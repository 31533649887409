import React from 'react';
import PropTypes from 'prop-types';

export default function ContentModulesCurrentTip({ currentModule }) {
  return (
    <div className="tip">
      <div className="row">
        <div className="col-md-6">
          <h3 className="mod__no-top-margin">{currentModule.tip_title}</h3>
          <p>
            {currentModule.tip_link_to ? (
              <a href={`${currentModule.tip_link_to.path}`}>{currentModule.tip_link_to.caption}</a>
            ) : (
              currentModule.tip_body
            )}
          </p>
        </div>
        <div className="col-md-6">
          <img src={currentModule.tip_image_url} className="tip-image" />
        </div>
      </div>
    </div>
  );
}

ContentModulesCurrentTip.propTypes = {
  currentModule: PropTypes.object
};
