import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MeetingActionCreators from 'store/actions/MeetingActions';
import * as SettingsActionCreators from 'store/actions/SettingsActions';
import * as UserActionCreators from 'store/actions/UserActions';
import { isFetching, isLoaded } from 'rest-states';

import Meetings from '../components/Meetings/index';

function select(store) {
  return { store };
}

class MeetingsContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null,
      loadedMeetings: null
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  };

  componentDidMount() {
    const { dispatch, store } = this.props;

    const settingsActions = bindActionCreators(SettingsActionCreators, dispatch);
    settingsActions.load();

    const userActions = bindActionCreators(UserActionCreators, dispatch);

    if (!isFetching(store.currentUserState.getRestState) && !isLoaded(store.currentUserState.getRestState)) {
      userActions.loadCurrentUser();
    }
  }

  componentDidUpdate() {
    const { store } = this.props;

    if (store.currentUser !== this.state.currentUser) {
      this.setState({ currentUser: store.currentUser });
    }

    if (this.state.currentUser && !this.state.loadedMeetings) {
      this.setState({ loadedMeetings: true });
      this.loadMeetingsAfterUsersLoaded();
    }
  }

  loadMeetingsAfterUsersLoaded = () => {
    const { dispatch } = this.props;
    const meetingActions = bindActionCreators(MeetingActionCreators, dispatch);

    meetingActions.fetchCurrentMeetings();
  };

  render() {
    const { dispatch, store } = this.props;
    const meetingActions = bindActionCreators(MeetingActionCreators, dispatch);
    const items = store.meet.meetings.items;

    return (
      <Meetings
        items={items}
        meetingActions={meetingActions}
        store={store}
      />
    );
  }
}

export default connect(select)(MeetingsContainer);
