import React from 'react';
import PropTypes from 'prop-types';

export default class Header extends React.Component {
  static propTypes = {
    add: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="mn-section-heading">
        <h2>Medication
        </h2>

        <button onClick={this.add} className="is-primary is-pulled-right">
          Add medication
        </button>

        <hr />
      </div>
    );
  }

  add = () => this.props.add();
}
