import React from 'react';

const MnStarLargeIcon = props => {
  const iconColour = props.colour || '#2E4FA6';
  const opacity = props.opacity || '1';

  return (
    <svg width="29" height="27" fill="none" opacity={opacity} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.481.799c.307-.906 1.588-.906 1.895 0l2.93 8.66h9.37c.978 0 1.374 1.26.572 1.82l-7.544 5.262 2.903 8.578c.31.915-.728 1.693-1.52 1.141l-7.658-5.342-7.66 5.342c-.791.552-1.828-.226-1.519-1.14l2.903-8.579L.61 11.279c-.803-.56-.407-1.82.572-1.82h9.368l2.931-8.66z"
        fill={iconColour}
      />
    </svg>
  );
};

export default MnStarLargeIcon;
