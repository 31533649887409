import React from 'react';
import PropTypes from 'prop-types';
import { adviseUserStartedTyping, adviseUserStoppedTyping } from 'store/realTimeConversations';
import { realTimeEnabled } from 'store/realTime';

export default class WhosTyping extends React.Component {
  static propTypes = {
    conversation: PropTypes.object,
    currentUser: PropTypes.object.isRequired
  };

  state = { usersTyping: [] };

  componentDidMount() {
    const { conversation } = this.props;

    if (realTimeEnabled()) {
      adviseUserStartedTyping(conversation.id, this.addUser);
      adviseUserStoppedTyping(conversation.id, this.removeUser);
    }
  }

  render() {
    const users = this.state.usersTyping;

    if (users.length === 0) {
      return null;
    }

    const message = (length => {
      switch (length) {
        case 1:
          return (
            <span>
              <b>{users[0].name}</b> is typing...
            </span>
          );
        case 2:
          return (
            <span>
              <b>
                {users[0].name} and {users[1].name}
              </b>{' '}
              are typing...
            </span>
          );
        default:
          return 'Several people are typing...';
      }
    })(users.length);

    return <div className="messages-list-people-typing">{message}</div>;
  }

  addUser = user => {
    if (user.id !== this.props.currentUser.id) {
      this.setState({ usersTyping: this.state.usersTyping.concat(user) });
    }
  };

  removeUser = id => {
    this.setState({
      usersTyping: this.state.usersTyping.filter(u => u.id !== id)
    });
  };
}
