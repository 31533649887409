import React from 'react';
import PropTypes from 'prop-types';

import MedicationItem from './MedicationItem';

export default class MedicationList extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    medications: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired
  };

  render() {
    const { store, actions, medications } = this.props;
    return (
      <div className="medication-list">
        {medications.map(item => <MedicationItem store={store} actions={actions} item={item} key={item.id} />)}
      </div>
    );
  }
}
