import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ContentModulesResource from './ContentModulesResource';
import ContentModulesCurrentSummary from './ContentModulesCurrent/ContentModulesCurrentSummary';
import ContentModulesCurrentTip from './ContentModulesCurrent/ContentModulesCurrentTip';
import { getResourceItem } from '../../../store/actions/ResourceActions';
import { MyProgramContext } from '../context/MyProgramContext';
import ContentModulesCurrentHeader from './ContentModulesCurrent/ContentModulesCurrentHeader';
import ContentModulesCurrentResourcesList from './ContentModulesCurrent/ContentModulesCurrentResourcesList';

export default function StepWizard({ modules, currentModule, steps, dispatch, store }) {
  const context = useContext(MyProgramContext);
  const startStep = context.completedResources;
  const [currentStep, setCurrentStep] = useState(startStep);
  const [onPrevStep, setOnPrevStep] = useState(false);
  const isFirstStep = currentStep === 0;
  const isFinalStep = steps.length === 1 ? true : currentStep === steps.length - 1;

  const nextStep = () => {
    currentStep === steps.length - 1 ? setCurrentStep(currentStep) : setCurrentStep(currentStep + 1);
    setOnPrevStep(false);

    if (isFinalStep) context.setCompletedModules(context.completedModules + 1);
  };

  const prevStep = () => {
    setOnPrevStep(true);
    currentStep === 0 ? setCurrentStep(currentStep) : setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    if (!isFirstStep) loadResourceItem(steps[currentStep].slug);
    if (currentStep !== startStep) context.saveCompletedResource(steps[currentStep].resource, onPrevStep);
  }, [currentStep]);

  const loadResourceItem = (slug) => {
    dispatch(getResourceItem(slug));
  };

  if (currentStep === 0) {
    if (currentModule.last) {
      return (
        <>
          <div className="header">
            <div className="module-header">
              <div className="subtitle">{currentModule.subtitle ?? 'Resources for this Program'}</div>
              <p className="strong">{currentModule.body ?? 'You\'ve completed your program! Here\'s a summary of what we\'ve covered'}</p>
            </div>
          </div>
          {modules.data.map((module, index) => {
            if (index < modules.data.length - 1) {
              return (
                <>
                  <div>
                    Module {index + 1} | {module.title}
                  </div>
                  {module.resources?.length > 0 ? (
                    <ul style={{ marginBottom: '20px' }}>
                      {module.resources.map((resource) => {
                        return (
                          <li key={resource.id}>
                            <a target="_blank" rel="noopener noreferrer" href={`/resources/${resource.slug}`}>
                              {resource.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div>This module does not have any resources</div>
                  )}
                </>
              );
            }
          })}
        </>
      );
    }
    return (
      <IntroPage
        currentModule={currentModule}
        nextStep={nextStep}
        prevStep={prevStep}
        isFirstStep={isFirstStep}
        isFinalStep={isFinalStep}
      />
    );
  } else {
    return (
      <>
        {currentModule.resources.map((r) => {
          if (r.slug === steps[currentStep].slug) {
            return (
              <ContentModulesResource
                key={currentModule.resources.indexOf(r)}
                slug={r.slug}
                nextStep={nextStep}
                prevStep={prevStep}
                resourceItems={store.resources.resourceItems}
                isFirstStep={isFirstStep}
                isFinalStep={isFinalStep}
                currentModule={currentModule}
              />
            );
          }
        })}
        {currentStep === currentModule.resources.length && (
          <ContentModulesCurrentResourcesList currentModule={currentModule} />
        )}
      </>
    );
  }
}

export function IntroPage(props) {
  return (
    <>
      <ContentModulesCurrentHeader
        nextStep={props.nextStep}
        prevStep={props.prevStep}
        isFirstStep={props.isFirstStep}
        isFinalStep={props.isFinalStep}
        currentModule={props.currentModule}
      />
      <br />
      <div className="header">
        <div className="module-header">
          <h3 className="title"> {props.currentModule.title}</h3>
          <div className="subtitle">{props.currentModule.subtitle}</div>
        </div>
      </div>
      <ContentModulesCurrentSummary currentModule={props.currentModule} />
      <hr />
      <ContentModulesCurrentTip currentModule={props.currentModule} />
    </>
  );
}

StepWizard.propTypes = {
  currentModule: PropTypes.object,
  totalResources: PropTypes.number,
  steps: PropTypes.array,
  dispatch: PropTypes.func,
  store: PropTypes.object
};
