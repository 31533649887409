import actionTypes from '../constants';
import User from 'models/User';

export default {
  requestCollection: actionTypes.REQUEST_CHAT_WITH,
  receiveCollection: actionTypes.RECEIVE_CHAT_WITH,
  storeKey: 'chatWith',
  Model: User,
  collectionKey: 'users',
  apiEndPoint: '/api/v1/users'
};
