import actionTypes from '../constants';
import CompletedResource from 'models/CompletedResource';

export default {
  receiveItem: actionTypes.RECEIVE_COMPLETED_RESOURCE,
  requestItem: actionTypes.REQUEST_COMPLETED_RESOURCE,
  storeKey: 'completed_resource',
  Model: CompletedResource,
  apiEndPoint: '/api/v1/completed_resources',
  bodyParam: 'resource_id'
};
