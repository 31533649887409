import actionTypes from 'action-types';
import { fetchResource, updateResource, deleteResource } from 'backend/ApiResources.js';
import { isFetching, isSaving } from 'store/reducers/restStates';
import { apiMethods } from 'backend/apiConstants';
import Mixpanel from 'common/Mixpanel';

function resourceSectionsGetInProgressAction() {
  return {
    type: actionTypes.RESOURCE_SECTIONS_GET_IN_PROGRESS
  };
}

function resourceSectionsGetSuccessAction(payload, store) {
  return {
    type: actionTypes.RESOURCE_SECTIONS_GET_SUCCESS,
    payload,
    store
  };
}

function resourceSectionsGetFailureAction(payload) {
  return {
    type: actionTypes.RESOURCE_SECTIONS_GET_FAILURE,
    payload
  };
}

/**
 * Action creator for requesting the resource sections
 *
 * @method getResourceSections
 *
 * @return {Action/Promise} The request for the resource sections
 */
export function getResourceSections() {
  return function(dispatch, getState) {
    const currentState = getState();

    if (isFetching(currentState.resources.sections.getRestState)) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: resourceSectionsGetInProgressAction,
        url: '/api/v1/resource_sections',
        onSuccess: resourceSectionsGetSuccessAction,
        onFailure: resourceSectionsGetFailureAction,
        store: getState()
      };

      return fetchResource(resource, dispatch, getState);
    }
  };
}

export function setResourceSections(resourceSections) {
  return function(dispatch, getState) {
    const currentState = getState();

    if (isFetching(currentState.resources.sections.getRestState)) {
      return Promise.resolve();
    } else {
      dispatch(resourceSectionsGetInProgressAction());
      return dispatch(resourceSectionsGetSuccessAction(resourceSections, getState()));
    }
  };
}

function resourceListGetInProgressAction(payload) {
  return {
    type: actionTypes.RESOURCE_LIST_GET_IN_PROGRESS,
    payload: payload
  };
}

function resourceListGetSuccessAction(payload, store) {
  return {
    type: actionTypes.RESOURCE_LIST_GET_SUCCESS,
    payload,
    store
  };
}

function resourceListGetFailureAction(error, resource) {
  return {
    type: actionTypes.RESOURCE_LIST_GET_FAILURE,
    payload: {
      resource_section: resource.resource.resource_section
    }
  };
}

/**
 * Action creator for requesting the resource list
 *
 * @method getResourceList
 *
 * @param {String} The resource section slug
 *
 * @return {Action/Promise} The request for the resource list
 */
export function getResourceList(resourceSection) {
  return function(dispatch, getState) {
    const currentState = getState();

    if (isFetching(currentState.resources.resourceList.getRestState)) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: resourceListGetInProgressAction,
        url: `/api/v1/resource_sections/${resourceSection}`,
        onSuccess: resourceListGetSuccessAction,
        onFailure: resourceListGetFailureAction,
        store: getState(),
        resource_section: resourceSection
      };

      return fetchResource(resource, dispatch, getState);
    }
  };
}

export function setResourceList(resourceList) {
  return function(dispatch, getState) {
    const currentState = getState();

    if (isFetching(currentState.resources.resourceList.getRestState)) {
      return Promise.resolve();
    } else {
      dispatch(resourceListGetInProgressAction({ resource: { resource_section: resourceList.resource_section.slug } }));
      return dispatch(resourceListGetSuccessAction(resourceList, getState()));
    }
  };
}

function resourceItemGetInProgressAction(payload) {
  return {
    type: actionTypes.RESOURCE_ITEM_GET_IN_PROGRESS,
    payload
  };
}

function resourceItemGetSuccessAction(payload, store) {
  return {
    type: actionTypes.RESOURCE_ITEM_GET_SUCCESS,
    payload,
    store
  };
}

function resourceItemGetFailureAction(error, resource) {
  console.log('resource');
  console.log(resource);
  console.log(resource.resource);
  return {
    type: actionTypes.RESOURCE_ITEM_GET_FAILURE,
    payload: {
      slug: resource.resource.slug
    }
  };
}

/**
 * Action creator for requesting the resource item
 *
 * @method getResourceItem
 *
 * @param {String} The resource item slug
 *
 * @return {Action/Promise} The request for the resource item
 */
export function getResourceItem(slug) {
  return function(dispatch, getState) {
    const currentState = getState();
    const resourceItem = currentState.resources.resourceItems[slug];

    if (resourceItem && isFetching(resourceItem.getRestState)) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: resourceItemGetInProgressAction,
        url: `/api/v1/resources/${slug}`,
        onSuccess: resourceItemGetSuccessAction,
        onFailure: resourceItemGetFailureAction,
        store: getState(),
        slug: resourceItem
      };

      return fetchResource(resource, dispatch, getState);
    }
  };
}

export function setResourceItem(resourceItem) {
  return function(dispatch, getState) {
    const currentState = getState();
    const existingResourceItem = currentState.resources.resourceItems[resourceItem.resource.slug];

    if (existingResourceItem && isFetching(existingResourceItem.getRestState)) {
      return Promise.resolve();
    } else {
      dispatch(resourceItemGetInProgressAction({ resource: { slug: resourceItem.resource.slug } }));
      return dispatch(resourceItemGetSuccessAction(resourceItem, getState()));
    }
  };
}

function resourceItemSummaryGetInProgressAction(params) {
  return {
    type: actionTypes.RESOURCE_ITEM_SUMMARY_GET_IN_PROGRESS,
    resource: params.resource
  };
}

function resourceItemSummaryGetSuccessAction(payload, store) {
  return {
    type: actionTypes.RESOURCE_ITEM_SUMMARY_GET_SUCCESS,
    payload,
    store
  };
}

function resourceItemSummaryGetFailureAction(payload, params) {
  return {
    type: actionTypes.RESOURCE_ITEM_SUMMARY_GET_FAILURE,
    payload,
    resource: params.resource
  };
}

export function getResourceItemSummary(resourceSlug) {
  return function(dispatch, getState) {
    const currentState = getState();

    if (isFetching(currentState.resources.summaries[resourceSlug] && currentState.resources.summaries[resourceSlug].getRestState)) {
      return Promise.resolve();
    }

    const resource = {
      onBegin: resourceItemSummaryGetInProgressAction,
      url: `/api/v1/resource_sections/${resourceSlug}/summary`,
      onSuccess: resourceItemSummaryGetSuccessAction,
      onFailure: resourceItemSummaryGetFailureAction,
      store: getState(),
      slug: resourceSlug
    };

    return fetchResource(resource, dispatch, getState);
  };
}

function resourceItemToggleFavoriteInProgressAction() {
  return {
    type: actionTypes.RESOURCE_ITEM_TOGGLE_FAVORITE_IN_PROGRESS
  };
}

function resourceItemToggleFavoriteSuccessAction(payload, store) {
  return {
    type: actionTypes.RESOURCE_ITEM_TOGGLE_FAVORITE_SUCCESS,
    payload,
    store
  };
}

function resourceItemToggleFavoriteFailureAction(payload) {
  return {
    type: actionTypes.RESOURCE_ITEM_TOGGLE_FAVORITE_FAILURE,
    payload
  };
}

/**
 * Action creator for setting a resource as favorite
 *
 * @method setResourceItemAsFavorite
 *
 * @param {Object} options
 *        @param {String} id
 *        @param {Boolean} [isListView]
 *
 * @return {Action/Promise} The request for saving the resource item as a favorite
 */
export function setResourceItemAsFavorite(options) {
  return function(dispatch, getState) {
    const currentState = getState();

    if (isSaving(currentState.resources.favorites.saveRestState)) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: resourceItemToggleFavoriteInProgressAction,
        onSuccess: resourceItemToggleFavoriteSuccessAction,
        onFailure: resourceItemToggleFavoriteFailureAction,
        resourceUrl: `/api/v1/resources/${options.id}/resource_favorite`,
        method: apiMethods.POST
      };

      return updateResource(null, resource, dispatch, getState).then(response => {
        const requestSuccess = response && response.success;

        if (requestSuccess) {
          Mixpanel.track(`Favourited Resource - ${options.slug}`);
          dispatch(_toggleResourceItemFavoriteState(options));
        }
      });
    }
  };
}

/**
 * Action creator for removing a resource as favorite
 *
 * @method removeResourceItemAsFavorite
 *
 * @param {Object} options
 *        @param {String} id
 *        @param {Boolean} [isListView]
 *
 * @return {Action/Promise} The request for deleting the resource item as a favorite
 */
export function removeResourceItemAsFavorite(options) {
  return function(dispatch, getState) {
    const currentState = getState();

    if (isSaving(currentState.resources.favorites.saveRestState)) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: resourceItemToggleFavoriteInProgressAction,
        onSuccess: resourceItemToggleFavoriteSuccessAction,
        onFailure: resourceItemToggleFavoriteFailureAction,
        url: `/api/v1/resources/${options.id}/resource_favorite`
      };

      return deleteResource(null, resource, dispatch, getState).then(response => {
        const requestSuccess = response && response.success;

        if (requestSuccess) {
          Mixpanel.track(`Unfavourited Resource - ${options.slug}`);
          dispatch(_toggleResourceItemFavoriteState(options));
        }
      });
    }
  };
}

/**
 * Action creator for toggling the current resource item favorite state
 * This is so we can render changes without needing to fetch the entire resource again
 *
 * @method _toggleResourceItemFavoriteState
 *
 * @param {Object} payload
 *
 * @return {Action} The request for toggling the resource item as a favorite
 */
function _toggleResourceItemFavoriteState(payload) {
  return {
    type: actionTypes.RESOURCE_ITEM_FAVORITE_STATE_TOGGLE,
    payload
  };
}
