import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'common/Mixpanel';
import UserAvatar from 'mn-react/components/UserAvatar';
import WriteReply from './WriteReply';

export default class PostWriteReply extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired
  };

  render() {
    return this.props.active ? <WriteReply {...this.props} cancel={this.props.onCancel} /> : this.renderInactive();
  }

  renderInactive() {
    const { store } = this.props;
    const author = store.currentUser;
    return (
      <div className="new-community-post-reply">
        <div className="community-post__write-reply_avatar">
          <UserAvatar user={author} size='size-40' onClick={ () => { Mixpanel.track('User Avatar Link'); }} />
        </div>
        <div className="editor-placeholder" onClick={() => { this.props.onFocus(); Mixpanel.track('New Reply Started'); }}>
          Write a reply...
        </div>
      </div>
    );
  }
}
