import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isFetching, isLoaded } from 'rest-states';

import * as SettingsActionCreators from 'store/actions/SettingsActions';
import * as MedicationActionCreators from 'store/actions/MedicationActions';
import * as UserActionCreators from 'store/actions/UserActions';

import Medication from '../components/index';

function select(store) {
  return { store };
}

class MedicationWidget extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired,
    userId: PropTypes.number.isRequired
  };

  componentDidMount() {
    const { dispatch, store, userId } = this.props;
    const settingsActions = bindActionCreators(SettingsActionCreators, dispatch);
    settingsActions.load();

    const medicationActions = bindActionCreators(MedicationActionCreators, dispatch);
    medicationActions.load(userId);

    const userActions = bindActionCreators(UserActionCreators, dispatch);

    if (!isFetching(store.currentUserState.getRestState) && !isLoaded(store.currentUserState.getRestState)) {
      userActions.loadCurrentUser();
    }
  }

  componentDidUpdate() {
    const { store } = this.props;

    if (store.currentUser !== this.state.currentUser) {
      this.setState({ currentUser: store.currentUser });
    }
  }

  render() {
    const { dispatch, store, userId } = this.props;
    const medicationActions = bindActionCreators(MedicationActionCreators, dispatch);

    if (!store.medications.loaded || !store.settings.loaded || !this.state.currentUser) {
      return <div>loading...</div>;
    }

    return (
      <Medication
        actions={medicationActions}
        store={store}
        userId={userId}
      />
    );
  }
}

export default connect(select)(MedicationWidget);
