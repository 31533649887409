import React from 'react';
import PropTypes from 'prop-types';

import TextareaAutosize from 'react-textarea-autosize';
import UserAvatar from 'mn-react/components/UserAvatar';
import { indicateStartedTyping, indicateStoppedTyping } from 'store/realTimeConversations';
import { isInputEmpty } from 'web/utils/formHelpers';

export default class NewMessageForm extends React.Component {
  static propTypes = {
    conversationId: PropTypes.number,
    sendNewMessage: PropTypes.func,
    currentUser: PropTypes.object,
    shouldFocusOnMessage: PropTypes.bool
  };

  state = { stickyMessages: {} };

  componentDidMount() {
    if (this.props.shouldFocusOnMessage) {
      this.focusInMessage();
    }
  }

  focusInMessage = () => {
    try {
      this.messageInput.focus();
    } catch (err) {}
  };

  render() {
    const { currentUser } = this.props;

    if (this.props.shouldFocusOnMessage) {
      this.focusInMessage();
    }

    if (this.messageInput) {
      this.messageInput.value = this.state.stickyMessages[this.props.conversationId] || '';
    }

    return (
      <form className="new-message" onSubmit={this.save}>
        <UserAvatar size='size-40' user={currentUser} />
        <TextareaAutosize
          placeholder="Write a message"
          id="message_body"
          ref={node => {
            this.messageInput = node;
          }}
          onChange={this.inputChanged}
        />
        <input type="submit" value="Send" className="mn-button is-primary" onClick={this.save} />
      </form>
    );
  }

  inputChanged = () => {
    const value = this.messageInput.value;
    this.updateStickyMessage(value);
    this.updateStartTyping(value);
  };

  updateStickyMessage = newVal => {
    const { conversationId } = this.props;

    const stickyMessages = this.state.stickyMessages;
    stickyMessages[conversationId] = newVal;

    this.setState({ stickyMessages });
  };

  updateStartTyping = val => {
    const { conversationId, currentUser } = this.props;

    indicateStartedTyping(conversationId, currentUser.id, currentUser.name);
    setTimeout(() => this.testIfStoppedTyping(val), 10000);
  };

  testIfStoppedTyping = oldVal => {
    const { conversationId, currentUser } = this.props;

    if (this.messageInput && oldVal === this.messageInput.value) {
      indicateStoppedTyping(conversationId, currentUser.id);
    }
  };

  save = e => {
    e.preventDefault();
    const { conversationId, sendNewMessage, currentUser } = this.props;

    const body = this.messageInput.value;

    if (isInputEmpty(body)) {
      return;
    }

    this.messageInput.value = '';
    this.updateStickyMessage('');
    indicateStoppedTyping(conversationId, currentUser.id);
    var newMesage = {
      conversation_id: conversationId,
      body: body,
      return_conversation: true // used to triger alternative API format
    };

    sendNewMessage(newMesage);
  };
}
