import React from 'react';
import PropTypes from 'prop-types';
import PostView from './Posts/PostView';
import Mixpanel from 'common/Mixpanel';

export default class ViewSinglePost extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    postData: PropTypes.object.isRequired,
    ensureUserHasConfirmed: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      postFocused: false
    };
  }

  componentDidMount() {
    Mixpanel.screenView();
    this.props.actions.postActions.loadPost(this.props.postData);
  }

  render() {
    const { store } = this.props;
    const post = store.posts.items.find(p => {
      return p.id === this.props.postData.post.id;
    });

    if (!post) {
      return (<div/>);
    }

    return (
      <div className="posts-container" onClick={() => { this.setFocus(false); }}>
        <div className="mn-columns">
          <div className="column is-single-column post-wrapper">
            <PostView
              actions={this.props.actions}
              store={this.props.store}
              post={post}
              focused={this.state.postFocused}
              focus={(this.setFocus.bind(null, true))}
              ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
            />
          </div>
        </div>
      </div>
    );
  }

  setFocus = bool => {
    this.setState({ postFocused: bool });
  }
}
