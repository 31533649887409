import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';
import ResourceLinkPreviewContainer from 'mn-react/components/ResourceLinkPreviewContainer';

export default class BodyFormatter extends React.Component {
  // This is used to get the base url including protocol and port without using a lookup with the current environment.
  // This will be immune to variable server setting such as ports, urls, and secucure/not secure connections allowing it
  // to work accross all environments.
  resourcePath = window.location.toString().split('/').slice(0, 3).join('/') + '/resources/';

  static propTypes = {
    item: PropTypes.object.isRequired
  };

  render() {
    const { item, truncateAt = 1000000 } = this.props;

    const itemClone = Object.assign({}, item);
    itemClone.body = this._truncate(item.body, truncateAt);
    return <Linkify properties={{ target: '_blank' }}>{this._replaceKeywords(itemClone)}</Linkify>;
  }

  sortMentionByIndexAsc = (a, b) => {
    if (a.index < b.index) return -1;
    if (a.index > b.index) return 1;
    return 0;
  };

  /**
 * Get the indexes of successive code points in a string
 *   There is a good description od codepoints here: https://medium.com/reactnative/emojis-in-javascript-f693d0eb79fb
 *   JS indexes and lengths are based on an array of characters, eg "hello".length === 5
 *   This causes problems with emoji code points since they can span two characters.
 *      eg in JS "😺".length === 2.
 *        in rails though, the length of "😺" is 1.
 *   This means that the indexes of mentions from the server need to be adjusted to work with JS
 *
 *  This method prepares an array of character indexes from a string, such that array[codePointIndex]=characterIndex

 * @method mapCodePointIndicesToCharacterIndices
 *
 * @param {String} text: an input string
 *
 * @return {Array} An array mapping code point indices to character indices
 *
 * @example
 *    mapCodePointIndicesToCharacterIndices("hello") === [0,1,2,3,4]
 *    mapCodePointIndicesToCharacterIndices("he😺llo") === [0,1,2,4,5,6]
 *      so while substring("he😺llo", 4) returns a wrong looking "llo"
 *      so while substring("he😺llo", mapCodePointIndicesToCharacterIndices("he😺llo")[4]) is the correct "lo"
 */
  mapCodePointIndicesToCharacterIndices = text => {
    const mapping = [];
    for (
      let characterIndex = 0;
      characterIndex < text.length;
      characterIndex += String.fromCodePoint(text.codePointAt(characterIndex)).length
    ) {
      mapping.push(characterIndex);
    }
    return mapping;
  };

  /**
   * Splits the posts body into a series of segments to be interprated and rendered as
   * jsx components when a non plain text post is detected.
   *
   * New line characters are replaced with break tags allowing for basic post formatting
   *
   * The splits occur on anything that can seperate a text node. i.e. spaces and new lines
   */
  _replaceKeywords = item => {
    if (item.body.includes(this.resourcePath) || item.body.includes('\n') || (item.mentions && item.mentions.length > 0)) {
      return item.body.split(' ').map((item, index) => (
        item.split('\n').map(subItem => (
          this._renderPostSegment(subItem, index)
        )).reduce((previous, current) => [previous, <br key={Math.random()} />, current])
      )).reduce((previous, current) => [previous, ' ', current]);
    }

    return item.body;
  }

  _truncate = (str, maxWords) => {
    const array = str.trim().split(' ');
    const ellipsis = array.length > maxWords ? '...' : '';

    return array.slice(0, maxWords).join(' ') + ellipsis;
  }

  /**
   * Returns a transformed segment
   *
   * In the case of internal resource links, a link preview component is returned
   * Mentions are replaced with links to the mentioned users profile
   * all other types are rendered as plain text
   */
  _renderPostSegment = (segment) => {
    if (this._isLink(segment)) {
      return <ResourceLinkPreviewContainer key={Math.random()} link={segment} />;
    } else if (this._isMention(segment)) {
      return this._renderMention(segment);
    }

    return segment;
  }

  _isLink = segment => {
    return segment.startsWith(this.resourcePath) && segment.split('/').length >= 6;
  }

  _isMention = segment => {
    return this.props.item.mentions.map(mention => { return mention.token; }).some(mentionString => { return segment.includes(mentionString); });
  }

  _renderMention(segment) {
    let proccessedSegment = segment;

    this.props.item.mentions.some((mention) => {
      if (segment.includes(mention.token) && mention.kind === 'mention') {
        // By splitting the text node on the mention token, any surrounding text and puonctuation will not be effected by the replacement
        proccessedSegment = segment.split(mention.token).reduce((previous, current) => [previous, <a key={Math.random()} href={'/users/' + mention.link_to.user_id}>{mention.token}</a>, current]);
        return true;
      }
    });

    return proccessedSegment;
  }
}
