import React from 'react';
import { Link } from 'react-router-dom';

export default class NewConversationBtn extends React.Component {
  render() {
    return (
      <Link to="/conversations/new" className="mn-button is-primary is-pulled-right" data-cy="new-message-button">
        New
      </Link>
    );
  }
}
