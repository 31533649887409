import actionTypes from 'action-types';

export function CurrentUserStateGetInProgressAction() {
  return {
    type: actionTypes.CURRENT_USER_STATE_GET_IN_PROGRESS
  };
}

export function CurrentUserStateGetSuccessAction() {
  return {
    type: actionTypes.CURRENT_USER_STATE_GET_SUCCESS
  };
}
