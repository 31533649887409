import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import MeetingUser from 'models/MeetingUser';
import MeetingForUser from 'models/MeetingForUser';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ActionCreators from 'store/actions/MeetingActions';
import * as SettingsActionCreators from 'store/actions/SettingsActions';
import MeetingForm from '../components/meetingForm/MeetingForm';

class MeetingFormContainer extends React.Component {
  static propTypes = {
    meeting: PropTypes.instanceOf(MeetingForUser),
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired,
    with_id: PropTypes.number,
    startAt: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);

    let meetingState, labels;
    if (props.meeting == null) {
      meetingState = {
        meeting_at: new Date(this.defaultMeetingAt().format()),
        isNewMeeting: true,
        with_id: props.with_id,
        notes: '',
        errors: {}
      };
    } else {
      meetingState = {
        meeting_at: new Date(props.meeting.meeting_at),
        isNewMeeting: false,
        with_id: this.props.meeting.otherUser().id,
        notes: props.meeting.notes,
        private_notes: props.meeting.private_notes,
        errors: {}
      };
    }
    meetingState.meetingUser = new MeetingUser(props.store.currentUser, props.store);

    if (meetingState.isNewMeeting) {
      labels = {
        title: 'Schedule a call',
        action: 'Request your call'
      };
    } else if (this.props.meeting.canConfirm()) {
      labels = {
        title: `Confirm call with ${this.props.meeting.otherUser().name}`,
        action: 'Confirm call'
      };
    } else {
      labels = {
        title: `${this.props.meeting.is_confirmed ? 'Confirmed call' : 'Unconfirmed call'} with ${
          this.props.meeting.otherUser().name
        }`
      };

      labels.action = 'Save';
    }
    this.state = {
      meetingState: meetingState,
      labels: labels
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const actions = bindActionCreators(ActionCreators, dispatch);
    actions.requestMeetingPartnersFor(this.state.meetingState.meetingUser);
    const settingActions = bindActionCreators(SettingsActionCreators, dispatch);
    settingActions.load();
  }

  render() {
    const { meeting, store } = this.props;
    if (this.props.showModal && store.settings.loaded) {
      return (
        <MeetingForm
          actions={{
            close: this.close,
            save: this.save,
            delete: this.delete,
            handleChange: this.handleChange
          }}
          meeting={meeting}
          labels={this.state.labels}
          meetingState={this.state.meetingState}
          meetWithPartners={store.meet.partners}
          availability={this.availability()}
          meetingSettings={store.settings.meet_and_greet.meetings}
        />
      );
    }
    if (this.props.showModal) {
      return <div>loading...</div>;
    }
    return null;
  }

  availability = () => {
    return this.props.store.settings.meeting_availability || this.defaultAvailability();
  };

  defaultAvailability = () => {
    return {
      weekly: {
        sunday: { startMins: 0, endMins: 1440 },
        monday: { startMins: 0, endMins: 1440 },
        tuesday: { startMins: 0, endMins: 1440 },
        wednesday: { startMins: 0, endMins: 1440 },
        thursday: { startMins: 0, endMins: 1440 },
        friday: { startMins: 0, endMins: 1440 },
        saturday: { startMins: 0, endMins: 1440 }
      }
    };
  };

  save = () => {
    if (this.state.action === 'Close') {
      this.close();
      return true;
    }
    var newValues = {};
    const meetingState = this.state.meetingState;

    if (meetingState.isNewMeeting) {
      newValues.is_requestor = true;
      newValues.requested_user_id = meetingState.with_id || this.props.store.meet.partners.items[0].id;
      newValues.other_user_id = newValues.requested_user_id;
    } else {
      newValues.id = this.props.meeting.id;
      if (this.props.meeting.canConfirm()) {
        newValues.is_confirmed = true;
      }
    }
    if (meetingState.notes) {
      newValues.notes = meetingState.notes;
    }
    if (meetingState.private_notes) {
      newValues.private_notes = meetingState.private_notes;
    }
    newValues.meeting_at = meetingState.meeting_at;

    this.props.onClose(newValues);

    const { dispatch } = this.props;
    const actions = bindActionCreators(ActionCreators, dispatch);
    actions.saveMeeting(newValues);
  };

  close = () => this.props.onClose(null);

  delete = () => {
    if (this.state.meetingState.isNewMeeting) {
      this.close();
    } else {
      const { dispatch, meeting } = this.props;
      const actions = bindActionCreators(ActionCreators, dispatch);
      actions.deleteMeeting(meeting);
      this.close();
    }
  };

  defaultMeetingAt() {
    let at;
    if (this.props.startAt) {
      at = moment(this.props.startAt);
      if (at.hour() === 0) {
        at = moment(this.props.startAt)
          .hour(12)
          .startOf('hour');
      }
    } else {
      // to provide something as a starting point, need to ensure it's a valid option
      // just go midday tomorrow
      at = moment()
        .add(1, 'day')
        .hour(12)
        .startOf('hour');
    }
    // not on Satuday
    if (at.day() === 6) {
      at = at.add(2, 'day');
    }
    // not on Sunday
    if (at.day() === 0) {
      at = at.add(1, 'day');
    }
    return at;
  }

  handleChange = (field, newValue) => {
    var nextState = this.state.meetingState;
    nextState[field] = newValue;
    this.setState({ meetingState: nextState });
  };
}

function select(store) {
  return { store };
}

export default connect(select)(MeetingFormContainer);
