import actionTypes from '../constants';

import Reply from 'models/Reply';

export default {
  // requestCollection: actionTypes.REQUEST_POSTS,
  // receiveCollection: actionTypes.RECEIVE_POSTS,
  receiveItem: actionTypes.RECEIVE_REPLY,
  removeItem: actionTypes.REMOVE_REPLY,

  Model: Reply,

  // collectionKey: 'posts',
  itemKey: 'replies',

  apiEndPoint: '/api/v1/replies',
  bodyParam: 'reply'
};
