import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ModalHeading from 'util/form/ModalHeading';
import Medication from 'models/Medication';

export default class Form extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    delete: PropTypes.func,
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      medication: new Medication(props.item, props.store)
    };
  }

  render() {
    const { medication } = this.state;
    const showDelete = !this.isAdding() && this.props.delete;

    return (
      <div>
        <Modal show={true} onHide={this.close}>
          <ModalHeading title={this.props.title} onClose={this.close} />
          <Modal.Body>
            <div className="mn-flex-form">
              <form>
                <div className="mn-flex-form-item">
                  <label>Medication</label>
                  <input
                    type="text"
                    placeholder="Enter medication name..."
                    value={medication.name}
                    onChange={this.handleFieldChange.bind(this, 'name')} />
                </div>

                <div className="mn-flex-form-item">
                  <label>Strength</label>
                  <input
                    type="text"
                    placeholder="i.e. 500mg..."
                    value={medication.strength}
                    onChange={this.handleFieldChange.bind(this, 'strength')} />
                </div>

                <div className="mn-flex-form-item">
                  <label>Number of tablets</label>
                  <div className="mn-select">
                    <select
                      value={medication.quantity}
                      onChange={this.handleFieldChange.bind(this, 'quantity')}>

                      <option></option>

                      {this.options().quantity_options.map(option => {
                        return (<option id={option} key={option}>{option}</option>);
                      })}
                    </select>
                  </div>
                </div>

                <div className="mn-flex-form-item">
                  <label>Frequency</label>
                  <div className="mn-select">
                    <select
                      value={medication.frequency}
                      onChange={this.handleFieldChange.bind(this, 'frequency')}>

                      <option></option>

                      {this.options().frequency_options.map(option => {
                        return (<option id={option}>{option}</option>);
                      })}
                    </select>
                  </div>
                </div>

                <div className="mn-flex-form-item">
                  <label>Reason</label>
                  <input
                    type="text"
                    placeholder="i.e. manage blood pressure..."
                    value={medication.reason}
                    onChange={this.handleFieldChange.bind(this, 'reason')} />
                </div>

                {this.showReminderField() && this.reminderFields(medication)}
                {this.isReminderCreated() && <span>Reminder aleady created</span>}

                <div className="mn-flex-form-action-buttons">
                  {showDelete && (
                    <button onClick={this.props.delete} className="is-link">
                      Delete
                    </button>
                  )}
                  <button onClick={this.save} className="is-primary" disabled={!this.canSave()}>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  reminderFields = medication => (
    <React.Fragment>
      <div className="mn-flex-form-item">
        <div className="mn-flex-form-switch">
          <label htmlFor="setupReminder">Setup Reminder?</label>
          <input id="setupReminder" type="checkbox" className="mn-toggle-switch" name="setupReminder" checked={medication.remind_me} onChange={this.handleCheckboxChange.bind(this, 'remind_me')}></input>
          <label htmlFor="setupReminder" className="mn-toggle-switch"></label>
          <label className="mn-secondary-copy-colour">
            A reminder is notification by phone or email that will remind you to take your medication.
            You can edit your reminder preferences in the notifications tab of your profile.
          </label>
        </div>
      </div>

      {medication.remind_me && (
        <div className="mn-flex-form-item">
          <label>Reminder time</label>
          <div className="mn-select">
            <select type="text" value={medication.time_of_day} onChange={this.handleFieldChange.bind(this, 'time_of_day')} >
              {this.reminderOptions().time_options.map(option => {
                return (<option value={option} key={option}>{ option }</option>);
              })}
            </select>
          </div>
        </div>
      )}

      {medication.remind_me && (
        <div className="mn-flex-form-item">
          <label>Reminder frequency</label>
          <div className="mn-select">
            <select type="text" value={medication.frequency_id} onChange={this.handleFieldChange.bind(this, 'frequency_id')} >
              { this.reminderOptions().frequency_options.map(option => {
                return (<option value={option.id} key={option.id}>{ option.name }</option>);
              })}
            </select>
          </div>
        </div>
      )}

      {medication.remind_me && (
        <div className="mn-flex-form-item">
          <label>Reminder title</label>
          <input type="text" value={medication.reminder_title} onChange={this.handleFieldChange.bind(this, 'reminder_title')} />
        </div>
      )}
    </React.Fragment>
  );

  close = () => this.props.onClose(null);

  canSave = () => {
    const { medication } = this.state;

    if (medication.name === '') {
      return false;
    }

    if (this.showReminderField() && medication.remind_me) {
      return medication.time_of_day > '' && medication.frequency_id && medication.reminder_title;
    }
    return true;
  };

  save = () => {
    this.props.onClose();
    this.props.actions.save(this.state.medication);
  };

  handleCheckboxChange(field, e) {
    this.handleChange(field, e.target.checked);
  }

  handleFieldChange(field, e) {
    this.handleChange(field, e.target.value);
  }

  handleChange(field, newValue) {
    const newMedicationState = this.state.medication;
    newMedicationState[field] = newValue;

    this.setState({ medication: newMedicationState });
  }

  showReminderField = () => this.isAdding() || !this.isReminderCreated();

  isReminderCreated = () => this.state.medication.reminder_id;

  isAdding = () => this.props.item.id === null;

  options = () => this.props.store.settings.medication;

  reminderOptions = () => this.props.store.settings.reminders;
}
