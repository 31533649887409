import React from 'react';
import { Provider } from 'react-redux';

import Home from './Home';

export default (props) => {
  const store = window.store;
  const appProps = {
    user: props.user
  };

  return (
    <Provider store={store}>
      <div className="mn-columns">
        <div className="column is-single-column mn-card">
          <Home {...appProps} />
        </div>
      </div>
    </Provider>
  );
};
