import React from 'react';
import moment from 'moment';
import MnIcon from 'mn-react/components/MnIcon';
import { constants } from '../../resourceHelpers';

class ResourceListItem extends React.Component {
  render() {
    return (
      <div className="resource-list-item-container">
        <div className="resource-list-item-details">
          <div className="resource-list-item-preview-image-container">
            {this._getPreviewImage()} {this._renderResourcePlayIcon()}
          </div>
          <div className="mn-mll">
            <div>{this.props.resource.title}</div>
            <div className="mn-subtext">{this._getItemDescription()}</div>
          </div>
        </div>
        <div className="resource-list-item-favorite-icon-container">{this._renderResourceFavoriteIcon()}</div>
      </div>
    );
  }

  /**
   * Get the resource list item description
   *
   * @private
   *
   * @method _getItemDescription
   *
   * @return {String} The resource list item description
   */
  _getItemDescription() {
    const { event } = this.props.resource;

    if (event) {
      return moment(event.startDateTime).format(constants.RESOURCE_DATE_FORMAT);
    }
  }

  /**
   * Get the preview image element
   *
   * @private
   *
   * @method _getPreviewImage
   *
   * @return {Element} The image preview element
   */
  _getPreviewImage() {
    const { event, documentUrl, videoUrl, previewImageUrl } = this.props.resource;

    if (documentUrl && !videoUrl) {
      return (
        <div className="resource-list-item-preview-icon-container">
          <MnIcon icon='mn-resources-document-icon' />
        </div>
      );
    }

    if (event) {
      return (
        <div className="resource-list-item-preview-icon-container">
          <MnIcon icon='mn-resources-event-icon' />
        </div>
      );
    }

    if (previewImageUrl) {
      return (
        <img
          className="resource-list-item-preview-image"
          src={previewImageUrl}
          alt={`${this.props.resource.title} preview`}
        />
      );
    }

    return <div className="resource-list-item-preview-image-container--placeholder" />;
  }

  /**
   * Render the resource list item play icon when dealing with a video resource
   *
   * @private
   *
   * @method _renderResourcePlayIcon
   *
   * @return {Element} The resource item play icon when dealing with a video resource
   */
  _renderResourcePlayIcon() {
    if (this.props.resource.videoUrl) {
      return (
        <div className="resource-list-item-preview-image-play-icon">
          <MnIcon icon="mn-play-icon" />
        </div>
      );
    }
  }

  /**
   * Get the resource favorite icon
   *
   * @private
   *
   * @method _renderResourceFavoriteIcon
   *
   * @return {Element} The resource favorite
   */
  _renderResourceFavoriteIcon() {
    const favoriteIcon = this.props.resource.favorite ? 'mn-star-large-icon' : 'mn-star-large-no-fill-icon';

    return (
      <div onClick={event => this._toggleResourceFavorite(this.props.resource, event)}>
        <MnIcon icon={favoriteIcon} />
      </div>
    );
  }

  /**
   * Toggle the current resource item favorite state
   *
   * @private
   *
   * @method _toggleResourceFavorite
   *
   * @param {Object} resourceItem
   *
   * @param {Event} event
   *
   * @return A call to toggle resource favorite state
   */
  _toggleResourceFavorite(resourceItem, event) {
    event.stopPropagation();

    if (resourceItem.favorite) {
      return this.props.removeResourceFavorite({ id: resourceItem.id, slug: resourceItem.slug, isListView: true });
    } else {
      return this.props.setResourceFavorite({ id: resourceItem.id, slug: resourceItem.slug, isListView: true });
    }
  }
}

export default ResourceListItem;
