import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { MyProgramContext } from '../context/MyProgramContext';

export default function LinearProgressBar() {
  const context = useContext(MyProgramContext);

  return (
    <MyProgramContext.Consumer>
      {() => {
        return (
          <div className="progress-bar-linear-container">
            <p className="subtitle-secondary">Module Progress</p>
            <p className="mn-subheading">{context.userProgress}% completed</p>
            <div className="progress-bar-outline">
              <div className="progress-bar-filler" style={{ width: `${context.userProgress}%` }}>
                <span style={{ opacity: 0 }}>-</span>
              </div>
            </div>
          </div>
        );
      }}
    </MyProgramContext.Consumer>
  );
}

LinearProgressBar.propTypes = {
  progress: PropTypes.number,
  totalResources: PropTypes.number
};
