import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Reminder from 'models/Reminder';
import ModalHeading from 'util/form/ModalHeading';
import flatpickr from 'flatpickr';
import Toast from 'common/toast';

export default class ReminderForm extends React.Component {
  static propTypes = {
    reminder: PropTypes.object.isRequired,
    config: PropTypes.object,
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
    saveAction: PropTypes.func.isRequired,
    deleteAction: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      reminder: new Reminder(props.reminder, props.store),
      title: `${props.reminder.id == null ? 'New' : 'Edit'} reminder`,
      showReminderDatePicker: props.reminder.frequency_id === 1
    };
    if (this.state.reminder.notes == null) {
      this.state.reminder.notes = '';
    }

    this.datePicker = React.createRef();
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.showReminderDatePicker && this.state.showReminderDatePicker) {
      flatpickr(this.datePicker.current, {
        onChange: this.handleDateChange.bind(this),
        static: true,
        wrap: true,
        minDate: new Date().toISOString().substring(0, 10)
      });
    }
  }

  render() {
    const { reminder } = this.state;

    return (
      <div>
        <Modal show={this.props.showModal} onHide={this.close}>
          <ModalHeading title={this.state.title} onClose={this.close} />
          <Modal.Body>
            <div className="mn-flex-form">
              <form className="new_reminder reminder">
                <div className="mn-flex-form-item">
                  <label>Title</label>
                  <input type="text" value={reminder.title} onChange={this.handleFieldChange.bind(this, 'title')} data-cy="reminderTitleInput" />
                </div>

                <div className="mn-flex-form-item">
                  <label>Frequency</label>
                  <div className="mn-select">
                    <select type="text" value={reminder.frequency_id} onChange={this.handleFieldChange.bind(this, 'frequency_id')} data-cy="reminderFrequencySelect">
                      {this.props.config.frequency_options.map(option => {
                        return (<option value={option.id} key={option.id}>{ option.name }</option>);
                      })}
                    </select>
                  </div>
                </div>

                <div className="mn-flex-form-item">
                  <label>Time</label>
                  <div className="mn-select">
                    <select type="text" value={reminder.time_of_day} onChange={this.handleFieldChange.bind(this, 'time_of_day')} data-cy="reminderTimeSelect">
                      { this.props.config.time_options.map(option => {
                        return (<option value={option} key={option}>{ option }</option>);
                      })}
                    </select>
                  </div>
                </div>

                {
                  this.state.showReminderDatePicker
                    ? <div className="mn-flex-form-item">
                      <label>Date</label>
                      <div ref={this.datePicker}>
                        <input name="date" type="text" placeholder="Select Date.." data-input/>
                      </div>
                    </div>
                    : null
                }

                <div className="mn-flex-form-item">
                  <label>Reminder type</label>
                  <div className="mn-select">
                    <select type="text" value={reminder.reminder_type_id} onChange={this.handleFieldChange.bind(this, 'reminder_type_id')} >
                      { this.props.config.reminder_type_options.map(option => {
                        return (<option value={option.id} key={option.id}>{ option.name }</option>);
                      })}
                    </select>
                  </div>
                </div>

                <div className="mn-flex-form-item">
                  <div className="mn-flex-form-switch">
                    <label htmlFor="enabled">Enabled?</label>
                    <input id="enabled" type="checkbox" name="enabled" className="mn-toggle-switch" checked={reminder.enabled === true} onChange={this.handleCheckboxChange.bind(this, 'enabled')}></input>
                    <label htmlFor="enabled" className="mn-toggle-switch"></label>
                  </div>
                </div>

                <div className="mn-flex-form-item">
                  <label>Notes</label>
                  <textarea
                    placeholder="Your notes"
                    value={reminder.notes}
                    onChange={this.handleFieldChange.bind(this, 'notes')}
                  />
                </div>

                <div className="mn-flex-form-action-buttons">
                  {this.renderCloseOrDeleteBtn()}
                  <button onClick={e => this.save(e) } className="is-primary" data-cy="save-reminder">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  renderCloseOrDeleteBtn() {
    const { reminder } = this.state;

    if (reminder.id) {
      return (
        <button onClick={this.delete} className="is-link">Delete</button>
      );
    }

    return (
      <button onClick={this.close} className="is-link">Cancel</button>
    );
  }

  isFormValid = () => this.validateTitle() && this.state.reminder.time_of_day;

  save = (event) => {
    const { reminder } = this.state;

    event.preventDefault();

    if (this.isFormValid()) {
      this.props.onClose();
      this.props.saveAction(reminder);
    }
  };

  delete = () => {
    const { reminder } = this.state;
    this.props.onClose();
    this.props.deleteAction(reminder);
  };

  close = () => this.props.onClose(null);

  handleFieldChange(field, e) {
    this.handleChange(field, e.target.value);
  }

  handleDateChange(selectedDates, dateStr, instance) {
    this.handleChange('date', dateStr);
  }

  handleCheckboxChange(field, e) {
    this.handleChange(field, e.target.checked);
  }

  handleChange(field, newValue) {
    const newReminder = this.state.reminder;
    newReminder[field] = newValue;

    const newState = { reminder: newReminder };

    if (field === 'frequency_id') {
      newState.showReminderDatePicker = (Number(newValue) === 1);
    }

    this.setState(newState);
  }

  validateTitle() {
    const { reminder } = this.state;

    if (!reminder.title) {
      Toast.add('Please provide a title', { expires: 5000 });
      return false;
    }

    return true;
  }
}
