import React from 'react';
import PropTypes from 'prop-types';
import StepWizard from '../StepWizard';

export default function ContentModulesCurrent({ modules, currentModule, store, dispatch }) {
  const createSteps = () => {
    const steps = [];

    steps.push({
      key: '0',
      slug: 'intro'
    });

    currentModule.resources.forEach((r, index) => {
      steps.push({
        key: index + 1,
        slug: r.slug,
        resource: r
      });
    });

    return steps;
  };

  const steps = createSteps();

  return (
    <div className="mn-card current-module">
      <StepWizard modules={modules} currentModule={currentModule} steps={steps} dispatch={dispatch} store={store} />
    </div>
  );
}

ContentModulesCurrent.propTypes = {
  currentModule: PropTypes.object
};
