import actionTypes from 'mn-react/store/constants';
import { updateResource } from 'backend/ApiResources.js';
import { apiMethods } from 'mn-react/backend/apiConstants';
import { toApi } from './models/transformer';

function youtubeResourceInteractionSaveInProgressAction() {
  return {
    type: actionTypes.YOUTUBE_RESOURCE_INTERACTION_SAVE_IN_PROGRESS
  };
}

function youtubeResourceInteractionSaveSuccessAction() {
  return {
    type: actionTypes.YOUTUBE_RESOURCE_INTERACTION_SAVE_SUCCESS
  };
}

function youtubeResourceInteractionFailureAction() {
  return {
    type: actionTypes.YOUTUBE_RESOURCE_INTERACTION_SAVE_FAILURE
  };
}

/**
 * Track video interactions
 *
 * @method trackVideoInteraction
 *
 * @param {Object} options
 *
 *        @param {String} resourceId
 *
 *        @param {String} interaction
 *               A string describing the video resource interaction type
 *
 * @return {Promise/Dispatch} A dispatched event to update the resource activities
 */
export function trackVideoInteraction({ resourceId, interaction }) {
  const requestData = toApi(interaction);
  const resource = {
    onBegin: youtubeResourceInteractionSaveInProgressAction,
    onSuccess: youtubeResourceInteractionSaveSuccessAction,
    onFailure: youtubeResourceInteractionFailureAction,
    resourceUrl: `/api/v1/resources/${resourceId}/resource_activities`,
    method: apiMethods.POST,
    param: 'resource_activity'
  };

  return (dispatch, getState) => updateResource(requestData, resource, dispatch, getState);
}
