import actionTypes from '../constants';

import Like from 'models/Like';

export default {
  receiveItem: actionTypes.RECEIVE_LIKE,
  removeItem: actionTypes.REMOVE_LIKE,

  Model: Like,

  itemKey: 'like',

  apiEndPoint: '/api/v1/likes',
  bodyParam: 'like'
};
