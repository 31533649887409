import React from 'react';
import PropTypes from 'prop-types';
import MessageSummary from './MessageSummary';

import { orderByIncreaingId } from 'models/Conversation';

export default class MessagesList extends React.Component {
  static propTypes = {
    conversation: PropTypes.object,
    currentUser: PropTypes.object.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    chatSettings: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.scrollToBottomOfMessages();
  }

  componentDidUpdate() {
    this.scrollToBottomOfMessages();
  }

  render() {
    const { conversation, currentUser, chatSettings, deleteMessage } = this.props;
    const sortedMessages = conversation.messages().sort(orderByIncreaingId);
    const firstUnreadMessageIndex = sortedMessages.length - conversation.num_unread_messages;
    const setRef = element => (this._messageListRef = element);

    return (
      <ul className="messages-list-container" ref={setRef}>
        {sortedMessages.map((message, index) => (
          <MessageSummary
            message={message}
            prevMessage={sortedMessages[index - 1]}
            key={message.id}
            unread={index >= firstUnreadMessageIndex}
            currentUser={currentUser}
            deleteMessage={deleteMessage}
            chatSettings={chatSettings}
          />
        ))}
      </ul>
    );
  }

  scrollToBottomOfMessages() {
    // a bit hacky, but scroll to the end - would be nicer to animate this - use a real number rather  than 10000
    if (this._messageListRef) {
      this._messageListRef.scrollTop = 10000;
    }
  }
}
