import React from 'react';
import PropTypes from 'prop-types';
import withRouter from '../../../../common/withRouter';
import ConversationSummary from './ConversationSummary';
import MnSpinner from 'mn-react/components/MnSpinner';

import { orderByLastestMessageFirst } from 'models/Conversation';

class ConversationsList extends React.Component {
  static propTypes = {
    conversations: PropTypes.object.isRequired,
    conversationId: PropTypes.string,
    filterByUser: PropTypes.object
  };

  static contextTypes = {
    router: PropTypes.object
  };

  state = { alreadyHandledLastReceivedItem: null };

  componentDidMount() {
    this.selectFirstConversationIfRequired(this.props);
  }

  render() {
    const { conversations } = this.props;

    if (!conversations.loaded) {
      return (
        <div className="conversation-widget-loading">
          <MnSpinner />
        </div>
      );
    }

    const hasConversationItems = conversations.items && conversations.items.length;
    if (!hasConversationItems) {
      return <div>You have no Conversations</div>;
    }

    return (
      <ul className="conversation-list__list">
        {this.displayConversations().map(conversation => (
          <ConversationSummary
            conversation={conversation}
            key={conversation.id}
            isSelected={
              this.props.conversationId && this.props.conversationId.toString() === conversation.id.toString()
            }
          />
        ))}
      </ul>
    );
  }

  displayConversations = () => {
    const { conversations, filterByUser } = this.props;
    return conversations.items.sort(orderByLastestMessageFirst).filter(c => c.includesUser(filterByUser));
  };

  selectFirstConversationIfRequired = props => {
    const { conversationId, conversations } = props;
    // if everythings loaded and we don't have a current conversation
    if (conversationId === 'new') {
      return null;
    }
    if (conversations.loaded && !conversations.isFetching) {
      if (conversations.lastReceivedItem && conversations.lastReceivedItem.id !== conversationId) {
        const newId = conversations.lastReceivedItem.id;
        if (this.state.alreadyHandledLastReceivedItem !== newId) {
          this.setState({
            alreadyHandledLastReceivedItem: newId
          });
          this.context.router.history.push(`/conversations/${newId}`);
        }
      }

      if (conversationId !== 'saving') {
        const curr = conversations.items.find(c => c.id.toString() === (conversationId && conversationId.toString()));
        if (!curr) {
          this.selectFirstConversation(conversationId, conversations);
        }
      }
    }
  };

  selectFirstConversation = (conversationId, conversations) => {
    const sortedConversations = conversations.items.sort(orderByLastestMessageFirst);
    if (sortedConversations[0]) {
      this.context.router.history.push(`/conversations/${sortedConversations[0].id}`);
    } else if (!conversations.isFetching && conversationId !== 'all') {
      this.context.router.history.push('/conversations/new');
    }
  };
}

export default withRouter(ConversationsList);
