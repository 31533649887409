import React from 'react';
import PropTypes from 'prop-types';
import CreatePost from './Posts/CreatePost';
import CommunitySwitcher from './CommunitySwitcher';
import PostList from './Posts/PostList';
import Mixpanel from 'common/Mixpanel';
import withRouter from '../../../common/withRouter';

class ViewCommunity extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    ensureUserHasConfirmed: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      page: 1,
      focusedPost: null
    };
  }

  componentDidMount() {
    if (this.props.enableRefreshListener) {
      window.refreshCommunityEvent = new Event('refreshCommunity');
      window.addEventListener('refreshCommunity', this.reloadCurrentCommunity);
    }

    this.loadCommunityPostsFromProps();
  }

  componentWillUnmount() {
    if (this.props.enableRefreshListener) {
      window.removeEventListener('refreshCommunity', this.reloadCurrentCommunity);
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (Number(this.props.params.id) !== Number(prevProps.params.id)) {
      this.fetchCommunityPosts();
    }
  }

  render() {
    const { store, actions } = this.props;
    // Hide dismissible notice and switcher when user is not able to switch communities.
    // switchableCommunities returns an array that contains the communities you belong to.
    // If there is more than one community the user can switch communities.
    const canSwitchCommunities = store.currentUser.switchableCommunities().length > 1;
    return (
      <div className="posts-container" onClick={() => { this.setFocusedPost(null); }}>
        <div className="mn-columns">
          <div className="column is-single-column post-wrapper">
            <CommunitySwitcher
              communityId={Number(this.props.params.id)}
              userActions={actions.userActions}
              store={store}
              currentCommunityActions={actions.currentCommunityActions}
              canSwitchCommunities={canSwitchCommunities}
              isEmbedded={this.props.isEmbedded}
            />

            <CreatePost
              actions={actions.postActions}
              store={store}
              communityId={Number(this.props.params.id)}
              focusNewPostOnStart={this.props.focusNewPostOnStart}
              ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
            />
            <div className="community-posts">
              <PostList
                actions={this.props.actions}
                store={this.props.store}
                posts={this.props.store.posts.items}
                focusedPost={this.state.focusedPost}
                focusPost={this.setFocusedPost}
                loadNextPage={this.fetchMoreCommunityPosts}
                ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  fetchCommunityPosts = () => {
    this.props.actions.postActions.initialLoadForCommunityId(Number(this.props.params.id));
    this.setState({ page: 1, focusedPost: null });
  };

  loadCommunityPostsFromProps = () => {
    this.props.actions.postActions.loadItems(this.props.initialPosts);
    this.setState({ page: 1, focusedPost: null });
  };

  fetchMoreCommunityPosts = () => {
    Mixpanel.track('Next Community Page');
    const nextPage = this.state.page + 1;

    this.props.actions.postActions.loadMoreForCommunityId(Number(this.props.params.id), nextPage);
    this.setState({ page: nextPage });
  };

  reloadCurrentCommunity = () => {
    Mixpanel.track('Community Refresh');
    this.fetchCommunityPosts();
  }

  setFocusedPost = post => {
    this.setState({ focusedPost: post });
  }
}

export default withRouter(ViewCommunity);
