import React from 'react';
import MnFavoriteAddIcon from './MnIcons/MnFavoriteAddIcon';
import MnFavoriteRemoveIcon from './MnIcons/MnFavoriteRemoveIcon';
import MnStarLargeIcon from './MnIcons/MnStarLargeIcon';
import MnStarLargeNoFillIcon from './MnIcons/MnStarLargeNoFillIcon';
import MnResourceDocumentIcon from './MnIcons/MnResourcesDocumentIcon';
import MnResourceEventIcon from './MnIcons/MnResourcesEventIcon';
import MnPlayIcon from './MnIcons/MnPlayIcon';

const MN_FAVORITE_ADD_ICON = 'mn-favorite-add-icon';
const MN_FAVORITE_REMOVE_ICON = 'mn-favorite-remove-icon';
const MN_STAR_LARGE_ICON = 'mn-star-large-icon';
const MN_STAR_LARGE_NO_FILL_ICON = 'mn-star-large-no-fill-icon';
const MN_RESOURCES_DOCUMENT_ICON = 'mn-resources-document-icon';
const MN_RESOURCES_EVENT_ICON = 'mn-resources-event-icon';
const MN_PLAY_ICON = 'mn-play-icon';

class MnIcon extends React.Component {
  render() {
    return this._getIcon();
  }

  /**
   * Get the icon to render based on the icon prop
   *
   * @private
   *
   * @method _getIcon
   *
   * @return {Element} The icon element to render
   */
  _getIcon() {
    switch (this.props.icon) {
      case MN_RESOURCES_DOCUMENT_ICON:
        return <MnResourceDocumentIcon dimensions={this.props.dimensions} colour={this.props.colour} />;

      case MN_RESOURCES_EVENT_ICON:
        return <MnResourceEventIcon dimensions={this.props.dimensions} colour={this.props.colour} />;

      case MN_PLAY_ICON:
        return <MnPlayIcon dimensions={this.props.dimensions} colour={this.props.colour} />;

      case MN_FAVORITE_ADD_ICON:
        return <MnFavoriteAddIcon />;

      case MN_FAVORITE_REMOVE_ICON:
        return <MnFavoriteRemoveIcon />;

      case MN_STAR_LARGE_ICON:
        return <MnStarLargeIcon colour={this.props.colour} opacity={this.props.opacity} />;

      case MN_STAR_LARGE_NO_FILL_ICON:
        return <MnStarLargeNoFillIcon colour={this.props.colour} opacity={this.props.opacity} />;

      default:
        return null;
    }
  }
}

export default MnIcon;
