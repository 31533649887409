'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import SortableTableHeader from 'util/table/SortableTableHeader';

export default class SortableTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    RowRenderer: PropTypes.func.isRequired // Maybe allow this to be optional, and render basic table, but would need field keys in columns then
  };

  constructor(props, context) {
    super(props, context);
    var sortings = new Array(props.columns.length).fill('both');
    sortings[0] = 'asc';
    this.state = {
      sortings: sortings
    };
    this.onClick = this.onClick.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
  }

  onClick(index) {
    this.props.onStateChange(index);
  }

  render() {
    const { RowRenderer } = this.props;

    var sortedData = this.sortedData();
    var rows = [];
    for (const row of sortedData) {
      rows.push(<RowRenderer row={row} key={row.id} />);
    }

    return (
      <table className="table">
        <SortableTableHeader
          columns={this.props.columns}
          sortings={this.state.sortings}
          onStateChange={this.onStateChange}
        />
        <tbody>{rows}</tbody>
      </table>
    );
  }

  sortedData() {
    if (this.props.data.length === 0 || this.props.columns.length === 0) {
      return [];
    } else {
      var sorted = this.props.data.sort(this.sortFunc());
      if (this.sortedReversed()) {
        sorted = sorted.reverse();
      }
      return sorted;
    }
  }

  sortFunc() {
    return this.props.columns[this.sortIndex()].sortFunc;
  }

  sortedReversed() {
    return this.state.sortings[this.sortIndex()] === 'desc';
  }

  sortIndex() {
    // return index of first sorting not equal to "both"
    var index = this.state.sortings.findIndex(sorting => sorting !== 'both');
    if (index === -1) index = 0; // none found, sort bt first field
    return index;
  }

  onStateChange(index) {
    var sortings = this.state.sortings.map(
      function(sorting, i) {
        // if (i == index) sorting = this.nextSortingState(sorting);  //retain existing setting for all others
        sorting = i === index ? this.nextSortingState(sorting) : 'both'; // Advance current sorter, and set reset all others
        return sorting;
      }.bind(this)
    );

    this.setState({
      sortings: sortings
    });
  }

  nextSortingState(state) {
    var next;
    switch (state) {
      case 'both':
        next = 'asc';
        break;
      case 'asc':
        next = 'desc';
        break;
      case 'desc':
        // next= "both"
        next = 'asc';
        break;
    }
    return next;
  }
}
