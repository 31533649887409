import React from 'react';
import PropTypes from 'prop-types';
import ResourceItemVideoSection from '../../Resources/components/ResourceItem/ResourceItemVideoSection/ResourceItemVideoSection';
import ResourceItemDocumentSection from '../../Resources/components/ResourceItem/ResourceItemDocumentSection/ResourceItemDocumentSection';
import ResourceItemImageSection from '../../Resources/components/ResourceItem/ResourceItemImageSection/ResourceItemImageSection';
import ResourceItemBodyTextSection from '../../Resources/components/ResourceItem/ResourceItemBodyTextSection/ResourceItemBodyTextSection';
import { isLoaded } from 'rest-states';
import ContentModulesCurrentHeader from './ContentModulesCurrent/ContentModulesCurrentHeader';

export default function ContentModulesResource(props) {
  const resourceItem = props.resourceItems[props.slug];

  const renderResourceItem = (resourceItem) => {
    return (
      <>
        <ResourceItemVideoSection resourceItem={resourceItem} />
        <ResourceItemDocumentSection resourceItem={resourceItem} />
        <ResourceItemImageSection resourceItem={resourceItem} />
        <ResourceItemBodyTextSection resourceItem={resourceItem} />
      </>
    );
  };

  return resourceItem && Object.keys(resourceItem).length > 0 ? (
    <>
      <ContentModulesCurrentHeader
        nextStep={props.nextStep}
        prevStep={props.prevStep}
        isFirstStep={props.isFirstStep}
        isFinalStep={props.isFinalStep}
        currentModule={props.currentModule}
      />
      <br />
      <div className="header">
        <div className="module-header">
          <h3 className="title">{resourceItem.data.title}</h3>
          {isLoaded(resourceItem && resourceItem.getRestState) && renderResourceItem(resourceItem.data)}
        </div>
      </div>
    </>
  ) : (
    <div />
  );
}

ContentModulesResource.propTypes = {
  resourceItems: PropTypes.object,
  slug: PropTypes.string,
  nextStep: PropTypes.func
};
