import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isFetching, isLoaded } from 'rest-states';

import ShowTerms from '../components/ShowTerms';

import * as TermsActionCreators from 'store/actions/TermsActions';
import * as UserActionCreators from 'store/actions/UserActions';

function select(store) {
  return { store };
}

class ShowTermsContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  };

  componentDidMount() {
    const { dispatch, store } = this.props;

    const userActions = bindActionCreators(UserActionCreators, dispatch);

    if (!isFetching(store.currentUserState.getRestState) && !isLoaded(store.currentUserState.getRestState)) {
      userActions.loadCurrentUser();
    }
  }

  componentDidUpdate() {
    const { store } = this.props;

    if (store.currentUser !== this.state.currentUser) {
      this.setState({ currentUser: store.currentUser });
    }
  }

  render() {
    const { currentUser } = this.state;

    if (!this.state.currentUser) {
      return null;
    }

    return (
      <ShowTerms
        show={currentUser && !currentUser.accepted_current_terms}
        labels={{
          userName: currentUser && currentUser.first_name
        }}
        actions={{
          acceptTerms: this.acceptTerms
        }}
      />
    );
  }

  acceptTerms = terms => {
    const { dispatch } = this.props;
    const actions = bindActionCreators(TermsActionCreators, dispatch);
    actions.acceptTerms();
  };
}

export default connect(select)(ShowTermsContainer);
