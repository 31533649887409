import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'common/Mixpanel';
import UserAvatar from 'mn-react/components/UserAvatar';
import ImageUploadButton from '../../Editor/ImageUploadButton';
import Editor from '../../Editor';
import ImagePreview from './ImagePreview';
import Toast from 'common/toast';
import { queryParams } from 'web/utils/uri';
import { isInputEmpty } from 'web/utils/formHelpers';

import styles from './createPostStyles.css';

export default class CreatePost extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    communityId: PropTypes.number.isRequired,
    ensureUserHasConfirmed: PropTypes.func
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.state = this.emptyState();
  }

  render() {
    return this.isEditing() ? this.renderActiveNewPost() : this.renderInactiveNewPost();
  }

  renderActiveNewPost() {
    return (
      <div className="mn-card new-community-post">
        <div className="new-post-banner">
          <UserAvatar user={this.props.store.currentUser} size='size-40' />
          <span className="username-banner">{this.props.store.currentUser.username}</span>
          <ImageUploadButton
            onSelectImage={this.setImage}
            store={this.props.store}
            ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
          />
        </div>
        { this.showPostEdit() }
      </div>
    );
  }

  renderInactiveNewPost() {
    return (
      <div className="mn-card new-community-post">
        <div className="new-post-banner">
          <UserAvatar user={this.props.store.currentUser} size='size-40' />
          <div className="editor-placeholder" onClick={this.startEditing}>
            What are you up to?
          </div>
          <ImageUploadButton
            onSelectImage={this.setImage}
            image={this.state.image}
            store={this.props.store}
            ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
          />
        </div>
      </div>
    );
  }

  componentWillReceiveProps(props) {
    // update to passed in community, unless the user has changed the community in here
    if (!this.state.haveSelectedCommunity) {
      const currentUser = props.store.currentUser;
      this.setState({
        communityId: currentUser.communityIdFor(this.props.communityId)
      });
    }
  }

  showPostEdit = () => {
    const { image } = this.state;

    if (this.isSaving()) {
      return <div>saving...</div>;
    }

    return (
      <form className={styles.form} onSubmit={this.submit}>
        <Editor
          onChange={this.setBody}
          withImageUploader={false}
          placeholder="What are you up to?"
          onSelectImage={this.setImage}
          image={image}
          id={'new-post-editor'}
          focusOnStart={this.props.focusNewPostOnStart}
        />

        <ImagePreview cancelImage={this.setImage.bind(null, null)} image={image} />

        <div className={styles.toolbar}>
          <input
            type="button"
            onClick={() => { this.stopEditing(); Mixpanel.track('New Post Canceled'); }}
            value="Cancel"
            className="mn-button is-link"
          />
          <input
            type="submit"
            name="commit"
            value="Post"
            className="mn-button is-primary done community-dropdown-post-btn"
          />
        </div>
      </form>
    );
  };

  setCommunityId = id => this.setState({ communityId: id, haveSelectedCommunity: true });

  startEditing = () => {
    if (this.props.ensureUserHasConfirmed(this.props.store.currentUser)) {
      Mixpanel.track('New Post Started');
      this.props.actions.startNewPost();
    }
  }

  stopEditing = () => {
    this.setState({ image: null, body: this.defaultBody() });
    this.props.actions.cancelNewPost();
  }

  isEditing = () => this.props.store.posts.editing.writingNewPost;

  isSaving = () => this.props.store.posts.editing.savingPost;

  submit = e => {
    e.preventDefault();

    const { body, image } = this.state;

    if (isInputEmpty(body) && image === null) {
      Toast.add('Unable to create an empty post, please provide either some text or an image.', { expires: 5000 });
      Mixpanel.track('New Post Failed');
    } else {
      const post = { body: body, community_id: this.state.communityId };
      if (image) {
        post.image_file = this.state.image;
      }
      this.setState(this.emptyState());
      this.props.actions.startSavingPost();
      this.props.actions.save(post);

      this.stopEditing();
      Mixpanel.track('New Post Submitted');
    }
  };

  emptyState = () => {
    return {
      image: null,
      body: this.defaultBody(),
      haveSelectedCommunity: false
    };
  };

  defaultBody = () => (this.isFirstTime() ? queryParams()['post[body]'] || '' : '');

  isFirstTime = () => {
    if (this.hasRun) {
      return false;
    }
    this.hasRun = true;
    return true;
  };

  setImage = val => {
    if (val == null) {
      // reset image
      this.setState({ image: null });
      Mixpanel.track('New Post Image Removed');
    } else {
      this.startEditing();
      this.setState({ image: val });
      Mixpanel.track('New Post Image Attached');
    }
  };

  setBody = val => this.setState({ body: val });
}
