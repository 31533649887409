import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import momentTz from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import constant from 'web/utils/constants';
import { Modal } from 'react-bootstrap';
import MeetingForUser from 'models/MeetingForUser';
import MeetingWithSelect from './MeetingWithSelect';
import OpenTokMinRequirements from './OpenTokMinRequirements';
import EnterMeetingBtn from './EnterMeetingBtn';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { minsAfterMidnight } from 'util/datetime/dateHelpers';
import UIText from 'store/UIText';
import ModalHeading from 'util/form/ModalHeading';

Moment.locale('en');
momentLocalizer();

export default class MeetingForm extends React.Component {
  static propTypes = {
    meeting: PropTypes.instanceOf(MeetingForUser),
    actions: PropTypes.object,
    labels: PropTypes.object,
    meetingState: PropTypes.object,
    meetWithPartners: PropTypes.object,
    avalibility: PropTypes.object,
    meetingSettings: PropTypes.object.isRequired
  };

  render() {
    const { meeting, actions } = this.props;
    return (
      <div>
        <Modal show={true} onHide={actions.close}>
          <ModalHeading title={this.props.labels.title} onClose={actions.close} />
          <Modal.Body>
            <OpenTokMinRequirements />
            <div className="mn-flex-form">
              <form action="">
                {this.selectMeetingWith()}
                {this.selectDate(meeting)}
                {this.showParticipantTimezoneInfo()}
                {this.enterNotes()}
                {this.enterPrivateNurseNotes()}

                <div className="mn-flex-form-action-buttons">
                  {this.cancelCallButton()}
                  <button type="button" onClick={actions.save} className={(meeting && meeting.is_confirmed) ? 'is-secondary' : 'is-primary' } disabled={!this.isFormValid()}>
                    {this.props.labels.action}
                  </button>
                  {(meeting && meeting.is_confirmed) ? <EnterMeetingBtn meeting={meeting} /> : null}
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  handleChange = (field, e) => {
    this.props.actions.handleChange(field, e.target.value);
  };

  isFormValid = () => this.isValidDate() && this.isValidTime() && this.props.meetingState.with_id !== undefined;

  handleChangeDate = (field, value) => {
    this.props.actions.handleChange(field, value);
  };

  rulesForDay = d => {
    const availabilityTime = Moment(d)
      .format('dddd')
      .toLowerCase();

    return this.props.availability.weekly[availabilityTime];
  };

  isValidDate = () => this.rulesForDay(this.props.meetingState.meeting_at);

  isValidTime = () => {
    if (!this.isValidDate()) {
      return false;
    }
    const times = this.rulesForDay(this.props.meetingState.meeting_at);
    const mins = minsAfterMidnight(this.props.meetingState.meeting_at);

    return times.startMins <= mins && mins <= times.endMins;
  };

  meetWithChange = id => {
    this.props.actions.handleChange('with_id', parseInt(id));
  };

  selectMeetingWith() {
    const { meetingState } = this.props;
    if (meetingState.isNewMeeting) {
      const labelUserType = meetingState.meetingUser.meetsWith() === 'Nurse' ? 'Nurse or Coach' : 'Patient';
      const labelCaption = `Choose a ${labelUserType}`;
      return (
        <div className="mn-flex-form-item">
          <label>{labelCaption}</label>
          <MeetingWithSelect
            meetingUser={meetingState.meetingUser}
            with_id={meetingState.with_id}
            meetWithPartners={this.props.meetWithPartners}
            onChange={this.meetWithChange}
          />
        </div>
      );
    } else {
      return false;
    }
  }

  cancelCallButton() {
    const { actions, meetingState } = this.props;

    if (meetingState.isNewMeeting) {
      return (
        <button type="button" onClick={actions.delete} className="is-link">
          Cancel
        </button>
      );
    }

    return (
      <button type="button" onClick={actions.delete} className="is-secondary">
        Cancel this call
      </button>
    );
  }

  showDateTimeField(meeting) {
    if (this.props.meetingState.isNewMeeting) return true;
    return !meeting.is_confirmed || this.props.meetingState.meetingUser.isNurse();
  }

  selectDate = meeting => {
    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);
    const isInvalidTime = this.isValidDate() && !this.isValidTime();

    if (this.showDateTimeField(meeting)) {
      return (
        <div className="mn-flex-form-item">
          <label>Call date and time</label>
          <div className="mn-flex-form-date-time-picker">
            <DateTimePicker
              format="ddd, D MMM, h:mm A"
              value={this.props.meetingState.meeting_at}
              onChange={this.handleChangeDate.bind(this, 'meeting_at')}
              step={15}
              min={minDate}
            />
            {!this.isValidDate() && (
              <div className="meetingTime__error-message">
                <UIText>Sorry this meeting date is unavailable</UIText>
              </div>
            )}
            {isInvalidTime && (
              <div className="meetingTime__error-message">
                <UIText>Sorry this meeting time is unavailable for the selected date</UIText>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  /**
   * Determine and render a meeting time in a participants time zone
   *
   * @private
   *
   * @method showParticipantTimezoneInfo
   *
   * @return {Component} A component displaying the meeting in the participants time zone
   */
  showParticipantTimezoneInfo() {
    if (this.props.meetingState.with_id && this.props.meetingState.meeting_at) {
      const meetingTime = momentTz(this.props.meetingState.meeting_at);
      const meetingParticipant = find(this.props.meetWithPartners.items, {
        id: this.props.meetingState.with_id
      });

      // During form load when confirming the meeting theres a condition where the meetingParticipant could be undefined
      // Doing a check here to ensure its present
      const hasTimezoneInformation = meetingParticipant && meetingParticipant.time_zone;

      if (!hasTimezoneInformation) {
        return;
      }

      const now = momentTz();

      const browserTimeZoneOffset = now.utcOffset();
      const participantTimeZoneOffset = now.tz(meetingParticipant.time_zone).utcOffset();

      if (browserTimeZoneOffset === participantTimeZoneOffset) {
        return;
      }

      const meetingTimeParsed = `${meetingTime
        .tz(meetingParticipant.time_zone)
        .format(constant.SHORT_TIME_DAY_FORMAT)} for ${startCase(toLower(meetingParticipant.name))}`;

      return (
        <div className="mn-flex-form-links">
          <img className="meeting-form-user-time-parsed-icon" src="/assets/icon-timezone.svg" />
          <span>{meetingTimeParsed}</span>
        </div>
      );
    }
  }

  enterNotes() {
    if (this.allowPatientNotes() === false) {
      return null;
    }
    if (this.props.meetingState.meetingUser.canViewPrivateNotes()) {
      if (this.props.meetingState.notes) {
        return (
          <div className="mn-flex-form-item">
            <label>Notes from the patient</label>
            <textarea
              placeholder="Any notes or items to discuss?"
              value={this.props.meetingState.notes}
              disabled="true"/>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return (
        <div className="mn-flex-form-item">
          <label>Notes (shared with your call partner)</label>
          <textarea
            placeholder="Any notes or items to discuss?"
            value={this.props.meetingState.notes || ''}
            onChange={this.handleChange.bind(this, 'notes')}
          />
        </div>
      );
    }
  }

  allowPatientNotes = () => this.props.meetingSettings.options.allow_patient.add_note;

  enterPrivateNurseNotes() {
    if (this.props.meetingState.meetingUser.canViewPrivateNotes()) {
      return (
        <div className="mn-flex-form-item">
          <label>Private Notes</label>
          <textarea
            placeholder="Enter notes here"
            value={this.props.meetingState.private_notes || ''}
            onChange={this.handleChange.bind(this, 'private_notes')}
          />
        </div>
      );
    } else {
      return false;
    }
  }
}
