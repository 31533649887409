import { YoutubeTrackVideoInteractionApiModel } from './models';

/**
 * Transform local data to the resource_activities API model
 *
 * @method toApi
 *
 * @param {String} interaction
 *        A string describing the interaction
 *
 * @return {Object} A new object representing resource_activities api model
 */
export const toApi = interaction => {
  const transformedData = {
    activityKey: null
  };

  if (interaction === 'playedVideo') {
    transformedData.activityKey = 'resource.played_video';
  }

  return new YoutubeTrackVideoInteractionApiModel(interaction, transformedData);
};
