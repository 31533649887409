import React from 'react';
import ResourceListItem from './ResourceListItem/ResourceListItem';
import ResourceHeader from '../ResourceHeader/ResourceHeader';
import { keyboardInteraction } from '../resourceHelpers';
import { isLoaded, isFailed } from 'rest-states';
import MnSpinner from 'mn-react/components/MnSpinner';
import Toast from 'common/toast';
import Mixpanel from 'common/Mixpanel';

class ResourceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    var resourceList = this.props.resources.resourceList[this.props.slug];

    if (!resourceList || this.props.slug === 'favorites' || isFailed(resourceList.getRestState)) {
      this.props.loadResourceList(this.props.slug);
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate() {
    var resourceList = this.props.resources.resourceList[this.props.slug];

    if (this.state.loading && (isLoaded(resourceList.getRestState) || isFailed(resourceList.getRestState))) {
      this.setState({ loading: false });

      if (isFailed(resourceList.getRestState)) {
        Toast.add('Failed to load resources, please check your internet connection and try again');
      }
    }
  }

  render() {
    const { slug, resources } = this.props;

    if (this.state.loading) {
      return (
        <div className="resources-loading">
          <MnSpinner />
        </div>
      );
    } else {
      return (
        <div>
          <ResourceHeader
            navigateBack={this.props.navigateBack}
            resourceList={resources.resourceList[slug].data}
            windowSize={this.props.windowSize}
          />
          <div>{this._resourceList()}</div>
        </div>
      );
    }
  }

  /**
   * Get the resource list elements
   *
   * @private
   *
   * @method _resourceList
   *
   * @return {Array/Elements} An array of elements representing the resource list
   */
  _resourceList() {
    const resourceList = this.props.resources.resourceList[this.props.slug].data;
    const hasResources = resourceList && resourceList.resources && resourceList.resources.length;

    if (!hasResources) {
      return null;
    }

    return resourceList.resources.map(resource => {
      return (
        <div
          className="resource-list-item"
          key={resource.id}
          tabIndex="0"
          onClick={() => {
            Mixpanel.track(`Resource Item Selected - ${resource.slug}`);
            this.props.selectResourceItem(resource.slug);
          }}
          onKeyPress={target => {
            keyboardInteraction.handleKeyPress(target, () => {
              Mixpanel.track(`Resource Item Selected - ${resource.slug}`);
              this.props.selectResourceItem(resource.slug);
            });
          }}
        >
          <ResourceListItem
            resource={resource}
            setResourceFavorite={this.props.setResourceFavorite}
            removeResourceFavorite={this.props.removeResourceFavorite}
          />
        </div>
      );
    });
  }
}

export default ResourceList;
