import React from 'react';
import PropTypes from 'prop-types';
import MnIcon from 'mn-react/components/MnIcon';

export default class ResourceLinkPreview extends React.Component {
  static propTypes = {
    slug: PropTypes.string.isRequired,
    summary: PropTypes.object.isRequired
  };

  render() {
    return (
      <div onClick={() => { window.location = '/resources/' + this.props.slug; }}>
        <div className="mn-link-preview-image-container">
          {this._renderImageIfExists()}
          {this._renderImageOverlay()}
        </div>
        <div className="mn-link-preview-details">
          <span className="mn-body-copy">{this.props.summary.title}</span>
        </div>
      </div>
    );
  }

  _renderImageIfExists() {
    const imageUrl = this._getImage();

    if (this.props.summary.preview_image_url) {
      return <img src={this.props.summary.preview_image_url} className="mn-link-preview-image" />;
    }

    if (imageUrl) {
      return (
        <div className="mn-link-preview-image mn-link-preview-icon-container">
          <div className="mn-link-preview-icon-wrapper">
            <MnIcon icon={this._getImage()} dimensions={{ width: '100%', height: '100%' }} />
          </div>
        </div>
      );
    }
  }

  _renderImageOverlay() {
    if (this.props.summary.video_url) {
      return (
        <div className="mn-link-icon-overlay-container">
          <span className="mn-link-preview-icon-wrapper">
            <MnIcon icon="mn-play-icon" dimensions={{ width: '100%', height: '100%' }} />
          </span>
        </div>
      );
    }
  }

  _getImage() {
    if (this.props.summary.event) {
      return 'mn-resources-event-icon';
    }

    if (this.props.summary.document_url) {
      return 'mn-resources-document-icon';
    }
  }
}
