import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

export let database;

export const initializeFirebaseDB = () => {
  if (!realTimeEnabled()) {
    const keys = document.body.dataset;

    if (keys.firebaseKeysApikey) {
      const config = {
        apiKey: keys.firebaseKeysApikey,
        databaseURL: keys.firebaseKeysDatabaseurl
      };
      firebase.initializeApp(config);
      database = firebase.database();
    }
  }
};

export const realTimeEnabled = () => !(database === undefined);
