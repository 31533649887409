import React from 'react';
import { keyboardInteraction } from '../../resourceHelpers';
import Mixpanel from 'common/Mixpanel';

class ResourceSectionCard extends React.Component {
  render() {
    return (
      <div
        tabIndex="0"
        onClick={
          () => {
            const slug = this.props.resourceSection.slug;
            Mixpanel.track(`Resource Section Selected - ${slug}`);
            this.props.selectResourceSection(slug);
          }
        }
        onKeyPress={target =>
          keyboardInteraction.handleKeyPress(target, () => {
            const slug = this.props.resourceSection.slug;
            Mixpanel.track(`Resource Section Selected - ${slug}`);
            this.props.selectResourceSection(slug);
          })
        }
        className="resource-section-card"
        style={
          this.props.resourceSection.imageUrl
            ? { backgroundImage: 'url(' + this.props.resourceSection.imageUrl + ')' }
            : {}
        }
      >
        <div className="resource-section-card-section-count-container">{this._sectionCountElement()}</div>
        <div className="resource-section-card-label mn-bold">{this.props.resourceSection.name}</div>
      </div>
    );
  }

  /**
   * Resource count element
   *
   * @private
   *
   * @method _sectionCountElement
   *
   * @return {Element} The resource count element
   */
  _sectionCountElement() {
    return (
      <div className="resource-section-card-resource-count mn-subtext">{this.props.resourceSection.resourceCount}</div>
    );
  }
}

export default ResourceSectionCard;
