import React from 'react';
import PropTypes from 'prop-types';
import LikeView from '../Likes/LikeView';
import Mixpanel from 'common/Mixpanel';

export default class PostLikes extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired
  };

  render() {
    const likes = this.likeCountByKind();

    if (Object.keys(likes).length === 0) {
      return null;
    }

    return (
      <div className="post-likes" onClick={() => { this.props.showAllLikes(); Mixpanel.track('Show All Likes Button'); } }>
        {Object.keys(likes).map(kind => <LikeView key={kind.toString()} likeKind={kind.toString()} likeCount={likes[kind]} />)}
      </div>
    );
  }

  likeCountByKind() {
    var likes = {};

    this.props.post.likes().forEach(like => {
      likes[like.kind] = (likes[like.kind] || 0) + 1;
    });

    return likes;
  }
}
