import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MnSpinner from 'mn-react/components/MnSpinner';
import { isFetching, isLoaded } from 'rest-states';

import * as ChatWithActionCreators from 'store/actions/ChatWithActions';
import * as MessageActionCreators from 'store/actions/MessageActions';
import * as ConversationActionCreators from 'store/actions/ConversationActions';
import * as SettingsActionCreators from 'store/actions/SettingsActions';
import * as UserActionCreators from 'store/actions/UserActions';

import ChatComponent from '../components/chatComponent';

function select(store) {
  return { store };
}

class ConversationWidget extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { dispatch, store } = this.props;
    const conversationActions = bindActionCreators(ConversationActionCreators, dispatch);
    const chatWithActions = bindActionCreators(ChatWithActionCreators, dispatch);
    const settingsActions = bindActionCreators(SettingsActionCreators, dispatch);
    const userActions = bindActionCreators(UserActionCreators, dispatch);

    chatWithActions.load();
    settingsActions.load();

    if (!isFetching(store.currentUserState.getRestState) && !isLoaded(store.currentUserState.getRestState)) {
      userActions.loadCurrentUser();
    }

    if (process.env.NODE_ENV !== 'development') {
      store.pubnub.client.subscribe({
        channel: store.pubnub.pubnubChannel,
        callback: conversationActions.refreshConversations
      });
    }
  }

  componentDidUpdate() {
    const { store } = this.props;

    if (store.currentUser !== this.state.currentUser) {
      this.setState({ currentUser: store.currentUser });
    }
  }

  render() {
    const { dispatch, store } = this.props;
    const chatWithActions = bindActionCreators(ChatWithActionCreators, dispatch);
    const messageActions = bindActionCreators(MessageActionCreators, dispatch);
    const conversationActions = bindActionCreators(ConversationActionCreators, dispatch);
    const hasSettings = store.settings && store.settings.loaded;
    const appDataReady = hasSettings && this.state.currentUser;

    if (!appDataReady) {
      return (
        <div className="conversation-widget-loading">
          <MnSpinner />
        </div>
      );
    }

    return (
      <ChatComponent
        store={store}
        filterUsers={chatWithActions.load}
        filterConversations={conversationActions.load}
        chatWithActions={chatWithActions}
        messageActions={messageActions}
        conversationActions={conversationActions}
      />
    );
  }
}

export default connect(select)(ConversationWidget);
