import React from 'react';
import PropTypes from 'prop-types';
import momentTz from 'moment-timezone';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import UserAvatar from 'mn-react/components/UserAvatar';
import constants from 'mn-react/web/utils/constants';

export default class ConversationMessageHeading extends React.Component {
  static propTypes = {
    conversation: PropTypes.object.isRequired
  };

  render() {
    const { conversation } = this.props;
    const chatParticipants = conversation.otherUsers().slice(0, 6);
    return (
      <div className="heading-container">
        <div className="heading-user-names-container">
          {this.topicText()}
          {this.showParticipantTimezoneInfo(chatParticipants)}
        </div>
        <OtherUsers users={chatParticipants} />
      </div>
    );
  }

  topicText = () => {
    const { conversation } = this.props;

    return <span>{this.userNames(conversation)}</span>;
  };

  userNames = conversation =>
    conversation
      .otherUsers()
      .map(user => user.name)
      .join(', ');

  /**
   * Determine the participant timezone information
   *
   * @private
   *
   * @method showParticipantTimezoneInfo
   *
   * @param {Array} chatParticipants
   *
   * @return {String} The participant timezone parsed information
   */
  showParticipantTimezoneInfo(chatParticipants) {
    // only show timezone when chatting with one person
    if (chatParticipants.length > 1) {
      return;
    }

    const chatParticipant = chatParticipants[0];
    const hasTimezoneInformation = chatParticipant && chatParticipant.time_zone;

    if (!hasTimezoneInformation) {
      return;
    }

    const now = momentTz();

    const browserTimeZoneOffset = now.utcOffset();
    const chatParticipantTimeZoneOffset = now.tz(chatParticipant.time_zone).utcOffset();

    // Check that a timezone is set and that the chat participants are not in the same time zone
    if (browserTimeZoneOffset === chatParticipantTimeZoneOffset) {
      return;
    }

    const meetingTimeParsed = `It’s ${momentTz()
      .tz(chatParticipant.time_zone)
      .format(constants.SHORT_TIME_DAY_FORMAT)} for ${startCase(toLower(chatParticipant.name))}`;

    return <span className="heading-participant-timezone">{meetingTimeParsed}</span>;
  }
}

function OtherUsers({ users }) {
  return (
    <div className="heading-user-list">{users.map(user => <UserAvatar size='size-40' user={user} key={user.id} />)}</div>
  );
}
OtherUsers.propTypes = {
  users: PropTypes.array.isRequired
};
