import BaseModel from 'models/BaseModel';

import { findUserInList } from 'models/users/utils';

export default class Message extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      body: '',
      conversation_id: null,
      owner_id: null,
      created_at: null
    };
    super(item, store, json, properties);
  }

  modalType = () => 'Message';

  owner = () => findUserInList(this.owner_id, this.json.owners, this.json);
}
