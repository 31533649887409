import React from 'react';
import PropTypes from 'prop-types';
import CurrentNurseMeetings from './CurrentNurseMeetings';
import AvailabiltyStatus from '../AvailabiltyStatus';
import NurseMeetingSummary from './NurseMeetingSummary';
import ViewApptForSelector from './ViewApptForSelector';
import RequestNewMeeting from '../RequestNewMeeting';

import Calendar from '../Meetings/Calendar';

export default class NurseMeetings extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    meetingActions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = { viewType: 'list' };
  }

  render() {
    const { store, meetingActions } = this.props;
    const { viewType } = this.state;
    const { meet } = store;
    const { nurses } = meet;
    const allNurseMeetings = meet.all_nurse_meetings;
    const meetings = allNurseMeetings.filteredMeetings(store.currentUser);

    return (
      <div>
        {this.heading()}
        <div className="meetings-heading">
          <ViewApptForSelector
            nurses={nurses}
            currentUser={store.currentUser}
            filter={allNurseMeetings.filter}
            onChange={meetingActions.filterMeetings}
            countMeetingsForUser={allNurseMeetings.countMeetingsForUser.bind(allNurseMeetings)}
          />
          <RequestNewMeeting current_user={store.currentUser} />
        </div>
        <div className="meetings">
          {viewType === 'list' ? (
            <CurrentNurseMeetings
              user={store.currentUser}
              meeting_items={meetings}
              MeetingView={NurseMeetingSummary}
            />
          ) : (
            <Calendar items={meetings} store={store} />
          )}
          <AvailabiltyStatus user={store.currentUser} />
        </div>
      </div>
    );
  }

  switchView = newView => this.setState({ viewType: newView });

  viewList = () => this.switchView('list');

  viewCalendar = () => this.switchView('calendar');

  heading = () => (
    <div className="heading">
      <h1 className="title">
        Upcoming Calls
        <span className="NurseMeetings__view-options">
          <a onClick={this.viewList} className={this.state.viewType === 'list' ? 'selected' : 'unselected'}>
            List
          </a>
          |
          <a onClick={this.viewCalendar} className={this.state.viewType === 'calendar' ? 'selected' : 'unselected'}>
            Calendar View
          </a>
        </span>
      </h1>
    </div>
  );
}
