import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { BellIcon, BellSlashIcon } from 'util/FaIcons';

import ReminderForm from './ReminderForm';

export default class ReminderSummary extends React.Component {
  static propTypes = {
    reminder: PropTypes.object.isRequired,
    config: PropTypes.object,
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showForm: false
    };
  }

  render() {
    const { reminder, config, actions, store } = this.props;
    var form = '';
    if (this.state.showForm) {
      form = (
        <ReminderForm
          reminder={reminder}
          config={config}
          store={store}
          saveAction={actions.save}
          deleteAction={actions.delete}
          showModal={this.state.showForm}
          onClose={this.closeForm}
        />
      );
    }

    return (
      <div
        className={`reminder-summary ${reminder.enabled ? '' : 'reminder-summary-disabled'}`}
      >
        <h3>
          <span className="reminder-summary-show-disabled">{reminder.title}</span>
          <button className="is-link" onClick={this.showForm}>
            Edit
          </button>
        </h3>

        <p className="reminder-summary-show-disabled">
          {reminder.enabled ? <BellIcon /> : <BellSlashIcon title="disabled" />}
          {reminder.frequencyDescription()}
          <span className="reminder-summary__next-due-at pull-right">
            {reminder.next_due_at && moment(reminder.next_due_at).fromNow()}
          </span>
        </p>
        <p className="reminder-summary-show-disabled">
          <em>Notes: {reminder.notes}</em>
        </p>
        {form}
      </div>
    );
  }
  //  display for due reminders, ? maybe? {reminder.is_due && <ExclamationIcon title="Due"/>}

  showForm = e => {
    e.preventDefault();
    this.setState({ showForm: true });
  };

  closeForm = () => {
    this.setState({ showForm: false });
  };
}
