// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EImY9hKhKZ_TjAyxPWO9 {\n  display: flex;\n  flex-direction: column;\n}\n\n.KhEO41i0iQajyDeKSMp3 {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.c2X0LKjHVy2We_HFH8d2 .public-DraftEditor-content {\n}", "",{"version":3,"sources":["webpack://./app/javascript/reactjs/components/Community/components/Posts/createPostStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;AACA","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n}\n\n.toolbar {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.editor :global(.public-DraftEditor-content) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "EImY9hKhKZ_TjAyxPWO9",
	"toolbar": "KhEO41i0iQajyDeKSMp3",
	"editor": "c2X0LKjHVy2We_HFH8d2"
};
export default ___CSS_LOADER_EXPORT___;
