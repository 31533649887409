import React from 'react';
import ContentModulesListItem from './ContentModulesListItem';
import PropTypes from 'prop-types';

export default function ContentModulesList({ currentModule, weeklyModules, inMobileApp, modules }) {
  const listLength = inMobileApp ? 'mn-card module-list mobile' : 'mn-card module-list';
  return (
    <div className={listLength}>
      <ul style={{ listStyleType: 'none' }}>
        {weeklyModules.map((m, index) => {
          return (
            <ContentModulesListItem
              module={m}
              moduleNum={weeklyModules.indexOf(m)}
              isCurrentModule={m.title === currentModule.title}
              key={weeklyModules.indexOf(m)}
              isLast={weeklyModules.length - 1 === index}
            />
          );
        })}
      </ul>
    </div>
  );
}

ContentModulesList.propTypes = {
  currentModule: PropTypes.object,
  weeklyModules: PropTypes.array.isRequired
};
