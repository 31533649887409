/* global location */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Quill from 'quill';
import 'quill-mention';
import fetch from 'isomorphic-fetch';

import ImageUploadButton from './ImageUploadButton';

export default class Editor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    withImageUploader: PropTypes.bool.isRequired,
    placeholder: PropTypes.string,
    onSelectImage: PropTypes.func,
    image: PropTypes.string,
    id: PropTypes.string.isRequired,
    focusOnStart: PropTypes.bool,
    ensureUserHasConfirmed: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      suggestions: []
    };

    this.pendingFilterValue = '';
  };

  onSearchChange(value, callback) {
    this.pendingFilterValue = value;
    const url = `/api/v1/users/autocomplete?term=${value}`;

    fetch(url, { credentials: 'same-origin' })
      .then(response => {
        if (!response.ok && response.status === 401) {
          location.reload();
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        // implement a poor man's promise cancel, only use the results from the current query
        if (this.pendingFilterValue === value) {
          const suggestions = data.users.map((item, _index) => {
            return {
              username: item.username,
              displayName: item.display_name,
              profileImageUrl: item.profile_image_url,
              value: item.username
            };
          });

          callback(suggestions);
        }
      });
  };

  focus = () => {
    try {
      this.quill.focus();
    } catch (err) {}
  };

  componentDidMount() {
    // focus in a timeout, so plugins have a chance to setup first
    if (this.props.focusOnStart) {
      setTimeout(this.focus, 0);
    }

    this.quill = new Quill(`#${this.props.id}`, {
      modules: {
        toolbar: false,
        mention: {
          defaultMenuOrientation: 'top',
          fixMentionsToQuill: true,
          allowedChars: /^[A-Za-z0-9_]*$/,
          mentionDenotationChars: ['@'],
          mentionContainerClass: 'mentionSuggestions',
          listItemClass: 'mentionSuggestionsEntry',
          mentionListClass: null,
          source: this.onSearchChange,
          renderItem: this.renderMention
        }
      },
      placeholder: this.props.placeholder
    });

    this.quill.on('text-change', (_delta, _oldDelta, _source) => {
      this.props.onChange(this.getPostBodyFromDelta(this.quill.getContents()));
    });

    if (this.props.body) {
      this.quill.setText(this.props.body, 'silent');
    }
  }

  render() {
    return (
      <Fragment>
        <div id={ this.props.id }>
        </div>
        {this.props.withImageUploader && (
          <ImageUploadButton
            onSelectImage={this.props.onSelectImage}
            image={this.props.image}
            store={this.props.store}
            ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
          />
        )}
      </Fragment>
    );
  }

  renderMention(mention) {
    return (
      `<div class="mentionSuggestionsEntryContainer">
        <div class="mentionSuggestionsEntryImage">
          <img class="mentionSuggestionsEntryAvatar" src="${mention.profileImageUrl}">
        </div>
        <div class="mentionNameWrapper">
          <div class="mentionSuggestionsEntryUserName">
            @${mention.username}
          </div>
        </div>
      </div>`
    );
  }

  getPostBodyFromDelta = delta => {
    var body = '';

    delta.forEach(entry => {
      if ('insert' in entry) {
        const insert = entry.insert;

        if (typeof insert === 'string') {
          body += insert;
        } else if (insert instanceof Object) {
          if ('mention' in insert) {
            const mention = insert.mention;

            body += mention.denotationChar;
            body += mention.value;
          }
        }
      }
    });

    return body;
  }
}
