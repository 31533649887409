import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFailed } from 'rest-states';
import fetch from 'isomorphic-fetch';
import Mixpanel from 'common/Mixpanel';
import Toast from 'common/toast';

export default class CommunitySwitcher extends React.Component {
  static propTypes = {
    communityId: PropTypes.number.isRequired,
    userActions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    isEmbedded: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    const { store, userActions } = this.props;

    if (!store.currentUser) {
      userActions.loadCurrentUser();
    }

    this.state = {
      open: false,
      selectedCommunityId: null,
      canSwitchCommunities: null,
      currentUser: store.currentUser || null,
      unreadPostsCount: store.currentUser.json.user.unread_posts_count || []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { store, canSwitchCommunities } = this.props;
    const prevStore = prevProps.store;
    const currentUserUpdated = this.state.currentUser !== store.currentUser;

    if (currentUserUpdated) {
      this.setState({
        currentUser: store.currentUser
      });
    }

    const allowChangeCommunity = this.state.currentUser && canSwitchCommunities;

    if (!allowChangeCommunity) {
      return;
    }

    const communityIdUpdated = this.state.selectedCommunityId !== this.props.communityId;

    if (store.currentUser && communityIdUpdated) {
      const communityId = canSwitchCommunities ? this.props.communityId : null;

      this.setState({
        canSwitchCommunities: canSwitchCommunities,
        selectedCommunityId: communityId
      });
    }

    const hasCommunityIdInteraction =
      !this.state.open && this.state.selectedCommunityId && !isNaN(this.state.selectedCommunityId);
    const communityIdStateChanged =
      prevState.selectedCommunityId &&
      this.state.selectedCommunityId.toString() !== prevState.selectedCommunityId.toString();

    const saveCurrentCommunity = hasCommunityIdInteraction && communityIdStateChanged;

    if (saveCurrentCommunity) {
      this.props.currentCommunityActions.saveCurrentCommunity(this.state.selectedCommunityId);
    }

    const saveRestStateUpdated = prevStore.currentCommunity.saveRestState !== store.currentCommunity.saveRestState;

    if (saveRestStateUpdated && isFailed(store.currentCommunity.saveRestState)) {
      Toast.add('Failed to save selected community. Please refresh and try again');
    }

    if (this.state.open && prevState.open !== this.state.open) {
      // The menu has been opened fetch the user to get an up-to-date hash of the unread posts count.
      const url = '/api/v1/user';
      fetch(url, { credentials: 'same-origin' })
        .then(response => {
          if (!response.ok && response.status === 401) {
            window.location.reload();
          }
          return response;
        })
        .then(response => response.json())
        .then(data => this.setState({ unreadPostsCount: data.user.unread_posts_count })
        );
    }

    this._preventEmbeddedPullToRefreshWhileScrollingCommunities();
  }

  render() {
    const { currentUser, selectedCommunityId, canSwitchCommunities } = this.state;
    const componentReady = currentUser && canSwitchCommunities && selectedCommunityId;

    if (!componentReady) {
      return null;
    }

    return (
      <div className="mn-card community-selector">
        <span onClick={() => { Mixpanel.track(`Community Switcher ${this.state.open ? 'Closed' : 'Opened'}`); this._toggleOpen(); }}>
          <h4 className="mn-body-copy mn-bold">{currentUser.communityNameFor(selectedCommunityId)} <i className="fas fa-caret-down"></i>{' '}</h4>
        </span>
        {this.state.open && this._selectCommunity(currentUser)}
      </div>
    );
  }

  /**
   * Toggle the community switcher dropdown menu
   *
   * @private
   *
   * @method _toggleOpen
   *
   * @param {Number} [id]
   *        Optional current selected community id
   */
  _toggleOpen = id => {
    this.setState({ open: !this.state.open });

    // Check that id is present as toggle has no id when opening.
    if (isNaN(id)) {
      return;
    }

    this.setState({
      selectedCommunityId: id
    });
  };

  /**
   * Render a badge with the count of unread posts, or nothing depending on whether there is any unread posts for the community id.
   *
   * @private
   *
   * @param {int} communityId
   *
   * @return {Element} A span with the count of unread posts, or nothing.
   */
  _renderUnreadPostsBadge(communityId) {
    var unreadPosts = this.state.unreadPostsCount[communityId];
    if (unreadPosts > 0) {
      if (unreadPosts > 9) {
        unreadPosts = '9+';
      }
      return (
        <span className='badge'>{unreadPosts}</span>
      );
    }
  }

  /**
   * Select community dropdown menu
   *
   * @private
   *
   * @method _selectCommunity
   *
   * @return {Element} The select community dropdown menu
   */
  _selectCommunity = currentUser => {
    return (
      <ul id="community-list">
        {currentUser.switchableCommunities().map(community => (
          <li key={community.id} onClick={() => this._toggleOpen(community.id)}>
            <Link to={`/posts/community/${community.id}`} onClick={() => { this._scrollToTop(); Mixpanel.track('Community Switched'); }}>
              {community.name} {this._renderUnreadPostsBadge(community.id)}
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  _scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
  * This is a hack used to prevent mobile devices from attempting to
  * refresh the page when a user tries to scroll up in the community
  * selector while the page is scrolled to 0.
  *
  * When a user swipes up on mobile while the main page is scrolled
  * to 0, rather than scroll the community switcher the swipe to
  * refresh UI is shown. To avoid this, whenever a user scrolls in
  * the community switcher while the page is scrolled to 0, we scroll
  * the page to 1. This is enough to prevent the above behaviour and
  * is hopefully subtle enough that most people wont even notice.
  */
  _preventEmbeddedPullToRefreshWhileScrollingCommunities = () => {
    var communityList = document.getElementById('community-list');

    if (communityList) {
      communityList.onscroll = () => {
        if (this.props.isEmbedded && window.scrollY === 0) {
          window.scrollTo({ top: 1, behavior: 'smooth' });
        }
      };
    }
  }
}
