import React from 'react';

const MnPlayIcon = (props) => {
  const dimensions = props.dimensions || {};
  const colour = props.colour || '#00CC69';

  return (
    <svg width={ dimensions.width || '20px' } height={ dimensions.height || '20px' } viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.476 4.476 0 10 0s10 4.476 10 10-4.476 10-10 10S0 15.524 0 10zm7.433 4.178l6.596-3.99a.22.22 0 0 0 0-.376l-6.596-3.99a.213.213 0 0 0-.322.188v7.98c0 .169.182.274.322.188z"
        fill="#fff"
      />
      <path
        d="M14.029 10.188l-6.596 3.99c-.14.086-.322-.02-.322-.188V6.01c0-.169.177-.274.322-.188l6.596 3.99a.22.22 0 0 1 0 .376z"
        fill={colour}
      />
    </svg>
  );
};

export default MnPlayIcon;
