import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import MeetingForUser from 'models/MeetingForUser';
import MeetingFormContainer from '../../containers/MeetingFormContainer';

const localizer = momentLocalizer(moment);

export default class Calendar extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    items: PropTypes.array
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showForm: false,
      showAdd: false,
      startAt: null,
      event: null
    };
  }

  render() {
    const items = this.props.items.map(item => {
      return Object.assign({}, item, {
        start: new Date(item.meeting_at),
        end: moment(new Date(item.meeting_at)).add(30, 'minutes').toDate(),
        title: item.title()
      });
    });

    return (
      <div>
        <BigCalendar
          events={items}
          selectable
          onSelectEvent={event => this.selectEvent(event)}
          onSelectSlot={slotInfo => this.selectTimeSlot(slotInfo)}
          localizer={localizer}
          components={{
            event: Event,
            agenda: {
              event: EventAgenda
            }
          }}
        />

        {// The new call form modal
          this.state.showForm && (
            <MeetingFormContainer
              meeting={new MeetingForUser(this.state.event, this.props.store)}
              showModal={this.state.showForm}
              onClose={this.closeForm} />
          )
        }

        {// The edit call form modal
          this.state.showAdd && (
            <MeetingFormContainer
              current_user={this.props.store.currentUser}
              showModal={this.state.showAdd}
              onClose={this.closeForm}
              startAt={this.state.startAt}
            />
          )
        }
      </div>
    );
  }

  selectEvent = event => {
    this.setState({
      showForm: true,
      event: event
    });
  };

  selectTimeSlot = slotInfo => {
    this.setState({
      showAdd: true,
      startAt: slotInfo.start
    });
  };

  closeForm = () => {
    this.setState({
      showForm: false,
      showAdd: false,
      startAt: null
    });
  };

  addTypeChange = e => {
    this.setState({
      addType: e.currentTarget.value
    });
  };
}

function Event({ event }) {
  return (
    <div className={event.meetingType()}>
      <strong>{event.title}</strong>
    </div>
  );
}

function EventAgenda({ event }) {
  return (
    <span>
      <p className="title">{event.title}</p>
      <p>{event.notes}</p>
    </span>
  );
}
