import React from 'react';
import PropTypes from 'prop-types';

export default class MeetingNotes extends React.Component {
  static propTypes = {
    meeting: PropTypes.object.isRequired
  };

  render() {
    const { meeting } = this.props;
    if (this.haveNotes()) {
      return (
        <div className="notes">
          {this.patientNotes(meeting.notes)}
          {this.privateNotes(meeting.private_notes)}
        </div>
      );
    } else {
      return false;
    }
  }

  haveNotes() {
    const { meeting } = this.props;
    return meeting.notes > '' || meeting.private_notes > '';
  }

  patientNotes(notes) {
    if (notes > '') {
      return (
        <div className="meeting-form__patient-notes">
          <span className="content">{notes}</span>
        </div>
      );
    }
  }

  privateNotes(notes) {
    if (notes > '') {
      return (
        <div className="nurse-notes">
          <h4>Nurse Notes</h4>
          <span className="content">{notes}</span>
        </div>
      );
    }
  }
}
