import React from 'react';
import PropTypes from 'prop-types';
import ReminderForm from './ReminderForm';
import Reminder from 'models/Reminder';

export default class NewBtn extends React.Component {
  static propTypes = {
    saveAction: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired,
    store: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showAdd: false
    };
  }

  render() {
    return (
      <div className="mn-buttons is-pulled-right">
        <button onClick={this.new} className="is-primary" data-cy="new-reminder-button">
          New Reminder
        </button>
        {this.form()}
      </div>
    );
  }

  form() {
    if (this.state.showAdd) {
      const { config, saveAction, store, userId } = this.props;
      return (
        <ReminderForm
          reminder={new Reminder({ user_id: userId }, store)}
          config={config}
          store={store}
          saveAction={saveAction}
          showModal={this.state.showAdd}
          onClose={this.closeForm}
        />
      );
    }
  }

  new = e => {
    e.preventDefault();
    this.setState({ showAdd: true });
  };

  closeForm = meeting => {
    this.setState({ showAdd: false });
  };
}
