import React from 'react';
import { Provider } from 'react-redux';

import ResourcesContainer from '../containers/ResourcesContainer';

export default props => {
  const store = window.store;
  const appProps = {
    hideNavigationHeader: props.hide_navigation_header,
    resourceSections: props.resource_sections && JSON.parse(props.resource_sections),
    resourceSection: props.resource_section,
    resource: props.resource
  };

  return (
    <Provider store={store}>
      <ResourcesContainer {...appProps} />
    </Provider>
  );
};
