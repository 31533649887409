import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import NoBadges from '../../../../../assets/images/content_modules/no_badges.png';
import OneBadge from '../../../../../assets/images/content_modules/one_badge.png';
import TwoBadges from '../../../../../assets/images/content_modules/two_badges.png';
import ThreeBadges from '../../../../../assets/images/content_modules/three_badges.png';
import FourBadges from '../../../../../assets/images/content_modules/four_badges.png';
import AllBadges from '../../../../../assets/images/content_modules/all_badges.png';
import NoBadgesMobile from '../../../../../assets/images/content_modules/content_modules_mobile/no_badges_mobile.png';
import OneBadgeMobile from '../../../../../assets/images/content_modules/content_modules_mobile/one_badge_mobile.png';
import TwoBadgesMobile from '../../../../../assets/images/content_modules/content_modules_mobile/two_badges_mobile.png';
import ThreeBadgesMobile from '../../../../../assets/images/content_modules/content_modules_mobile/three_badges_mobile.png';
import FourBadgesMobile from '../../../../../assets/images/content_modules/content_modules_mobile/four_badges_mobile.png';
import AllBadgesMobile from '../../../../../assets/images/content_modules/content_modules_mobile/all_badges_mobile.png';
import { MyProgramContext } from '../context/MyProgramContext';
import { getMilestones } from './ProgramMilestones';

export default function ProgramBadges({ inMobileApp }) {
  const context = useContext(MyProgramContext);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const Badges = () => {
    const milestones = getMilestones();
    const milestoneTwo = milestones[0];
    const milestoneThree = milestones[1];
    const milestoneFour = milestones[2];
    const milestoneFive = milestones[3];

    const noBadges = inMobileApp ? NoBadgesMobile : NoBadges;
    const oneBadge = inMobileApp ? OneBadgeMobile : OneBadge;
    const twoBadges = inMobileApp ? TwoBadgesMobile : TwoBadges;
    const threeBadges = inMobileApp ? ThreeBadgesMobile : ThreeBadges;
    const fourBadges = inMobileApp ? FourBadgesMobile : FourBadges;
    const allBadges = inMobileApp ? AllBadgesMobile : AllBadges;

    switch (true) {
      case context.completedModules === 0:
        return <img src={noBadges} alt="No Badges" />;
      case context.completedModules >= 1 && context.completedModules < milestoneTwo:
        return <img src={oneBadge} alt="One Badge" />;
      case context.completedModules >= milestoneTwo && context.completedModules < milestoneThree:
        return <img src={twoBadges} alt="Two Badges" />;
      case context.completedModules >= milestoneThree && context.completedModules < milestoneFour:
        return <img src={threeBadges} alt="Three Badges" />;
      case context.completedModules >= milestoneFour && context.completedModules < milestoneFive:
        return <img src={fourBadges} alt="Four Badges" />;
      case context.completedModules === milestoneFive:
        return <img src={allBadges} alt="All Badges" />;
      default:
        return <img src={NoBadges} alt="No Badges" />;
    }
  };

  const ProgramBadgesWeb = () => {
    return (
      <>
        <div className="badges" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <Badges />
        </div>
        {isHovering && (
          <div className="badges-infobox">
            <p>Complete modules to earn badges!</p>
          </div>
        )}
      </>
    );
  };

  const ProgramBadgesMobile = () => {
    return (
      <div className="badges-mobile">
        <Badges />
      </div>
    );
  };

  return inMobileApp ? <ProgramBadgesMobile /> : <ProgramBadgesWeb />;
}

ProgramBadges.propTypes = {
  totalModules: PropTypes.number
};
