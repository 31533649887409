/* global TB */
import React from 'react';

export default class OpenTokMinRequirements extends React.Component {
  render() {
    if (typeof TB !== 'undefined' && TB.checkSystemRequirements() !== TB.HAS_REQUIREMENTS) {
      return (
        <div className="error checks-opentok-minimum-requirements">
          <div className="emoticon-icon" />
          <h4>Your browser doesn’t currently support meetings</h4>
          <p>To enter the meeting you’ll need to switch to Chrome, Firefox, Opera, or Internet Explorer 8 or newer.</p>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
