import React from 'react';
import PropTypes from 'prop-types';

export default class UserAvatarInline extends React.Component {
  static propTypes = {
    user: PropTypes.object
  };

  render() {
    const { user } = this.props;
    const userUrl = `/users/${user.id}`;

    return (
      <span className="avatar">
        <a href={userUrl} title={user.name}>
          <span>
            <img src={user.links.profile_image_small_url} />
          </span>
        </a>
      </span>
    );
  }
}
