import actionTypes from '../constants';

import Post from 'models/Post';

export default {
  requestCollection: actionTypes.REQUEST_POSTS,
  receiveCollection: actionTypes.RECEIVE_POSTS,
  receiveItem: actionTypes.RECEIVE_POST,
  removeItem: actionTypes.REMOVE_POST,
  clearCollection: actionTypes.CLEAR_POSTS,

  storeKey: 'posts',
  Model: Post,

  collectionKey: 'posts',
  itemKey: 'post',

  apiEndPoint: '/api/v1/posts',
  bodyParam: 'post'
};
