import BaseModel from 'models/BaseModel';

export default class CompletedResource extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      resource_id: null,
      created_at: null
    };
    super(item, store, json, properties);
  }
}
