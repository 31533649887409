import React from 'react';
import PropTypes from 'prop-types';
import Combobox from 'react-widgets/lib/Combobox';
import UserRoleBadge from '../../../UserRoleBadge';

export default class MeetingWithSelect extends React.Component {
  static propTypes = {
    meetingUser: PropTypes.object,
    onClose: PropTypes.func,
    with_id: PropTypes.number,
    meetWithPartners: PropTypes.object
  };

  render() {
    if (this.partnersLoaded()) {
      const openIt = !this.props.with_id;

      return (
        <div className="mn-flex-form-combobox select-meeting-partner">
          <Combobox
            defaultValue={this.props.with_id}
            filter="contains"
            data={this.otherUsers()}
            valueField="id"
            textField="name"
            placeholder="Search"
            onChange={value => this.props.onChange(value.id)}
            defaultOpen={openIt || false}
            itemComponent={ListItem}
          />
        </div>
      );
    } else {
      return <span>loading partners...</span>;
    }
  }

  partnersLoaded() {
    return this.props.meetWithPartners.loaded;
  }

  otherUsers() {
    return this.props.meetWithPartners.items;
  }
}

const ListItem = ({ item }) => (
  <span className="user-dropdown-item">
    <img src={item.profile_image_url} className="user-image" />
    {item.name}
    <UserRoleBadge user={item} />
  </span>
);
