import React, { useContext, useEffect } from 'react';
import Mixpanel from 'common/Mixpanel';
import { getMilestones } from './ProgramMilestones';
import BadgeOne from '../../../../../assets/images/content_modules/badge_one.png';
import BadgeTwo from '../../../../../assets/images/content_modules/badge_two.png';
import BadgeThree from '../../../../../assets/images/content_modules/badge_three.png';
import BadgeFour from '../../../../../assets/images/content_modules/badge_four.png';
import BadgeFive from '../../../../../assets/images/content_modules/badge_five.png';
import { MyProgramContext } from '../context/MyProgramContext';
import { CheckIcon } from '../../../util/FaIcons';

export default function ContentModulesPopUp({ close, nextStep, moduleName }) {
  const context = useContext(MyProgramContext);
  const milestones = getMilestones();
  const milestoneTwo = milestones[0];
  const milestoneThree = milestones[1];
  const milestoneFour = milestones[2];
  const milestoneFive = milestones[3];

  useEffect(() => {
    nextStep();
  }, []);

  const DisplayBadge = () => {
    switch (context.completedModules) {
      case 1:
        return <img src={BadgeOne} alt="Badge One" />;
      case milestoneTwo:
        return <img src={BadgeTwo} alt="Badge Two" />;
      case milestoneThree:
        return <img src={BadgeThree} alt="Badge Three" />;
      case milestoneFour:
        return <img src={BadgeFour} alt="Badge Four" />;
      case milestoneFive:
        return <img src={BadgeFive} alt="Badge Five" />;
      default:
        return <CheckIcon />;
    }
  };

  const DisplayText = () => {
    let text = '';
    let prompt = 'Feel free to take a break or keep up the momentum and start the next module.';

    switch (context.completedModules) {
      case 1:
        text = "You've completed the first milestone, keep up the awesome work.";
        break;
      case milestoneTwo:
        text = "You've completed the second milestone, keep up the awesome work.";
        break;
      case milestoneThree:
        text = "You've completed the third milestone, keep up the awesome work.";
        break;
      case milestoneFour:
        text = "You've completed the fourth milestone, keep up the awesome work.";
        break;
      case milestoneFive:
        text = "You've completed all the milestones!";
        prompt = 'Feel free to revisit these modules whenever you like.';
        break;
      default:
        text = 'You completed a module, keep up the awesome work.';
        break;
    }

    return (
      <>
        <p>{text}</p>
        <p>{prompt}</p>
      </>
    );
  };

  return (
    <div className="pop-up">
      <div className="content">
        <div className="pop-up-badge">
          <DisplayBadge />
        </div>
        <h3>Congratulations!</h3>
        <DisplayText />
        <button className="mn-button next-button" onClick={() => { Mixpanel.track(`${moduleName} - Completed`); close(); }}>
          Continue
        </button>
      </div>
    </div>
  );
}
