// See https://www.npmjs.com/package/mirror-creator
// Allows us to set up constants in a slightly more concise syntax. See:
// client/app/bundles/HelloWorld/actions/helloWorldActionCreators.jsx
import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  'REQUEST_SETTINGS',
  'RECEIVE_SETTINGS',

  // Community
  'REQUEST_POSTS',
  'RECEIVE_POSTS',
  'RECEIVE_POST',
  'REMOVE_POST',
  'CLEAR_POSTS',
  'FINISHED_EDITING',
  'START_NEW_POST',
  'CANCEL_NEW_POST',
  'START_SAVING_POST',
  'EDIT_POST',
  'EDIT_REPLY',
  'START_REPLY_TO_POST',
  'RECEIVE_REPLY',
  'RECEIVE_LIKE',

  'REMOVE_REPLY',

  // UPDATING_YOUR_COMMUNITIES
  'CURRENT_COMMUNITY_SAVE_IN_PROGRESS',
  'CURRENT_COMMUNITY_SAVE_SUCCESS',
  'CURRENT_COMMUNITY_SAVE_FAILURE',

  // chatty_chat
  'REQUEST_CONVERSATIONS',
  'RECEIVE_CONVERSATIONS',
  'CLEAR_CONVERSATIONS',
  'RECEIVE_CONVERSATION',
  'RECEIVE_MESSAGE',
  'REMOVE_MESSAGE',
  'REQUEST_CHAT_WITH',
  'RECEIVE_CHAT_WITH',
  'CHAT_WITH_USER_SELECTED',
  'SET_CURRENT_CONVERSATION',

  'REQUEST_REMINDERS',
  'RECEIVE_REMINDERS',
  'RECEIVE_REMINDER',
  'REMOVE_REMINDER',
  'REQUEST_REMINDERS_CONFIG',
  'RECEIVE_REMINDERS_CONFIG',

  // MeetAndGreet
  'REQUEST_CURRENT_MEETINGS',
  'RECEIVE_CURRENT_MEETINGS',
  'REQUEST_ALL_NURSE_MEETINGS',
  'RECEIVE_ALL_NURSE_MEETINGS',

  // 'REQUEST_MEETING_PARTNERS_FOR',
  'RECEIVE_MEETING_PARTNERS',
  'SAVE_MEETING',
  'DELETE_MEETING',

  'REQUEST_NURSES',
  'RECEIVE_NURSES',
  'FILTER_MEETINGS',
  // 'FETCH_CURRENT_MEETINGS_SUCCESS',

  'REQUEST_MEDICATIONS',
  'RECEIVE_MEDICATIONS',
  'RECEIVE_MEDICATION',
  'REMOVE_MEDICATION',

  // Users
  'REQUEST_USERS',
  'RECEIVE_USERS',
  'RECEIVE_USER',
  'REMOVE_USER',
  'REQUEST_USER_UPDATE',

  // SELF_SIGNUP_REDEMPTIONS
  'SELF_SIGNUP_REDEMPTIONS_GET_IN_PROGRESS',
  'SELF_SIGNUP_REDEMPTIONS_GET_SUCCESS',
  'SELF_SIGNUP_REDEMPTIONS_GET_FAILURE',
  'SELF_SIGNUP_REDEMPTIONS_SAVE_IN_PROGRESS',
  'SELF_SIGNUP_REDEMPTIONS_SAVE_SUCCESS',
  'SELF_SIGNUP_REDEMPTIONS_SAVE_FAILURE',

  // CURRENT_USER_STATES
  'CURRENT_USER_STATE_GET_IN_PROGRESS',
  'CURRENT_USER_STATE_GET_SUCCESS',

  // YOUTUBE_RESOURCE_VIDEOS
  'YOUTUBE_RESOURCE_INTERACTION_SAVE_IN_PROGRESS',
  'YOUTUBE_RESOURCE_INTERACTION_SAVE_SUCCESS',
  'YOUTUBE_RESOURCE_INTERACTION_SAVE_FAILURE',

  // RESOURCES
  'RESOURCE_SECTIONS_GET_IN_PROGRESS',
  'RESOURCE_SECTIONS_GET_SUCCESS',
  'RESOURCE_SECTIONS_GET_FAILURE',
  'RESOURCE_LIST_GET_IN_PROGRESS',
  'RESOURCE_LIST_GET_SUCCESS',
  'RESOURCE_LIST_GET_FAILURE',
  'RESOURCE_ITEM_GET_IN_PROGRESS',
  'RESOURCE_ITEM_GET_SUCCESS',
  'RESOURCE_ITEM_GET_FAILURE',
  'RESOURCE_ITEM_SUMMARY_GET_IN_PROGRESS',
  'RESOURCE_ITEM_SUMMARY_GET_SUCCESS',
  'RESOURCE_ITEM_SUMMARY_GET_FAILURE',
  'RESOURCE_ITEM_TOGGLE_FAVORITE_IN_PROGRESS',
  'RESOURCE_ITEM_TOGGLE_FAVORITE_SUCCESS',
  'RESOURCE_ITEM_TOGGLE_FAVORITE_FAILURE',
  'RESOURCE_ITEM_FAVORITE_STATE_TOGGLE',

  // COMPLETED_RESOURCE
  'RECEIVE_COMPLETED_RESOURCE',
  'REQUEST_COMPLETED_RESOURCE',

  // WINDOW_DELEGATE
  'WINDOW_DELEGATE_SET_WINDOW_SIZE'
]);

export default actionTypes;
