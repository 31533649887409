import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoaded } from 'rest-states';
import { getResourceItemSummary } from 'store/actions/ResourceActions';
import ResourceLinkPreview from './ResourceLinkPreview';
import MnSpinner from 'mn-react/components/MnSpinner';

class ResourceLinkPreviewContainer extends React.Component {
  static propTypes = {
    link: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this._slug = this._getSlugFromLink(this.props.link);
  }

  componentWillMount() {
    this.props.dispatch(getResourceItemSummary(this._slug));
  }

  render() {
    return (
      <div className="mn-link-preview-container mn-mtm mn-mbm">
        {
          this._isLoaded()
            ? this._renderPreview()
            : this._renderLoading()
        }
      </div>
    );
  }

  _isLoaded() {
    return this.props.store.resources.summaries &&
      this.props.store.resources.summaries[this._slug] &&
      isLoaded(this.props.store.resources.summaries[this._slug].getRestState);
  }

  _renderLoading() {
    return (
      <div className='mn-link-preview-loading-container'>
        <MnSpinner size="small" />
      </div>
    );
  }

  _renderPreview() {
    return (
      <ResourceLinkPreview
        slug={this._slug}
        summary={this.props.store.resources.summaries[this._slug].summary}
      />
    );
  }

  _getSlugFromLink(link) {
    return link.split('/').pop();
  }
}

function mapStateToProps(state) {
  return {
    store: state
  };
}

export default connect(mapStateToProps)(ResourceLinkPreviewContainer);
