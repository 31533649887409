import BaseModel from 'models/BaseModel';

import { findUserInList } from 'models/users/utils';

import Message from 'models/Message';

export default class Conversation extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      group_id: '',
      created_at: '',
      updated_at: '',
      pubnub_channel: '',
      heading: '',
      num_unread_messages: 0
    };
    super(item, store, json, properties);
  }

  modalType = () => 'Conversation';

  latestMessage = () => (this.hasMessages() ? this.sortedMessages().slice(-1)[0] : this._nullMessage());

  sortedMessages = () => this.messages().sort(orderByCreatedAt);

  latestMessageTime = () => this.latestMessage().created_at;

  hasMessages = () => this.messages().length > 0;

  hasUnreadMessages = () => this.num_unread_messages > 0;

  messageIds = () => this.item.latest_message_ids || [];

  messages = () => this.messageIds().map(id => this._findMessage(id));

  users = () => (this.item.user_ids || []).map(id => this._findUser(id));

  includesUser = user => (user ? this.item.user_ids.includes(user.id) : true);

  otherUsers = () => (this.item.user_ids || []).map(id => this._findUser(id));

  numberOfUsers = () => this.item.user_ids.length;

  _findUser = id => findUserInList(id, this._usersJson(), this.json);

  _usersJson = () => (this.json.users || []).concat(this.json.owners || []);

  _findMessage = id => new Message(this.json.latest_messages.find(m => +m.id === +id) || {}, this.store, this.json);

  _nullMessage() {
    return {
      body: '',
      created_at: null
    };
  }

  latestMessageSortValue() {
    // Yuck, we want any with messages in desending order, then the rest in a nice predictable order so it doesn't jump around
    if (this.hasMessages() && this.latestMessageTime()) {
      return `  ${9999999999999 - Date.parse(this.latestMessageTime())}`; // at start, so these come first
    } else {
      return `zzzz- ${this.heading} ${this.id}`;
    }
  }
}

function sortByfield(a, b) {
  if (a === null || a === undefined || b === null || b === undefined) {
    return 0;
  } else if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
}

export function orderByLastestMessageFirst(a, b) {
  return sortByfield(a.latestMessageSortValue(), b.latestMessageSortValue());
}

export function orderByCreatedAt(a, b) {
  return sortByfield(a.created_at, b.created_at);
}

export function orderByIncreaingId(a, b) {
  return sortByfield(a.id, b.id);
}
