import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

export default class ShowTerms extends React.Component {
  static propTypes = {
    // true if there is a user and they have not accepted the current terms
    show: PropTypes.bool,
    // has a userName which is the user's first name
    labels: PropTypes.object,
    // has acceptTerms which will accept the terms
    actions: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      // set to true if the user rejects the terms, and should be asked for confirmation
      confirmingRejection: false
    };
  }

  render() {
    const { props, state, showTerms, showReject } = this;
    const { actions, labels, show } = props;
    const { userName } = labels;

    if (!show) return null;

    const modalClass = state.confirmingRejection ? 'reject-terms-modal' : 'terms-modal';

    return (
      <Modal className={`terms modal ${modalClass}`} backdrop="static" show={true} keyboard={false}>
        {state.confirmingRejection ? (
          <div className="content">
            <div className="title">Decline Terms?</div>
            <p className="body">
              Declining the Terms means that you will be logged out of Melon and won’t have access until you accept.
            </p>
            <div className="mn-buttons is-centered">
              <button className="is-primary back-btn" onClick={showTerms}>
                No, go back
              </button>
              <a className="mn-button confirm-btn" rel="nofollow" data-method="delete" href="/users/sign_out">
                Yes, decline terms
              </a>
            </div>
          </div>
        ) : (
          <div className="content">
            {' '}
            <div className="welcome-icon" />
            <div className="title">Welcome back, {userName}</div>
            <p className="body">
              We’ve updated our{' '}
              <a href="/terms" target="_blank">
                Terms & Conditions
              </a>
              ,{' '}
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>
              , and{' '}
              <a href="/community_guide" target="_blank">
                Community Code
              </a>
              . You must accept the updated Terms to continue using Melon.
            </p>
            <button className="is-primary" onClick={actions.acceptTerms}>
              I accept
            </button>
            <button onClick={showReject}>
              I don’t accept
            </button>
          </div>
        )}
      </Modal>
    );
  }

  showTerms = () => {
    this.setState({
      confirmingRejection: false
    });
  };

  showReject = terms => {
    this.setState({
      confirmingRejection: true
    });
  };
}
