import actionTypes from '../constants';
import User from 'models/User';
import MeetingForUser from 'models/MeetingForUser';

import { deprecatedFetchResource, updateResource, deleteResource } from 'backend/ApiResources.js';

function requestCurrentMeetings() {
  return {
    type: actionTypes.REQUEST_CURRENT_MEETINGS
  };
}

function requestNurses() {
  return {
    type: actionTypes.REQUEST_NURSES
  };
}

function postSaveMeeting() {
  return {
    type: actionTypes.SAVE_MEETING
  };
}

const meetingsForUser = (items, store) => (items || []).map(meeting => new MeetingForUser(meeting, store));

function receiveCurrentMeetings(json, store) {
  store.users.append(json.requesting_users);
  store.users.append(json.requested_users);
  return {
    type: actionTypes.RECEIVE_CURRENT_MEETINGS,
    meetings: meetingsForUser(json.meetings, store),
    receivedAt: Date.now()
  };
}

function receiveAllNurseMeetings(json, store) {
  store.users.append(json.requesting_users);
  store.users.append(json.requested_users);
  return {
    type: actionTypes.RECEIVE_ALL_NURSE_MEETINGS,
    meetings: meetingsForUser(json.meetings, store),
    receivedAt: Date.now()
  };
}

function receiveNurses(json) {
  return {
    type: actionTypes.RECEIVE_NURSES,
    nurses: nurses(json.users),
    receivedAt: Date.now()
  };

  function nurses(items) {
    var list = [];
    for (const u of items.values()) {
      list.push(new User(u));
    }
    return list;
  }
}

function receiveMeetingPartners(json, store) {
  return {
    type: actionTypes.RECEIVE_MEETING_PARTNERS,
    partners: partners(json.users),
    receivedAt: Date.now()
  };

  function partners(items) {
    var list = [];
    for (const u of items.values()) {
      const uo = new User(u);
      list.push(uo);
    }
    return list;
  }
}

export function filterMeetings(filter) {
  return {
    type: actionTypes.FILTER_MEETINGS,
    newFilter: filter
  };
}

export function fetchCurrentMeetings() {
  function isFetching(store) {
    return store.meet.meetings.isFetching;
  }

  return function(dispatch, getState) {
    if (isFetching(getState())) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: requestCurrentMeetings,
        url: '/api/v1/meetings',
        onSuccess: receiveCurrentMeetings,
        store: getState()
      };
      deprecatedFetchResource(resource, dispatch, getState);
    }
  };
}

export function fetchAllNurseMeetings() {
  function isFetching(state) {
    return state.meet.all_nurse_meetings.isFetching;
  }

  return function(dispatch, getState) {
    if (isFetching(getState())) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: requestCurrentMeetings,
        url: '/api/v1/meetings/all_nurse_meetings',
        onSuccess: receiveAllNurseMeetings,
        store: getState()
      };
      deprecatedFetchResource(resource, dispatch, getState);
    }
  };
}

export function fetchNurses() {
  function isFetching(state) {
    return state.meet.nurses.isFetching;
  }
  return function(dispatch, getState) {
    if (isFetching(getState())) {
      return Promise.resolve();
    } else {
      const resource = {
        onBegin: requestNurses,
        url: '/api/v1/users?filter[scope]=nurses',
        onSuccess: receiveNurses,
        store: getState()
      };
      deprecatedFetchResource(resource, dispatch, getState);
    }
  };
}

export function requestMeetingPartnersFor(user) {
  function isFetching(state) {
    return state.meet.partners.isFetching;
  }
  return function(dispatch, getState) {
    if (isFetching(getState())) {
      return Promise.resolve();
    } else {
      const resource = {
        url: '/api/v1/users?filter[scope]=meet_with',
        onSuccess: receiveMeetingPartners,
        store: getState()
      };
      deprecatedFetchResource(resource, dispatch, getState);
    }
  };
}
export function deleteMeeting(meeting) {
  const resource = {
    url: '/api/v1/meetings',
    onSuccess: fetchCurrentMeetings
  };
  return function(dispatch, getState) {
    deleteResource(meeting, resource, dispatch, getState);
  };
}

export function saveMeeting(data) {
  const resource = {
    onBegin: postSaveMeeting,
    url: '/api/v1/meetings',
    param: 'meeting',
    onSuccess: fetchCurrentMeetings
  };
  return function(dispatch, getState) {
    updateResource(data, resource, dispatch, getState);
  };
}
