import React from 'react';
import { Provider } from 'react-redux';

import ConversationWidget from '../containers/ConversationWidget';

export default props => {
  const store = window.store;
  const reactComponent = (
    <Provider store={store}>
      <ConversationWidget />
    </Provider>
  );
  return reactComponent;
};
