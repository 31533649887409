import BaseModel from 'models/BaseModel';
import Like from 'models/Like';

export default class Reply extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      body: '',
      post_id: null,
      author_id: null,
      is_deleted: false,
      mentions: null,
      created_at: null
    };
    super(item, store, json, properties);
  }

  modalType = () => 'Reply';

  author = store => store.allUsers.find(this.author_id);

  likes = () => this.item.like_ids.map(id => this._findLike(id));

  _findLike = id => new Like(this.json.likes.find(m => +m.id === +id) || {});
}
