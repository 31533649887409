import { updateResource } from 'backend/ApiResources.js';
import actionTypes from '../constants';
import { apiMethods } from '../../backend/apiConstants';

function savingCurrentCommunityAction() {
  return {
    type: actionTypes.CURRENT_COMMUNITY_SAVE_IN_PROGRESS
  };
}

function savedCurrentCommunityAction(payload) {
  return {
    type: actionTypes.CURRENT_COMMUNITY_SAVE_SUCCESS,
    payload
  };
}

function saveCurrentCommunityFailureAction(payload) {
  return {
    type: actionTypes.CURRENT_COMMUNITY_SAVE_FAILURE,
    payload
  };
}

/**
 * Save the current community
 *
 * @method saveCurrentCommunity
 *
 * @param {Number} id
 *
 * @return {Promise/State} A promise resolved with a redux state update for current community
 */
export function saveCurrentCommunity(id) {
  return (dispatch, getState) => {
    const state = getState();

    const resource = {
      onBegin: savingCurrentCommunityAction,
      onSuccess: savedCurrentCommunityAction,
      onFailure: saveCurrentCommunityFailureAction,
      resourceUrl: `/api/v1/users/${state.currentUser.id}/current_community`,
      method: apiMethods.PATCH,
      param: 'current_community'
    };

    return updateResource({ id }, resource, dispatch, getState);
  };
}
