import React from 'react';
import { Provider } from 'react-redux';

import ContentModules from '../containers/ContentModules';

export default (props) => {
  const store = window.store;
  const appProps = {
    user: props.user,
    inMobileApp: props.inMobileApp,
    mixpanelProjectToken: props.mixpanelProjectToken,
    analyticsUUID: props.analyticsUUID,
    railsENV: props.railsENV
  };

  return (
    <Provider store={store}>
      <ContentModules {...appProps} />
    </Provider>
  );
};
