import { createContext } from 'react';
export const MyProgramContext = createContext({
  userProgress: 0,
  completedResources: 0,
  saveCompletedResource: () => {},
  allCompletedResources: [],
  completedModules: 0,
  setCompletedModules: () => {},
  completedModulesIds: [],
  setCompletedModulesIds: () => {},
  totalModules: 0
});
