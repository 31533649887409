import React, { Fragment } from 'react';
import ResourceHeader from '../ResourceHeader/ResourceHeader';
import ResourceItemVideoSection from './ResourceItemVideoSection/ResourceItemVideoSection';
import ResourceItemDocumentSection from './ResourceItemDocumentSection/ResourceItemDocumentSection';
import ResourceItemImageSection from './ResourceItemImageSection/ResourceItemImageSection';
import ResourceItemBodyTextSection from './ResourceItemBodyTextSection/ResourceItemBodyTextSection';
import { isLoaded, isFailed } from 'rest-states';
import MnSpinner from 'mn-react/components/MnSpinner';
import Toast from 'common/toast';

class ResourceItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    var resourceItem = this.props.resourceItems[this.props.slug];

    if (!resourceItem || isFailed(resourceItem.getRestState)) {
      this.props.loadResourceItem(this.props.slug);
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate() {
    var resourceItem = this.props.resourceItems[this.props.slug];

    if (this.state.loading && (resourceItem && (isLoaded(resourceItem.getRestState) || isFailed(resourceItem.getRestState)))) {
      this.setState({ loading: false });

      if (isFailed(resourceItem.getRestState)) {
        Toast.add('Failed to load resource, please check your internet connection and try again');
      }
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="resources-loading">
          <MnSpinner />
        </div>
      );
    } else {
      var resourceItem = this.props.resourceItems[this.props.slug];

      return (
        <div>
          <ResourceHeader
            navigateBack={this.props.navigateBack}
            windowSize={this.props.windowSize}
            resourceItem={resourceItem.data}
            hideNavigationHeader={this.props.hideNavigationHeader}
            setResourceFavorite={this.props.setResourceFavorite}
            removeResourceFavorite={this.props.removeResourceFavorite}
          />
          { isLoaded(resourceItem && resourceItem.getRestState) && this.renderResourceItem(resourceItem.data) }
        </div>
      );
    }
  }

  renderResourceItem(resourceItem) {
    return (
      <Fragment>
        <ResourceItemVideoSection resourceItem={resourceItem} />
        <ResourceItemDocumentSection resourceItem={resourceItem} />
        <ResourceItemImageSection resourceItem={resourceItem} />
        <ResourceItemBodyTextSection resourceItem={resourceItem} />
      </Fragment>
    );
  }
}

export default ResourceItem;
