export default class ReplyPolicy {
  constructor(reply, store) {
    this.reply = reply;
    this.store = store;
  }

  hasOptions = () => this.canReport() || this.canEdit() || this.canDelete();

  canReport = () => this.author().id !== this.currentUser().id;

  canBlockUser = () => {
    return this.author().id !== this.currentUser().id && this.currentUser().isPatient() && !(this.currentUser().isNurse() || this.currentUser().isCommunityManager());
  }

  canEdit = () =>
    this._editAllowed() && (this.author().id === this.currentUser().id || this.currentUserCanEditAnyPost());

  canDelete = () =>
    this._deleteAllowed() && (this.author().id === this.currentUser().id || this.currentUserCanEditAnyPost());

  currentUser = () => this.store.currentUser;

  author = () => this.reply.author(this.store);

  // its likely we will add more options into here, other user type, an/or pass something through the settings
  currentUserCanEditAnyPost = () => this.currentUser().isCommunityManager();

  _editAllowed = () => this._overallOptions().edit_allowed;

  _deleteAllowed = () => this._overallOptions().delete_allowed;

  _overallOptions = () => {
    if (
      this.store.settings.community &&
      this.store.settings.community.options &&
      this.store.settings.community.options.overall_options
    ) {
      return this.store.settings.community.options.overall_options;
    } else {
      return {};
    }
  };
}
