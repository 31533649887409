import React from 'react';
import PropTypes from 'prop-types';
import NewMeetingBtn from '../RequestNewMeeting';
import Calendar from './Calendar';
import UIText from 'store/UIText';

export default class Meetings extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    meetingActions: PropTypes.object.isRequired,
    items: PropTypes.array
  };

  render() {
    const { store, items } = this.props;

    return (
      <div className="meetings-and-appointments">
        <h2>
          <UIText>Calls</UIText>
        </h2>
        <div className="meeting-buttons">
          <NewMeetingBtn current_user={store.currentUser} />
        </div>
        <div className="row">
          <div className="col-md-12" />
        </div>

        <Calendar items={items} store={store} />
      </div>
    );
  }
}
