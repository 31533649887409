import { ApiEndpoint } from 'backend/ApiEndpointActions';
import apiConfig from '../apiConfigs/chatWith';
import actionTypes from '../constants';

const endpoint = new ApiEndpoint(apiConfig);

export function load(term = '') {
  return function(dispatch, getState) {
    endpoint.forcedLoad(dispatch, getState, {
      queryStr: `filter[scope]=chat_with&term=${term}`
    });
  };
}

export function selectedForUser(forUser) {
  return {
    type: actionTypes.CHAT_WITH_USER_SELECTED,
    forUser: forUser
  };
}
