/* global $ */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PostView from './PostView';
import { orderByCreatedAt } from 'models/Post';

export default class PostList extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    posts: PropTypes.array.isRequired,
    ensureUserHasConfirmed: PropTypes.func.isRequired
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { posts, store, actions } = this.props;
    return (
      <Fragment>
        {store.posts.loading && 'Loading...'}
        <TransitionGroup>
          {posts.sort(orderByCreatedAt).map(p => (
            <FadeTransition key={p.id}>
              <PostView
                actions={actions}
                store={store}
                post={p}
                focused={(this.props.focusedPost && this.props.focusedPost.id === p.id) || false}
                focus={(this.props.focusPost.bind(null, p))}
                ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
              />
            </FadeTransition>
          ))}
        </TransitionGroup>
        {store.posts.isFetching && 'Loading more posts...'}
      </Fragment>
    );
  }

  handleScroll = () => {
    const { posts } = this.props.store;
    if (posts.loaded && posts.mayHaveMore && this.nearBottomOfPage() && !posts.isFetching) {
      this.props.loadNextPage();
    }
  };

  nearBottomOfPage = () => $(window).scrollTop() > $(document).height() - $(window).height() - 300;
}

const FadeTransition = props => <CSSTransition {...props} classNames="animate" timeout={{ enter: 500, exit: 300 }} />;
