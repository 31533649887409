import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UIText from 'store/UIText';

export default class EmptyMessage extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object
  };

  render() {
    const { currentUser } = this.props;
    if (!currentUser) {
      return null;
    }
    return (
      <div>
        <div className="messages-list-container empty">
          <div className="messages-container">
            <h2 className="center">Hi {currentUser.first_name}, welcome to messages</h2>
            <p className="center intro">
              <UIText>Welcome message</UIText>
            </p>
            <p className="center">
              <Link to={'/conversations/new'} className="mn-button is-primary">
                Send a Message
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
