import React from 'react';

const MnStarLargeNoFillIcon = props => {
  const iconColour = props.colour || '#A3AFB8';
  const opacity = props.opacity || '1';

  return (
    <svg width="29" height="27" fill="none" opacity={opacity} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.833 9.62l.115.339h9.728a.5.5 0 0 1 .286.91l-7.544 5.262-.308.215.12.355 2.904 8.579a.5.5 0 0 1-.76.57l-7.66-5.342-.285-.2-.287.2-7.659 5.342a.5.5 0 0 1-.76-.57L8.628 16.7l.12-.355-.308-.215-7.543-5.262a.5.5 0 0 1 .286-.91h9.727l.115-.34L13.955.96a.5.5 0 0 1 .947 0l2.931 8.66z"
        stroke={iconColour}
      />
    </svg>
  );
};

export default MnStarLargeNoFillIcon;
