import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'common/Mixpanel';

export default class ReactList extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired
  };

  render() {
    // const likeTypes = ['love', 'surprise', 'sad', 'wink', 'smile', 'hug']  #seem to have dropped the hug
    const likeTypes = ['love', 'surprise', 'sad', 'wink', 'smile'];
    return (
      <div className="community-posts__emoticon-list-outter">
        <div className="community-posts__emoticon-list">
          <div className="arrow-tip" />
          <ul className="emoticon-buttons">
            {likeTypes.map(l => (
              <li className={`emoticons ${l}`} key={l}>
                <a data-type="html" className={`love-${l}`} onClick={(e) => { this[l](e); Mixpanel.track(`${l} Reaction Button`); }}>
                  {l}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  love = e => this.like(e, 'love');
  surprise = e => this.like(e, 'surprise');
  sad = e => this.like(e, 'sad');
  wink = e => this.like(e, 'wink');
  smile = e => this.like(e, 'smile');
  // hug = (e) =>
  //   this.like(e, 'hug')

  like = (e, kind) => {
    e.preventDefault();
    const { item } = this.props;
    const like = {
      kind: kind,
      likeable_id: item.id,
      likeable_type: item.modalType()
    };

    this.props.actions.likeActions.save(like);
    this.props.close(e);
  };
}
