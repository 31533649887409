import React from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import Combobox from 'react-widgets/lib/Combobox';
import ChatPolicy from 'models/policies/ChatPolicy';

export default class Filter extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    filteredUsers: PropTypes.object.isRequired,
    filterConversations: PropTypes.func.isRequired,
    chatWithActions: PropTypes.object.isRequired,
    conversationId: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      value: null,
      open: false,
      filteredUsers: props.filteredUsers.items
    };
  }

  render() {
    const { store } = this.props;

    const chatPolicy = new ChatPolicy(store);
    if (!chatPolicy.showFilter()) {
      return null;
    }

    return (
      <div className="conversations-filter">
        <Combobox
          filter={'contains'}
          data={this.currentData()}
          valueField="id"
          textField="name"
          placeholder="Search"
          open={this.state.open}
          value={this.state.value}
          onChange={this.search}
          onToggle={this.toggling}
        />
      </div>
    );
  }

  currentData = () => this.excludeCurrentUser(this.state.filteredUsers, this.props.store.currentUser.id);

  excludeCurrentUser = (items, currentUserId) => items.filter(user => user.id !== currentUserId);

  search = value => {
    this.setState({ value });

    if (value.id) {
      // we have a User, retrieve that users conversations
      this.props.filterConversations({
        forUser: value
      });
      this.props.chatWithActions.selectedForUser(value);
    } else if (value > ' ') {
      this.filterUsers(value);
    } else {
      this.props.chatWithActions.selectedForUser(null);
      this.props.filterConversations({
        conversationId: this.props.conversationId
      });
    }
  };

  filterUsers = value => {
    const url = `/api/v1/users?filter[scope]=chat_with&term=${value}`;
    this.pendingFilterValue = value;
    fetch(url, { credentials: 'same-origin' })
      .then(response => {
        if (!response.ok && response.status === 401) {
          window.location.reload();
        }

        return response;
      })
      .then(response => response.json())
      .then(data => {
        // implement a poor man's promise cancel, only use the results from the current query
        if (this.pendingFilterValue === value) {
          this.setState({ filteredUsers: data.users });
        }
      });
  };

  toggling = opening => this.setState({ open: opening });
}
