import { ApiEndpoint } from 'backend/ApiEndpointActions';
import apiConfig from '../apiConfigs/posts';
import { apiMethods } from '../../backend/apiConstants';

import actionTypes from '../constants';

const endpoint = new ApiEndpoint(apiConfig);

export function startNewPost() {
  return {
    type: actionTypes.START_NEW_POST
  };
}

export function cancelNewPost() {
  return {
    type: actionTypes.CANCEL_NEW_POST
  };
}

export function startSavingPost() {
  return {
    type: actionTypes.START_SAVING_POST
  };
}

export function finishedEditing() {
  return {
    type: actionTypes.FINISHED_EDITING
  };
}

export function editPost(post) {
  return {
    type: actionTypes.EDIT_POST,
    post: post
  };
}

export function startReplyToPost(post) {
  return {
    type: actionTypes.START_REPLY_TO_POST,
    post: post
  };
}

export function loadMoreForCommunityId(communityId, page = 1) {
  return function(dispatch, getState) {
    endpoint.forcedLoad(dispatch, getState, {
      queryStr: `community_id=${communityId}&page=${page}`
    });
  };
}

export function loadItems(items) {
  return function(dispatch, getState) {
    endpoint.loadCollectionFromProps(dispatch, getState, items);
  };
}

export function loadPost(post) {
  return function(dispatch, getState) {
    endpoint.loadItemFromProps(dispatch, getState, post);
  };
}

export function initialLoadForCommunityId(communityId, page = 1) {
  return function(dispatch, getState) {
    endpoint.filteredLoad(dispatch, getState, {
      queryStr: `community_id=${communityId}&page=${page}`
    });
  };
}

export function save(data) {
  return function(dispatch, getState) {
    endpoint.save(dispatch, getState, data);
  };
}

export function destroy(data) {
  return function(dispatch, getState) {
    endpoint.destroy(dispatch, getState, data);
  };
}

export function report(data) {
  return function(dispatch, getState) {
    const params = {
      resourceOverrides: {
        resourceUrl: '/api/v1/reported_content',
        method: apiMethods.POST,
        param: null
      }
    };
    endpoint.save(dispatch, getState, { post_id: data.id }, params);
  };
}
