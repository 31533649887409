import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class MeetingTime extends React.Component {
  static propTypes = {
    meeting_at: PropTypes.string.isRequired,
    confirmed: PropTypes.string
  };

  render() {
    return <div className="meeting-time">{this.formatedTime()}</div>;
  }

  formatedTime() {
    if (this.isToday()) {
      return (
        <span>
          <h3 className="meeting-week-day">Today at</h3>
          <h2 className="meeting-hour">{moment(this.props.meeting_at).format('h:mma')}</h2>
          <h4 className="meeting-day-month">{this.props.confirmed}</h4>
        </span>
      );
    } else {
      return (
        <span>
          <h3 className="meeting-week-day">{moment(this.props.meeting_at).format('ddd')}</h3>
          <h2 className="meeting-hour">{moment(this.props.meeting_at).format('h:mma')}</h2>
          <h4 className="meeting-day-month">
            {moment(this.props.meeting_at).format('Do MMM')} {this.props.confirmed}
          </h4>
        </span>
      );
    }
  }

  isToday(m) {
    return moment().format('YYYY-MM-DD') === moment(this.props.meeting_at).format('YYYY-MM-DD');
  }
}
