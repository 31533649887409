import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ReminderActionCreators from 'store/reminders/RemindersActions';
import * as ReminderConfigActionCreators from 'store/reminders/RemindersConfigActions';
import ReminderList from '../components/ReminderList';

function select(store) {
  return { store };
}

class RemindersWidget extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired
  };

  componentDidMount() {
    const { dispatch, userId } = this.props;
    const reminderActions = bindActionCreators(ReminderActionCreators, dispatch);
    const reminderConfigActions = bindActionCreators(ReminderConfigActionCreators, dispatch);

    reminderActions.load(userId);
    reminderConfigActions.load();
  }

  render() {
    const { dispatch, store, userId } = this.props;
    const reminderActions = bindActionCreators(ReminderActionCreators, dispatch);
    return (
      <ReminderList
        reminders={store.reminders}
        config={store.remindersConfig.reminder_config}
        actions={{
          save: reminderActions.save,
          delete: reminderActions.destroy
        }}
        userId={userId}
        store={store}
      />
    );
  }
}

export default connect(select)(RemindersWidget);
