import React from 'react';
import PropTypes from 'prop-types';

export default class UserRoleBadge extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;

    const userBadgeClass = user.role_name.toLowerCase().replace(' ', '-');

    return <span className={`user-role-badge ${userBadgeClass} mn-subtext`}>{user.i18n_role_name}</span>;
  }
}
