import capitalize from 'lodash/capitalize';
import lowerCase from 'lodash/lowerCase';
import Toast from 'common/toast';

/**
 * Determine if an input field has failed required validation
 *
 * @method isInputEmpty
 *
 * @param {String} value
 *
 * @return {Boolean} True if input field required validation has failed, false otherwise
 */
export function isInputEmpty(value) {
  return !value || value.trim() === '';
}

/**
 * Determine if an email is valid
 *
 * @method isValidEmail
 *
 * @param {String} email
 *
 * @return {Boolean} True if the email is valid, false otherwise
 */
export function isValidEmail(email) {
  return /^\S+@\S+\.\S+$/i.test(email);
}

/**
 * Parse the default server error response object and show a toast notification to the user
 *
 * @method parseAndShowServerErrors
 *
 * @param {Object} errors
 *
 * @return {Toast} Displays the error in a toast notification to the user
 */
export function parseAndShowServerErrors(errors) {
  // We only display the first error in the response. If there are more, these will be shown in subsequent posts
  const errorKey = Object.getOwnPropertyNames(errors)[0];
  const firstError = errors[Object.keys(errors)[0]][0];

  if (errorKey === 'base') {
    const errorMessage = capitalize(firstError);
    Toast.add(errorMessage);

    return errorMessage;
  } else {
    const errorMessage = `${capitalize(errorKey)} ${firstError}`;
    Toast.add(errorMessage);

    return errorMessage;
  }
}

/**
 * Determine the gender label for a given gender API option
 *
 * @method determineGenderLabel
 *
 * @param {String} genderOption
 *
 * @return {String} The formatted gender label for a given API gender option
 */
export function determineGenderLabel(genderOption) {
  // Not all _ separated options will require a "-" so we default to space and capitalize and add exceptions as required
  if (genderOption === 'non_binary') {
    return 'Non-binary';
  }

  return capitalize(lowerCase(genderOption));
}

/**
 * Map used for common form error messages
 */
export const commonErrorMessages = {
  firstNameRequired: 'Please enter a first name',
  lastNameRequired: 'Please enter a last name',
  daySelectRequired: 'Please select a day',
  monthSelectRequired: 'Please select a month',
  yearSelectRequired: 'Please select a year',
  genderSelectRequired: 'Please select a gender',
  ethnicitySelectRequired: 'Please select an ethnicity',
  emailRequired: 'Please enter an email address',
  emailInvalid: 'Doesn’t look like a valid email address',
  phoneNumberRequired: 'Please enter a phone number',
  phoneNumberInvalid: 'Please enter a valid phone number',
  isNaN: 'Should be a number'
};
