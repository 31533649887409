import React from 'react';
import PropTypes from 'prop-types';
import MeetingFormContainer from '../containers/MeetingFormContainer';

export default class RequestNewMeeting extends React.Component {
  static propTypes = {
    current_user: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      showAdd: false,
      with_id: undefined,
      user: props.current_user
    };

    window.requestNewMeetingWith = this.requestNewMeetingWith;
  }

  render() {
    if (this.state.showAdd) {
      return (
        <MeetingFormContainer
          meeting={null}
          current_user={this.state.user}
          showModal={this.state.showAdd}
          onClose={this.closeEditMeeting}
          with_id={this.state.with_id}
        />
      );
    }

    return '';
  }

  requestNewMeetingWith = withId => {
    this.setState({
      with_id: withId,
      showAdd: true
    });
  };

  newMeeting = e => {
    e.preventDefault();
    this.setState({
      showAdd: true
    });
  };

  closeEditMeeting = meeting => {
    this.setState({
      showAdd: false
    });
  };
}
