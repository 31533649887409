export default class BaseModel {
  constructor(item, store, json, properties) {
    this.item = item || {};
    this.store = store;
    this.json = json;
    this.properties = properties;

    for (var prop in this.properties) {
      // explictly check for prop, rather than || as booleans are false
      if (Object.prototype.hasOwnProperty.call(this.item, prop)) {
        this[prop] = this.item[prop];
      } else {
        this[prop] = this.properties[prop];
      }
    }
  }

  asParams = () =>
    Object.keys(this.properties).reduce((result, prop) => {
      result[prop] = this[prop];
      return result;
    }, {});
}
