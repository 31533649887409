import React from 'react';
import { connect } from 'react-redux';

const UIText = ({ text }) => {
  // return <span>{text}</span>;
  // UIText is never user provided, so we can use this, and it allows for embedded html like <br> etc
  if (text.indexOf('__##!placeholder!##__') > 0) {
    // placeholder which hasn't been overriden by the app, therefore clear it out
    return null;
  } else {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    text: state.uiText[ownProps.children] || ownProps.children
  };
};

export default connect(mapStateToProps)(UIText);
