export const restStates = {
  isFetching: 'isFetching',
  isSaving: 'isSaving',
  loaded: 'loaded',
  failed: 'failed',
  saved: 'saved'
};

export const isFetching = state => {
  return Boolean(state === restStates.isFetching);
};

export const isSaving = state => {
  return Boolean(state === restStates.isSaving);
};

export const isLoaded = state => {
  return Boolean(state === restStates.loaded);
};

export const isFailed = state => {
  return Boolean(state === restStates.failed);
};

export const isSaved = state => {
  return Boolean(state === restStates.saved);
};
