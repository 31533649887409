import React from 'react';

export default class MnSpinner extends React.Component {
  constructor(props) {
    super(props);

    this._size = this.props.size;
    this._colour = this.props.colour;
  }

  render() {
    return (
      <div className={this._getContainerClasses()}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <path
            className={this._getSpinnerColour()}
            d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.7s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    );
  }

  /**
   * Get the spinner container colour classes
   *
   * @private
   *
   * @method _getContainerClasses
   *
   * @return {String} The spinner container classes
   */
  _getContainerClasses() {
    let classes = '';

    if (this._size === 'grow') {
      return;
    } else if (this._size === 'small') {
      classes = classes + ' mn-spinner mn-spinner--small';
    } else {
      classes = classes + ' mn-spinner';
    }

    return classes;
  }

  /**
   * Get the spinner colour classes
   *
   * @private
   *
   * @method _getSpinnerColour
   *
   * @return {String} The spinner classes
   */
  _getSpinnerColour() {
    let classes = '';

    if (this._colour === 'secondary') {
      classes = classes + 'mn-spinner-colour-secondary';
    } else if (this._colour === 'invert') {
      classes = classes + 'mn-spinner-colour-invert';
    } else {
      classes = classes + 'mn-spinner-colour-primary';
    }

    return classes;
  }
}
