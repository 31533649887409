import React from 'react';
import PropTypes from 'prop-types';
import MessagesList from './MessagesList';
import NewMessage from './NewMessageForm';
import WhosTyping from './WhosTyping';
import Heading from './heading/heading';
import ConversationNotice from '../utils/ConversationNotice';

export default class MessagesIndex extends React.Component {
  static propTypes = {
    conversation: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    sendNewMessage: PropTypes.func.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    chatSettings: PropTypes.object.isRequired,
    notices: PropTypes.object.isRequired
  };

  render() {
    const { conversation, currentUser, sendNewMessage, deleteMessage, chatSettings } = this.props;

    return (
      <div className="messages-container">
        <Heading conversation={conversation} />
        <div className="messages-container__messages-list">
          { this._renderCoachDelayMessage() }
          <MessagesList
            conversation={conversation}
            currentUser={currentUser}
            deleteMessage={deleteMessage}
            chatSettings={chatSettings}
          />
          <WhosTyping conversation={conversation} currentUser={currentUser} />
          <NewMessage
            conversationId={conversation.id}
            sendNewMessage={sendNewMessage}
            currentUser={currentUser}
            shouldFocusOnMessage={true}
          />
        </div>
      </div>
    );
  }

  _renderCoachDelayMessage = () => {
    if (this.props.currentUser.shouldSeeCoachDelayMessage()) {
      return <ConversationNotice message={ this.props.notices.coachDelayMessage }></ConversationNotice>;
    }
  }
}
