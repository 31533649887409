import React from 'react';
import ResourceSectionCard from './ResourceSectionCard/ResourceSectionCard';
import ResourceFavoriteSection from './ResourceFavoriteSection/ResourceFavoriteSection';
import { isLoaded, isFailed } from 'rest-states';
import MnSpinner from 'mn-react/components/MnSpinner';
import Toast from 'common/toast';

class ResourceSections extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    var { resourceSections } = this.props;

    if (!resourceSections.getRestState || isFailed(resourceSections.getRestState)) {
      this.props.loadResourceSections();
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate() {
    var { resourceSections } = this.props;

    if (this.state.loading && (isLoaded(resourceSections.getRestState) || isFailed(resourceSections.getRestState))) {
      this.setState({ loading: false });

      if (isFailed(resourceSections.getRestState)) {
        Toast.add('Failed to load resources, please check your internet connection and try again');
      }
    }
  }

  render() {
    var { resourceSections } = this.props;

    if (this.state.loading) {
      return (
        <div className="resources-loading">
          <MnSpinner />
        </div>
      );
    } else {
      return (
        <div>
          <ResourceFavoriteSection
            favoritesSection={resourceSections.data && resourceSections.data.favoritesSection}
            selectResourceSection={this.props.selectResourceSection}
          />
          <div className="resource-sections-cards-container">
            { isLoaded(resourceSections.getRestState) && this._resourceSectionsList() }
          </div>
        </div>
      );
    }
  }

  /**
   * Get the resource sections list
   *
   * @private
   *
   * @method _resourceSectionsList
   *
   * @return {Array/Elements} An array of resource sections
   */
  _resourceSectionsList() {
    return this.props.resourceSections.data.resourceSections.filter(section => section.resourceCount > 0).map(resourceSection => {
      return (
        <div key={resourceSection.id}>
          <ResourceSectionCard
            resourceSection={resourceSection}
            selectResourceSection={this.props.selectResourceSection}
          />
        </div>
      );
    });
  }
}

export default ResourceSections;
