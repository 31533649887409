import React from 'react';
import { Provider } from 'react-redux';

import MedicationWidget from '../containers/MedicationWidget';

export default props => {
  const store = window.store;
  const reactComponent = (
    <Provider store={store}>
      <MedicationWidget {...props} />
    </Provider>
  );
  return reactComponent;
};
