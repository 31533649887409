import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Mixpanel from 'common/Mixpanel';
import { MyProgramContext } from '../context/MyProgramContext';
import { LockIcon, CheckIcon, UnCheckedIcon, BookIcon } from '../../../util/FaIcons';

export default function ContentModulesListItem({ module, moduleNum, isCurrentModule, isLast }) {
  const context = useContext(MyProgramContext);
  const mod = context.allCompletedResources.find((m) => m.module_id === module.id);
  const completedResources = mod ? mod.completed_resources : 0;
  const overallProgress = mod.total_resources > 0 ? Math.floor((completedResources / mod.total_resources) * 100) : 0;
  const [moduleProgress, setModuleProgress] = useState(Math.floor(overallProgress));
  const [moduleIcon, setModuleIcon] = useState(
    module.locked ? 'locked' : mod.is_completed ? 'completed' : 'uncompleted'
  );

  useEffect(() => {
    if (isCurrentModule) {
      setModuleProgress(context.userProgress);
      if (context.userProgress === 100) setModuleIcon('completed');
    } else {
      setModuleProgress(overallProgress);
    }
  }, [context.userProgress]);

  const ModuleNum = () => {
    const num = moduleNum === 0 ? 1 : moduleNum + 1;
    return isLast ? '' : `Module ${num}`;
  };

  const StatusIcon = () => {
    switch (moduleIcon) {
      case 'locked':
        return <LockIcon />;
      case 'completed':
        return <CheckIcon />;
      case 'uncompleted':
        return <UnCheckedIcon />;
      default:
        return null;
    }
  };

  const ModuleListItem = () => {
    return (
      <div className="module-list-item">
        <div className="progress-bar-container list-item">
          {
            isLast ? <BookIcon /> : <CircularProgressbarWithChildren value={moduleProgress}>
              <StatusIcon />
            </CircularProgressbarWithChildren>
          }

        </div>
        <div className="module-title">
          <div className="subtitle">
            <ModuleNum />
          </div>
          <div className="title">{module.title}</div>
        </div>
      </div>
    );
  };

  const UnlockedModuleListItem = () => {
    var subtitle = moduleProgress > 0 ? `Progress-${moduleProgress}` : 'Start';
    return (
      <a onClick={() => Mixpanel.track(`${module.title} - ${subtitle}`)} href={module.id}>
        <ModuleListItem />
      </a>
    );
  };

  return (
    <li className={isCurrentModule ? 'current' : module.locked ? 'locked' : 'unlocked'}>
      {module.locked ? <ModuleListItem /> : <UnlockedModuleListItem />}
    </li>
  );
}

ContentModulesListItem.propTypes = {
  module: PropTypes.object,
  moduleNum: PropTypes.number,
  isCurrentModule: PropTypes.bool
};
