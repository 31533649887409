import actionTypes from '../constants';

import Reminder from 'models/Reminder';

export default {
  requestCollection: actionTypes.REQUEST_REMINDERS,
  receiveCollection: actionTypes.RECEIVE_REMINDERS,
  receiveItem: actionTypes.RECEIVE_REMINDER,
  removeItem: actionTypes.REMOVE_REMINDER,

  storeKey: 'reminders',
  Model: Reminder,

  collectionKey: 'reminders',
  itemKey: 'reminder',

  apiEndPoint: '/api/v1/users/:user_id/reminders',
  bodyParam: 'reminder'
};
