import React from 'react';
import PropTypes from 'prop-types';
import UserAvatarInline from 'mn-react/components/UserAvatarInline';
import { CalendarIcon } from 'util/FaIcons';
import MeetingFormContainer from '../../containers/MeetingFormContainer';
import NurseMeetingTime from '../NurseMeetingTime';
import MeetingNotes from '../MeetingNotes';

export default class NurseMeetingSummary extends React.Component {
  static propTypes = {
    row: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showDetail: false
    };
  }

  render() {
    const meeting = this.props.row;

    return (
      <tr className="widget-meeting">
        <td className="meeting-nurse-name">{meeting.nurseUser().name}</td>
        <td className="meeting-patient-name">
          <UserAvatarInline user={meeting.patientUser()} />
          {meeting.patientUser().name}
        </td>
        <td>
          <CalendarIcon />
          <NurseMeetingTime meeting_at={meeting.meeting_at} />
        </td>
        <td>{meeting.confirmedStatus()}</td>
        <td>{this.actionBtns()}</td>
        <td>
          <MeetingNotes meeting={meeting} />
        </td>
      </tr>
    );
  }

  actionBtns() {
    const meeting = this.props.row;
    var meetingForm = '';

    if (this.state.showDetail) {
      meetingForm = (
        <MeetingFormContainer meeting={meeting} showModal={this.state.showDetail} onClose={this.closeEditMeeting} />
      );
    }

    if (meeting.currentUserIsAMember()) {
      return (
        <div>
          <button className="is-primary is-small meeting-btn" onClick={this.showMeetingDetails}>
            {meeting.userActionMessage()}
          </button>
          {meetingForm}
        </div>
      );
    }

    return (
      <button className="is-small" href={`/find_conversation/${meeting.patientUser().id}`}>
        Message the patient
      </button>
    );
  }

  showMeetingDetails = () =>
    this.setState({
      showDetail: true
    });

  closeEditMeeting = meeting =>
    this.setState({
      showDetail: false
    });
}
