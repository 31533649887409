import React from 'react';

export function AngleUpIcon(props) {
  return <i className="far fa-angle-up" {...props} />;
}

export function BellIcon(props) {
  return <i className="far fa-bell" {...props} />;
}

export function BellSlashIcon(props) {
  return <i className="far fa-bell-slash" {...props} />;
}

export function BellSlashOIcon(props) {
  return <i className="far fa-bell-slash" {...props} />;
}

export function BookIcon(props) {
  return <i className="far fa-book" {...props} />;
}

export function CalendarIcon(props) {
  return <i className="far fa-calendar-alt" {...props} />;
}

export function ChevronDownIcon(props) {
  return <i className="far fa-chevron-down" {...props} />;
}

export function CheckIcon(props) {
  return (
    <div className="fa-check-filled">
      <i className="fa fa-check fa-lg" {...props} />
    </div>
  );
}

export function ChevronUpIcon(props) {
  return <i className="far fa-chevron-up" {...props} />;
}

export function ChevronLeftIcon(props) {
  return <i className="far fa-chevron-left" {...props} />;
}

export function ChevronRightIcon(props) {
  return <i className="far fa-chevron-right" {...props} />;
}

export function ChevronCircleDownIcon(props) {
  return <i className="far fa-chevron-circle-down" {...props} />;
}

export function ChevronCircleUpIcon(props) {
  return <i className="far fa-chevron-circle-up" {...props} />;
}

export function ChevronCircleLeftIcon(props) {
  return <i className="far fa-chevron-circle-left" {...props} />;
}

export function ChevronCircleRightIcon(props) {
  return <i className="far fa-chevron-circle-right" {...props} />;
}

export function CameraIcon(props) {
  return <i className="far fa-camera" {...props} />;
}

export function ClockIcon(props) {
  return <i className="far fa-clock" {...props} />;
}

export function DownPointingTriangle(props) {
  return <span>&#9662;</span>;
}

export function EditIcon(props) {
  return <i className="far fa-edit" {...props} />;
}

export function ExclamationIcon(props) {
  return <i className="far fa-exclamation" {...props} />;
}

export function ExclamationCircleIcon(props) {
  return <i className="far fa-exclamation-circle" {...props} />;
}

export function HomeCameraIcon(props) {
  return (
    <span className="fa-stack fa-1x">
      <span style={{ color: '#354fa0' }}>
        <i className="fa fa-circle fa-stack-2x fa-sm" />
      </span>
      <span style={{ color: '#fff' }}>
        <i className="far fa-camera fa-stack-1x" />
      </span>
    </span>
  );
}

export function ListViewIcon(props) {
  return <i className="far fa-align-justify" {...props} />;
}

export function LockIcon(props) {
  return <i className="fa fa-lock fa-sm" {...props} />;
}

export function MapMarkerIcon(props) {
  return <i className="far fa-map-marker-alt" {...props} />;
}

export function PencilIcon(props) {
  return <i className="far fa-pen" {...props} />;
}

export function ReplyIcon(props) {
  return <i className="far fa-reply" {...props} />;
}

export function SortIconAsc(props) {
  return <i className="far fa-sort-up" {...props} />;
}

export function SortIconBoth(props) {
  return <i className="far fa-sort" {...props} />;
}

export function SortIconDesc(props) {
  return <i className="far fa-sort-down" {...props} />;
}

export function TrashIcon(props) {
  return <i className="far fa-trash-alt" {...props} />;
}

export function UnCheckedIcon(props) {
  return (
    <div className="fa-check-unfilled">
      <i className="fa fa-check fa-lg" {...props} />
    </div>
  );
}

export function UserIcon(props) {
  return <i className="far fa-user" {...props} />;
}

export function UsersIcon(props) {
  return <i className="far fa-users" {...props} />;
}
