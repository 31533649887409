import { ApiEndpoint } from 'backend/ApiEndpointActions';
import apiConfig from '../apiConfigs/medication';

const endpoint = new ApiEndpoint(apiConfig);

export function load(userId) {
  return function(dispatch, getState) {
    endpoint.load(dispatch, getState, { baseUrlVars: { user_id: userId } });
  };
}

export function save(data) {
  return function(dispatch, getState) {
    endpoint.save(dispatch, getState, data, {
      baseUrlVars: { user_id: data.user_id }
    });
  };
}

export function destroy(data) {
  return function(dispatch, getState) {
    endpoint.destroy(dispatch, getState, data, {
      baseUrlVars: { user_id: data.user_id }
    });
  };
}
