'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { SortIconBoth, SortIconDesc, SortIconAsc } from 'util/FaIcons';

export default class SortableTableHeader extends React.Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    sortings: PropTypes.array.isRequired,
    onStateChange: PropTypes.func,
    iconStyle: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
  }

  onClick(index) {
    this.props.onStateChange(index);
  }

  render() {
    var headers = this.props.columns.map(
      function(column, index) {
        var sorting = this.props.sortings[index];
        return (
          <SortableTableHeaderItem
            sortable={column.sortable}
            key={index}
            index={index}
            header={column.header}
            onClick={this.onClick}
            sorting={sorting}
            style={column.headerStyle}
          />
        );
      }.bind(this)
    );
    // iconStyle={this.props.iconStyle} />

    return (
      <thead>
        <tr>{headers}</tr>
      </thead>
    );
  }
}

class SortableTableHeaderItem extends React.Component {
  static defaultProps = {
    sortable: true
  };

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if (this.props.sortable) {
      this.props.onClick(this.props.index);
    }
  }

  render() {
    var sortIcon;
    if (this.props.sortable) {
      sortIcon = <SortIconBoth style={this.props.iconStyle} />;
      if (this.props.sorting === 'desc') {
        sortIcon = <SortIconDesc style={this.props.iconStyle} />;
      } else if (this.props.sorting === 'asc') {
        sortIcon = <SortIconAsc style={this.props.iconStyle} />;
      }
    }

    return (
      <th style={this.props.style} onClick={this.onClick}>
        {this.props.header}
        {sortIcon}
      </th>
    );
  }
}
