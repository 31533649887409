import { ApiEndpoint } from 'backend/ApiEndpointActions';
import apiConfig from './RemindersConfigApiConfig';

const endpoint = new ApiEndpoint(apiConfig);

export function load(user) {
  return function(dispatch, getState) {
    endpoint.load(dispatch, getState);
  };
}
