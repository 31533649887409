import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'common/Mixpanel';
import swal from 'mn-react/common/sweetalert-with-react';

import { TrashIcon, PencilIcon as EditIcon } from 'util/FaIcons';

import PostPolicy from 'models/policies/PostPolicy';
import ReplyPolicy from 'models/policies/ReplyPolicy';

export default class PostOptions extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    startEditing: PropTypes.func,
    post: PropTypes.object,
    reply: PropTypes.object,
    store: PropTypes.object.isRequired
  };

  render() {
    const { post, reply, store } = this.props;
    let policy;
    if (post) {
      policy = new PostPolicy(post, store);
    } else if (reply) {
      policy = new ReplyPolicy(reply, store);
    } else {
      return null;
    }

    if (policy.hasOptions()) {
      return (
        <div className="post-options" onClick={() => { Mixpanel.track(`${this.capitalizedOptionType()} Options Button`); }}>
          <i className="far fa-ellipsis-h" onClick={this.props.toggle} />
          {this.props.open && this.showOptionsList(policy)}
        </div>
      );
    } else {
      return null;
    }
  }

  showOptionsList = policy => {
    const canEdit = policy.canEdit() && this.props.startEditing;

    return (
      <div className="community-post__options-list">
        <ul>
          {canEdit && (
            <li className="community-post__options-edit">
              <a onClick={() => { this.editOption(); Mixpanel.track(`Edit ${this.capitalizedOptionType()} Button`); }}>
                <EditIcon /> Edit
              </a>
            </li>
          )}
          {policy.canDelete() && (
            <li className="community-post__options-delete">
              <a onClick={() => { this.renderDeleteConfirmation(); Mixpanel.track(`Delete ${this.capitalizedOptionType()} Button`); } }>
                <TrashIcon /> Delete
              </a>
            </li>
          )}
          {policy.canReport() && (
            <li className="community-post__options-edit" data-cy="report-post">
              <a onClick={() => { this.renderReportConfirmation(); Mixpanel.track(`Report ${this.capitalizedOptionType()} Button`); } } >Report this</a>
            </li>
          )}
          {policy.canBlockUser() && (
            <li className="community-post__options-delete" data-cy="block-user">
              <a onClick={() => { this.renderBlockConfirmation(); Mixpanel.track('Block User Button'); }}>Block user</a>
            </li>
          )}
        </ul>
      </div>
    );
  };

  renderDeleteConfirmation = () => {
    swal({
      text: null,
      button: null,
      content: (
        <div>
          <h1>Delete this {this.optionType()}?</h1>
          <p>You will not be able to recover the {this.optionType()} after deleting</p>
          <button className="mn-button is-link" onClick={() => { this.closeAlert(); Mixpanel.track(`Cancel ${this.capitalizedOptionType()} Delete Button`); }}>
            Cancel
          </button>
          <button className="mn-button is-destructive" onClick={() => { this.reallyDelete(); Mixpanel.track(`Confirm ${this.capitalizedOptionType()} Delete Button`); }}>
            Delete
          </button>
        </div>
      )
    });
  }

  renderReportConfirmation = () => {
    swal({
      text: null,
      button: null,
      content: (
        <div>
          <h1>Report this user?</h1>
          <p>We will send an email to one of the community managers to look into this</p>
          <button className="mn-button is-link" onClick={() => { this.closeAlert(); Mixpanel.track(`Cancel ${this.capitalizedOptionType()} Report Button`); }}>
            Cancel
          </button>
          <button className="mn-button is-destructive" onClick={() => { this.reportPost(); Mixpanel.track(`Confirm ${this.capitalizedOptionType()} Report Button`); }}>
            Report user
          </button>
        </div>
      )
    });
  }

  renderBlockConfirmation = () => {
    swal({
      text: null,
      button: null,
      content: (
        <div>
          <h1>Block this user?</h1>
          <p>This user will be permanently blocked from your newsfeed and messages</p>
          <button className="mn-button is-link" onClick={() => { this.closeAlert(); Mixpanel.track('Cancel User Block Button'); }}>
            Cancel
          </button>
          <button className="mn-button is-destructive" onClick={() => { this.blockUser(); Mixpanel.track('Confirm User Block Button'); }}>
            Block user
          </button>
        </div>
      )
    });
  }

  optionType = () => (this.props.post ? 'post' : 'reply');

  capitalizedOptionType = () => (this.props.post ? 'Post' : 'Reply');

  editOption = () => {
    this.props.startEditing();
  };

  closeAlert = () => {
    swal.close();
  }

  reallyDelete = () => {
    this.closeAlert();
    this.props.actions.destroy(this.props.post || this.props.reply);
  };

  reportPost = () => {
    this.closeAlert();
    this.props.reportPost();
  }

  blockUser = () => {
    this.closeAlert();
    this.props.blockUser();
  }
}
