import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class MeetingTime extends React.Component {
  static propTypes = {
    meeting_at: PropTypes.string.isRequired,
    confirmed: PropTypes.string
  };

  render() {
    return <div className="meeting-time">{this.formatedTime()}</div>;
  }

  formatedTime() {
    if (this.isToday()) {
      return (
        <span className="meeting-time-nurse">
          Today at {moment(this.props.meeting_at).format('h:mma')} {this.props.confirmed}
        </span>
      );
    } else {
      return (
        <span className="meeting-time-nurse">{moment(this.props.meeting_at).format('ddd, Do MMM YYYY, h:mm a')}</span>
      );
    }
  }

  isToday(m) {
    return moment().format('YYYY-MM-DD') === moment(this.props.meeting_at).format('YYYY-MM-DD');
  }
}
