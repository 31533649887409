import { loadCurrentUser } from './UserActions';
import { updateResource } from 'backend/ApiResources.js';

export function acceptTerms() {
  const resource = {
    url: '/api/v1/users/accept_terms',
    onSuccess: loadCurrentUser
  };
  return function(dispatch, getState) {
    updateResource({}, resource, dispatch, getState);
  };
}
