import React from 'react';

const MnResoucesEventIcon = (props) => {
  const dimensions = props.dimensions || {};
  const colour = props.colour || '#fff';

  return (
    <svg width={ dimensions.width || '54px' } height={ dimensions.height || '54px' } viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36 27h-6.75A2.257 2.257 0 0 0 27 29.25V36a2.257 2.257 0 0 0 2.25 2.25H36A2.257 2.257 0 0 0 38.25 36v-6.75A2.257 2.257 0 0 0 36 27zm0-22.5v2.25H18V4.5a2.257 2.257 0 0 0-2.25-2.25A2.257 2.257 0 0 0 13.5 4.5v2.25h-2.25c-2.498 0-4.478 2.025-4.478 4.5l-.022 31.5c0 2.475 2.002 4.5 4.5 4.5h31.5c2.475 0 4.5-2.025 4.5-4.5v-31.5c0-2.475-2.025-4.5-4.5-4.5H40.5V4.5a2.257 2.257 0 0 0-2.25-2.25A2.257 2.257 0 0 0 36 4.5zm4.5 38.25h-27a2.257 2.257 0 0 1-2.25-2.25V18h31.5v22.5a2.257 2.257 0 0 1-2.25 2.25z"
        fill={colour}
      />
    </svg>
  );
};

export default MnResoucesEventIcon;
