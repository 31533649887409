import React from 'react';
import PropTypes from 'prop-types';

export default class EnterMeetingBtn extends React.Component {
  static propTypes = {
    meeting: PropTypes.object
  };

  render() {
    const { meeting } = this.props;
    if (meeting && meeting.is_confirmed) {
      var href = `/meetings/${meeting.key}`;
      return (
        <a href={href}>
          <button type="button" className="is-primary checks-opentok-minimum-requirements">
            Enter the call
          </button>
        </a>
      );
    } else {
      return false;
    }
  }
}
