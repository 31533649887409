import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'common/Mixpanel';

export default class UserAvatar extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    size: PropTypes.string
  };

  render() {
    const { user, size } = this.props;
    const userUrl = `/users/${user.id}`;

    var styles = {
      backgroundImage: `url(${user.links.profile_image_small_url})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: '100%'
    };

    return (
      <a href={userUrl} title={user.name} onClick={() => { Mixpanel.track('User Avatar Link'); }}>
        <div className={classNameForSize(size)} style={styles} />
      </a>
    );
  }
}

function classNameForSize(size) {
  switch (size) {
    case 'size-20':
      return 'mn-avatar-20';
    case 'size-30':
      return 'mn-avatar-30';
    case 'size-40':
      return 'mn-avatar-40';
    case 'size-100':
    default:
      return 'mn-avatar-100';
  }
}
