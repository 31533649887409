import React from 'react';
import PropTypes from 'prop-types';
import ConversationUserAvatars from './ConversationUserAvatars';
import TimeAgoNoIcon from 'util/datetime/TimeAgoNoIcon';
import withRouter from '../../../../common/withRouter';

class ConversationSummary extends React.Component {
  static propTypes = {
    conversation: PropTypes.object,
    isSelected: PropTypes.bool
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props, context) {
    super(props, context);
    this.state = { showMessages: false };
  }

  render() {
    const { conversation, isSelected } = this.props;

    var className = 'single-conversation';
    if (isSelected) {
      className += ' current-conversation';
    }
    return (
      <li className={className} onClick={this.selectConversation}>
        <ConversationUserAvatars conversation={conversation} />
        <UnreadBadge count={conversation.num_unread_messages} />
        <div className="conversation-list__text">
          <div className="conversation-list__heading">{conversation.heading}</div>
          <div className="conversation-list__preview">{conversation.latestMessage().body}</div>
          <TimeAgoNoIcon time={conversation.latestMessageTime()} />
        </div>
      </li>
    );
  }

  selectConversation = e => {
    e.preventDefault();
    this.props.navigate(`/conversations/${this.props.conversation.id}`);
  };
}

const UnreadBadge = ({ count }) => (count > 0 ? <span className="badge">{count}</span> : <span />);

export default withRouter(ConversationSummary);
