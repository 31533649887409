import React from 'react';

/**
 * Convert a text string to html, including explicit line breaks

 * @method elementsFromText
 *
 * @param {String} text: an input string, which may contain sensitive chars and has \n's for line breaks
 *
 * @return {String} An array of span elements that together have the given string as its textContent.
 *                  Note that a trailing newline is also added

 */
export const elementsFromText = text =>
  text.split('\n').map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));
