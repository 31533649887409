import React from 'react';
import PropTypes from 'prop-types';

export default class LikeView extends React.Component {
  static propTypes = {
    // postActions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    like: PropTypes.object.isRequired
    // post: PropTypes.object.isRequired,
  };

  render() {
    const { like } = this.props;

    return (
      <div className="community-post__reply_like">
        <span className={`community-post__mini-emoticon community-post__mini-emoticon_${like.kind}`} />
      </div>
    );
  }
}
