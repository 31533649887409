import React from 'react';
import PropTypes from 'prop-types';
import ReactAction from '../Common/ReactAction';

export default class ReplyReact extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    reply: PropTypes.object.isRequired
  };

  render() {
    const { actions, store, reply } = this.props;
    return <ReactAction actions={actions} store={store} item={reply} />;
  }
}
