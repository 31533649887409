import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MyProgramContext } from '../context/MyProgramContext';
import ProgramBadges from '../components/ProgramBadges';

export default function ProgramSummary({ inMobileApp }) {
  const context = useContext(MyProgramContext);
  const overallProgress =
    context.totalModules > 0 ? Math.floor((context.completedModules / context.totalModules) * 100) : 0;
  const [programProgress, setProgramProgress] = useState(overallProgress);

  useEffect(() => {
    if (context.totalModules > 0) setProgramProgress(overallProgress);
  }, [context.completedModules]);

  const CircProgressBar = () => {
    if (context.totalModules > 0) {
      return (
        <div className="progress-bar-container">
          <CircularProgressbar
            value={programProgress}
            text={`${context.completedModules}/${context.totalModules}`}
            strokeWidth={7}
          />
          <p className="circular-progress-bar-text">modules completed</p>
        </div>
      );
    }
    return <div />;
  };

  const ProgramSummaryWeb = () => {
    return (
      <div className="mn-containter">
        <div className="row">
          <div className="col-md-3" />
          <div className="col-md-6">
            <div className="program-summary">
              <h3 className="mn-section-heading">My Program Progress</h3>
              <div className="program-summary-content">
                <CircProgressBar />
                <ProgramBadges inMobileApp={inMobileApp} />
              </div>
            </div>
          </div>
          <div className="col-md-3" />
        </div>
      </div>
    );
  };

  const ProgramSummaryMobile = () => {
    return (
      <>
        <div className="program-summary-mobile">
          <div className="summary-title">My Program Progress</div>
          <div className="program-summary-content-mobile">
            <CircProgressBar />
            <ProgramBadges inMobileApp={inMobileApp} />
          </div>
        </div>
      </>
    );
  };

  return inMobileApp ? <ProgramSummaryMobile /> : <ProgramSummaryWeb />;
}

ProgramSummary.props = {
  total_modules: PropTypes.number
};
