import React from 'react';
import PropTypes from 'prop-types';
import loadImage from 'blueimp-load-image';
import Mixpanel from 'common/Mixpanel';

import { CameraIcon } from 'util/FaIcons';
import Toast from 'common/toast';

export default class ImageUploadButton extends React.Component {
  static propTypes = {
    onSelectImage: PropTypes.func,
    store: PropTypes.object.isRequired,
    ensureUserHasConfirmed: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.imageUploadInput = React.createRef();
  }

  render() {
    return (
      <div className="image-upload-icon-container">
        <input type="file" accept="image/*" className="hidden" data-cy="file-input" onChange={this.onDrop} ref={this.imageUploadInput} />
        <CameraIcon onClick={() => {
          if (this.props.ensureUserHasConfirmed(this.props.store.currentUser)) {
            this.imageUploadInput.current.click();
            Mixpanel.track('Image Upload Button');
          }
        }} />
      </div>
    );
  }

  onDrop = event => {
    const file = event.target.files[0];

    event.target.value = '';

    loadImage(
      file,
      this.attachImage,
      {
        maxWidth: 1000,
        maxHeight: 1000,
        meta: true,
        orientation: true,
        canvas: true
      }
    );
  };

  attachImage = (image) => {
    var imageDataURL;

    try {
      imageDataURL = image.toDataURL();
    } catch (error) {
      Toast.add('An error occured when attaching your file. Please make sure that you selected an image.', { expires: 5000 });
    }

    if (imageDataURL) {
      this.props.onSelectImage(imageDataURL);
    }
  }
}
