import React from 'react';
import PropTypes from 'prop-types';
import ReminderSummary from './ReminderSummary';
import NewBtn from './NewBtn';

import { orderReminders } from 'store/reminders/RemindersReducer';

export default class ReminderList extends React.Component {
  static propTypes = {
    reminders: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired
  };

  render() {
    var htmlClasses = 'reminders';
    return (
      <div id="widget-reminders">
        {this.renderHeader()}
        <hr />
        <div className={htmlClasses}>{this.renderSummary()}</div>
      </div>
    );
  }

  renderSummary() {
    const { reminders, store, config, actions } = this.props;

    if (!reminders.loaded) {
      return 'Loading...';
    } else if (reminders.items.length === 0) {
      return 'No reminders';
    } else {
      const orderedItems = orderReminders(reminders.items);
      return (
        <div>
          {orderedItems.map(reminder => (
            <ReminderSummary reminder={reminder} config={config} store={store} actions={actions} key={reminder.id} />
          ))}
        </div>
      );
    }
  }

  renderHeader() {
    const { config, store, actions } = this.props;
    return (
      <div>
        <h2>Reminders</h2>
        <NewBtn saveAction={actions.save} userId={this.props.userId} config={config} store={store} />
      </div>
    );
  }
}
