export const keyboardInteraction = {
  /**
   * Handle key press
   *
   * @param {Object} target
   *
   * @param {Function} action
   *
   * @return {Function} Will run the given action on key press
   */
  handleKeyPress: (target, action) => {
    const enterKeyCode = 13;

    if (target.charCode === enterKeyCode) {
      return action();
    }
  }
};

export const constants = {
  // eg 9th of Mar 2019
  RESOURCE_DATE_FORMAT: 'Do [of] MMM YYYY',
  RESOURCE_DATE_TIME_FORMAT: 'Do [of] MMM YYYY h:m a'
};

export const resourceTypes = {
  EVENT: 'Event'
};
