import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'common/Mixpanel';
import ReplyView from './ReplyView';

export default class PostReplies extends React.Component {
  replyCollapsePoint = 3;

  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    focused: PropTypes.bool.isRequired,
    ensureUserHasConfirmed: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      hidingReplies: false,
      replies: [],
      focuedReply: null
    };
  }

  componentDidMount() {
    var replies = this.props.post.repliesOrderedChronologically();
    var hidingReplies = replies.length > 3;

    this.setState({
      replies: replies,
      hidingReplies: hidingReplies
    });
  }

  componentDidUpdate(_, prevState) {
    var newReplies = this.props.post.repliesOrderedChronologically();

    var newVisibleReplies = this.state.hidingReplies ? newReplies.slice(0, this.replyCollapsePoint) : newReplies;
    var oldVisibleReplies = this.state.hidingReplies ? this.state.replies.slice(0, this.replyCollapsePoint) : this.state.replies;

    var visibleRepliesChanged = !this.replyArraysMatch(newVisibleReplies, oldVisibleReplies);
    var showAll = newReplies.length <= this.replyCollapsePoint ||
      newReplies.length !== this.state.replies.length ||
      !prevState.hidingReplies ||
      !this.state.hidingReplies;

    // By always showing all replies after a reply is added, we ensure that the replying user will see their new reply
    if (visibleRepliesChanged || newReplies.length !== this.state.replies.length) {
      this.setState({
        replies: newReplies,
        hidingReplies: !showAll
      });
    }

    if (this.state.focuedReply && !this.props.focused) {
      this.setState({ focuedReply: null });
    }
  }

  render() {
    return this.state.hidingReplies ? this.renderLastReplies() : this.renderAllReplies();
  }

  renderAllReplies = () => {
    const { store, actions } = this.props;

    return this.state.replies.map(reply =>
      <ReplyView
        reply={reply}
        store={store}
        key={reply.id}
        actions={actions}
        focused={(this.state.focuedReply && reply.id === this.state.focuedReply.id) || false}
        focus={this.focusReply.bind(null, reply)}
        ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
      />);
  }

  renderLastReplies = () => {
    const { store, actions } = this.props;

    return (
      <Fragment>
        {this.state.replies.slice(-this.replyCollapsePoint).map(reply =>
          <ReplyView
            reply={reply}
            store={store}
            key={reply.id}
            actions={actions}
            focused={(this.state.focuedReply && reply.id === this.state.focuedReply.id) || false}
            focus={this.focusReply.bind(null, reply)}
            ensureUserHasConfirmed={this.props.ensureUserHasConfirmed}
          />)}
        <a className="show-all-replies-link" onClick={(e) => { this.showAllReplies(e); Mixpanel.track('Show All Replies Button'); }}>Show all {this.state.replies.length} replies</a>
      </Fragment>
    );
  }

  showAllReplies = (e) => {
    e.preventDefault();

    this.setState({ hidingReplies: false });
  }

  likeArraysMatch(arrayA, arrayB) {
    return arrayA.length === arrayB.length &&
      arrayA.every((likeA, i) => {
        var likeB = arrayB[i];

        return (
          likeA.id === likeB.id &&
          likeA.kind === likeB.kind &&
          likeA.is_deleted === likeB.is_deleted
        );
      });
  }

  replyArraysMatch(arrayA, arrayB) {
    return arrayA.length === arrayB.length &&
      arrayA.every((replyA, i) => {
        var replyB = arrayB[i];

        return (
          replyA.id === replyB.id &&
          replyA.body === replyB.body &&
          replyA.is_deleted === replyB.is_deleted &&
          this.likeArraysMatch(replyA.likes(), replyB.likes())
        );
      });
  }

  focusReply = reply => {
    this.props.focus();
    this.setState({ focuedReply: reply });
  }
}
