import { database, realTimeEnabled, initializeFirebaseDB } from './realTime';

initializeFirebaseDB();

const path = '/realtime/chat';

const userTypingRef = (conversationId, userId) => database.ref(`${path}/${conversationId}/typing/${userId}`);

export const indicateStartedTyping = (conversationId, userId, name) => {
  if (!realTimeEnabled() || !conversationId || !userId) {
    return null;
  }
  const ref = userTypingRef(conversationId, userId);

  return new Promise((resolve, reject) => {
    const userInConv = { name: name };
    ref
      .set(userInConv)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
    ref.onDisconnect().remove();
  });
};

export const indicateStoppedTyping = (conversationId, userId) => {
  if (!realTimeEnabled() || !conversationId || !userId) {
    return null;
  }
  const ref = userTypingRef(conversationId, userId);

  return new Promise((resolve, reject) => {
    ref.remove();
  });
};

const usersTyping = conversationId => database.ref(`${path}/${conversationId}/typing`);

export const adviseUserStartedTyping = (conversationId, callback) =>
  usersTyping(conversationId).on('child_added', snapshot => {
    const user = { name: snapshot.val().name, id: snapshot.key };
    callback(user);
  });

export const adviseUserStoppedTyping = (conversationId, callback) =>
  usersTyping(conversationId).on('child_removed', snapshot => {
    callback(snapshot.key);
  });
