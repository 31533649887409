import React from 'react';
import PropTypes from 'prop-types';

import Form from './Form';
import swal from 'mn-react/common/sweetalert-with-react';

export default class MedicationItem extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
  };

  state = { showForm: false };

  render() {
    const { item } = this.props;
    return (
      <div className="medication-item">
        <div className="medication-item-display-field">
          <label className="medication-item-label">Medication:</label>
          <div className="medication-item-value">{item.name}</div>
        </div>
        <div className="medication-item-display-field">
          <label className="medication-item-label">Dosage:</label>
          <div className="medication-item-value">{this.formatDosage(item.strength)}</div>
        </div>
        <div className="medication-item-display-field">
          <label className="medication-item-label">Frequency:</label>
          <div className="medication-item-value">{this.formatFreq(item)}</div>
        </div>
        <div className="medication-item-display-field">
          <label className="medication-item-label">Reason:</label>
          <div className="medication-item-value">{this.formatReason(item.reason)}</div>
        </div>
        <div className="medication-item-display-field">
          <label className="medication-item-label">Remind me?</label>
          <div className="medication-item-value">{item.remind_me ? 'Yes' : 'No'}</div>
        </div>

        <div className="mn-buttons are-small">
          <button className="is-link " onClick={this.edit}>Edit</button>
          <button className="is-link" onClick={this.delete}>Delete</button>
        </div>

        {this.form()}
        <hr />
      </div>
    );
  }

  edit = () => {
    this.setState({ showForm: true });
  };

  closeForm = () => {
    this.setState({ showForm: false });
  };

  form() {
    if (this.state.showForm) {
      return (
        <Form
          item={this.props.item}
          onClose={this.closeForm}
          actions={this.props.actions}
          title="Edit Medication"
          store={this.props.store}
          delete={this.delete}
        />
      );
    }
  }

  delete = () => {
    swal({
      text: null,
      button: null,
      content: (
        <div>
          <h1>Are you sure?</h1>
          <p>Are you sure you want to delete your medication entry {this.props.item.name}?</p>
          <button className="mn-button is-link" onClick={() => { swal.close(); } }>Cancel</button>
          <button className="mn-button is-destructive" onClick={ this.reallyDelete }>Delete</button>
        </div>
      )
    });
  }

  reallyDelete = () => {
    swal.close();
    this.props.actions.destroy(this.props.item);
  };

  formatReason = val => this.formatWithPlaceholder(val);

  formatDosage = val => this.formatWithPlaceholder(val);

  formatFreq = item => {
    if (item.quantity && item.frequency) {
      return `${item.quantity}, ${this.formatFrequency(item.frequency)}`;
    } else if (item.quantity) {
      return `${item.quantity}`;
    } else if (item.frequency) {
      return `${this.formatFrequency(item.frequency)}`;
    } else {
      return '-';
    }
  };

  formatWithPlaceholder = val => {
    return val || '-';
  };

  formatFrequency = frequency => (frequency ? frequency.toLowerCase() : '');
}
