import React from 'react';
import YoutubeContainer from '../../../../Youtube/containers/YoutubeContainer';

class ResourceItemVideoSection extends React.Component {
  render() {
    if (!this.props.resourceItem.linkedVideo) {
      return null;
    }

    return (
      <div className="resource-item-video-aspect-ratio-box">
        <YoutubeContainer
          videoId={this.props.resourceItem.linkedVideo.youtubeId}
          resourceId={this.props.resourceItem.id}
        />
      </div>
    );
  }
}

export default ResourceItemVideoSection;
