/* eslint camelcase: 0 */
import User from './User';

export default class MeetingUser extends User {
  constructor(user, store) {
    super(user);
    this.store = store;
    this.canMeetWith = this.canMeetWith.bind(this);
  }

  meetsWith() {
    return this.isPatient() ? 'Nurse' : 'Patient';
  }

  meetingPartners() {
    if (this.store.meet.partners.loaded) {
      return this.store.meet.partners.items;
    }
  }

  canMeetWith(user) {
    if (this.meetsWith() === 'Patient') {
      return user.isPatient;
    }

    return user.isNurse || user.isCoach;
  }

  order_by_name(a, b) {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    }
    return 0;
  }

  canViewPrivateNotes() {
    return this.isNurse;
  }
}
