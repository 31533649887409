export const queryParams = () => {
  const queryString = {};
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    // If first entry with this name
    if (typeof queryString[pair[0]] === 'undefined') {
      queryString[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      // If second entry with this name
    } else if (typeof queryString[pair[0]] === 'string') {
      var arr = [queryString[pair[0]], decodeURIComponent(pair[1])];
      queryString[decodeURIComponent(pair[0])] = arr;
      // If third or later entry with this name
    } else {
      queryString[decodeURIComponent(pair[0])].push(decodeURIComponent(pair[1]));
    }
  }
  return queryString;
};
