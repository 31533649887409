import actionTypes from '../constants';
import User from 'models/User';

export default {
  receiveItem: actionTypes.RECEIVE_USER,
  requestUpdateItem: actionTypes.REQUEST_USER_UPDATE,
  storeKey: 'users',
  Model: User,
  apiEndPoint: '/api/v1/users',
  bodyParam: 'user'
};
