import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Mixpanel from 'common/Mixpanel';
import ContentModulesPopUp from './ContentModulesPopUp';
import { MyProgramContext } from '../context/MyProgramContext';

export function NextButton({ nextStep, currentModule }) {
  return (
    <button className="mn-button next-button" onClick={() => { Mixpanel.track(`${currentModule.title} - Next`); nextStep(); }}>
      Next &gt;
    </button>
  );
}

export function PrevButton({ prevStep, currentModule }) {
  return (
    <button className="mn-button next-button" onClick={() => { Mixpanel.track(`${currentModule.title} - Prev`); prevStep(); }}>
      &lt; Prev
    </button>
  );
}

export function FinishButton({ nextStep, currentModule }) {
  const context = useContext(MyProgramContext);
  return (
    <Popup
      trigger={<button className="mn-button next-button">Finish</button>}
      onClose={() => context.setCompletedModulesIds([...context.completedModulesIds, currentModule.id])}
      modal
    >
      {(close) => <ContentModulesPopUp close={close} nextStep={nextStep} moduleName={currentModule.title} />}
    </Popup>
  );
}

export function CompletedButton() {
  return <button className="mn-button completed-button ">Completed</button>;
}

NextButton.propTypes = {
  nextStep: PropTypes.func
};
