import React from 'react';
import PropTypes from 'prop-types';
import UserAvatar from 'mn-react/components/UserAvatar';

export default class ConversationUserAvatars extends React.Component {
  static propTypes = {
    conversation: PropTypes.object
  };

  render() {
    const { conversation } = this.props;

    return <div className="conversation-list__avatar">{this._avatars(conversation)}</div>;
  }

  _avatars(conversation) {
    if (conversation.numberOfUsers() === 1) {
      return (
        <UserAvatar size='size-40' user={conversation.otherUsers()[0]} />
      );
    }

    if (conversation.numberOfUsers() > 1) {
      var firstOtherUser = conversation.otherUsers()[0];
      var secondOtherUser = conversation.otherUsers()[1];

      var styles1 = {
        backgroundImage: `url(${firstOtherUser.links.profile_image_small_url})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100%'
      };

      var styles2 = {
        backgroundImage: `url(${secondOtherUser.links.profile_image_small_url})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: '100%'
      };

      return (
        <span>
          <div className="halfcircle1" style={styles1} />
          <div className="halfcircle2" style={styles2} />
        </span>
      );
    }

    return (
      <div/>
    );
  }
}
