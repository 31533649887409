import React from 'react';
import PropTypes from 'prop-types';

import UIText from 'store/UIText';

import Header from './Header';
import MedicationList from './MedicationList';
import Form from './Form';
import Medication from 'models/Medication';

export default class Medications extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    userId: PropTypes.number.isRequired
  };

  state = { showForm: false };

  render() {
    const { store, actions } = this.props;
    return (
      <div>
        <Header add={this.add} />
        {store.medications.items.length > 0 ? (
          <MedicationList
            store={store}
            medications={store.medications.items.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())}
            actions={actions}
          />
        ) : (
          <p>
            <UIText>Upload your medications here and keep them in a safe place.</UIText>
          </p>
        )}

        {this.form()}
      </div>
    );
  }

  add = () => this.setState({ showForm: true });

  closeForm = () => this.setState({ showForm: false });

  form() {
    const { store } = this.props;
    if (this.state.showForm) {
      return (
        <Form
          item={new Medication({ user_id: this.props.userId }, store)}
          onClose={this.closeForm}
          actions={this.props.actions}
          title="Add a medication"
          store={store}
        />
      );
    }
  }
}
