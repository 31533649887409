import React from 'react';

class ResourceItemImageSection extends React.Component {
  render() {
    if (!this.props.resourceItem.imageUrl) {
      return null;
    }

    return (
      <img
        className="resource-item-image"
        src={this.props.resourceItem.imageUrl}
        alt={`${this.props.resourceItem.title} image`}
      />
    );
  }
}

export default ResourceItemImageSection;
