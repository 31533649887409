import React from 'react';
import PropTypes from 'prop-types';
import InviteStatus from './InviteStatus';

// Thin wrapper around  InviteStatus or none (if patient)
export default class AvailabiltyStatus extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;

    if (user.isPatient()) {
      return <div />;
    } else {
      return <InviteStatus user={user} />;
    }
  }
}
