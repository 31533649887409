import { deprecatedFetchResource, updateResource, deleteResource } from './ApiResources.js';
import { CurrentUserStateGetSuccessAction } from '../store/actions/CurrentUserStateActions';
import actionTypes from '../store/constants';

export class ApiEndpoint {
  constructor(config) {
    this.config = config;
  }

  load(dispatch, getState, params) {
    if (this._needToLoad(getState()[this.config.storeKey])) {
      return this._doFetch(dispatch, getState, params);
    } else {
      return Promise.resolve();
    }
  }

  forcedLoad(dispatch, getState, params) {
    this._doFetch(dispatch, getState, params);
  }

  filteredLoad(dispatch, getState, params) {
    dispatch(this._clearCollection());
    this._doFetch(dispatch, getState, params);
  }

  callAndReceiveItem(dispatch, getState, data, url) {
    const resource = {
      url: url,
      onSuccess: this._receiveItem.bind(this, dispatch),
      store: getState()
    };
    const resourceParams = typeof data.asParams === 'function' ? data.asParams() : data;
    updateResource(resourceParams, resource, dispatch, getState);
  }

  save(dispatch, getState, data, params = {}) {
    const resourceDefaults = {
      url: this.formatUrl(this.config.apiEndPoint, params),
      param: this.config.bodyParam,
      onSuccess: this._receiveItem.bind(this, dispatch),
      onCompleteCallBack: params.onCompleteCallBack,
      store: getState()
    };

    if (this.config.requestUpdateItem) {
      resourceDefaults.onBegin = () => {
        return {
          type: this.config.requestUpdateItem,
          data: data
        };
      };
    }

    const resource = Object.assign({}, resourceDefaults, params.resourceOverrides);
    const resourceParams = typeof data.asParams === 'function' ? data.asParams() : data;

    return updateResource(resourceParams, resource, dispatch, getState);
  }

  loadItem(dispatch, getState, id, data, params) {
    const resource = {
      url: this.formatUrl(this.config.apiEndPoint + '/' + id, params),
      onSuccess: this._receiveItem.bind(this, dispatch),
      store: getState()
    };

    deprecatedFetchResource(resource, dispatch, getState);
  }

  loadItemFromProps(dispatch, getState, json) {
    const onSuccess = this._receiveItem.bind(this, dispatch);

    dispatch(onSuccess(json, getState()));
  }

  loadCollectionFromProps(dispatch, getState, json) {
    var onBegin = this._requestCollection.bind(this);
    var onSuccess = this._receiveCollection.bind(this);

    dispatch(onBegin);
    dispatch(onSuccess(json, getState()));
  }

  destroy(dispatch, getState, data, params) {
    const resource = {
      url: this.formatUrl(this.config.apiEndPoint, params),
      store: getState(),
      onSuccess: this._removeItem.bind(this)
    };
    deleteResource(data, resource, dispatch, getState);
  }

  // private methods below here! - no standard way for this in JS, however prefixing with _ is close

  _doFetch(dispatch, getState, params) {
    const resource = {
      onBegin: this._requestCollection.bind(this),
      url: this.formatUrl(this.config.apiEndPoint, params),
      onSuccess: this._receiveCollection.bind(this),
      store: getState()
    };

    return deprecatedFetchResource(resource, dispatch, getState);
  }

  _requestCollection() {
    return {
      type: this.config.requestCollection
    };
  }

  _receiveCollection(json, store, options = {}) {
    let items = [];
    if (this.config.collectionKey && json[this.config.collectionKey]) {
      items = json[this.config.collectionKey].map(data => new this.config.Model(data, store, json));
    }
    return {
      type: this.config.receiveCollection,
      items: items,
      json: json,
      store: store,
      loadedFromCache: options.loadedFromCache || false,
      receivedAt: Date.now()
    };
  }

  _clearCollection() {
    return {
      type: this.config.clearCollection
    };
  }

  formatUrl(url, params) {
    if (params && params.queryStr) {
      url += '?' + params.queryStr;
    }
    if (params && params.baseUrlVars) {
      Object.keys(params.baseUrlVars).forEach(key => (url = url.replace(`:${key}`, params.baseUrlVars[key])));
    }
    return url;
  }

  _receiveItem(dispatch, json, store, options = {}) {
    if (this.config.receiveItem === actionTypes.RECEIVE_USER) {
      dispatch(CurrentUserStateGetSuccessAction());
    }

    let item = null;
    if (this.config.itemKey && json[this.config.itemKey]) {
      item = new this.config.Model(json[this.config.itemKey], store, json);
    }
    return {
      type: this.config.receiveItem,
      item: item,
      json: json,
      store: store,
      receivedAt: Date.now()
    };
  }

  _removeItem(json, store) {
    return {
      type: this.config.removeItem,
      id: json[this.config.itemKey].id,
      json: json,
      store: store,
      receivedAt: Date.now()
    };
  }

  _needToLoad(obj) {
    return !obj.isFetching && (!obj.loaded || obj.loadedFromCache);
  }
}
