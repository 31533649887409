/* eslint camelcase: 0 */
// Represents a single meeting, with the context of one the two users attending
export default class MeetingForUser {
  constructor(meeting, store) {
    this.meeting = meeting;
    this.store = store;
    this.user = store.currentUser;

    for (var prop in meeting) {
      if (Object.prototype.hasOwnProperty.call(meeting, prop)) {
        this[prop] = meeting[prop];
      }
    }
  }

  requestingUser = () => this.store.users.findUser(this.meeting.requesting_user_id);

  requestedUser = () => this.store.users.findUser(this.meeting.requested_user_id);

  isRequestor = () => this.meeting.requesting_user_id === this.user.id;

  isRequestedUser = () => this.meeting.requested_user_id === this.user.id;

  current_user = () => this;

  isMeetingMember(user) {
    return user.id === this.requestedUser().id || user.id === this.requestingUser().id;
  }

  otherUser = () => (this.isRequestor() ? this.requestedUser() : this.requestingUser());

  nurseUser = () => this.requestingUser().isNurse() ? this.requestingUser() : this.requestedUser();

  patientUser() {
    return this.requestingUser().isPatient() ? this.requestingUser() : this.requestedUser();
  }

  canConfirm = () => !this.isRequestor() && !this.confirmedMeeting();

  currentUserIsAMember = () => this.isRequestor() || this.isRequestedUser();

  userActionMessage() {
    if (!this.confirmedMeeting() && !this.isRequestor()) {
      return 'Confirm';
    } else {
      return this.confirmedMeeting() ? 'Enter or review' : 'View';
    }
  }

  confirmedMessage = () => (this.confirmedMeeting() ? '' : '(unconfirmed)');

  confirmedStatus = () => (this.confirmedMeeting() ? 'Confirmed' : 'Unconfirmed');

  confirmedMeeting = () => this.meeting.is_confirmed === true;

  title = () => `${this.otherUser().name}`;

  modalType = () => 'MeetingForUser';
  meetingType = () => 'call';
}

function sortByfield(a, b) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
}

export function orderByNurse(a, b) {
  return sortByfield(a.nurseUser().name, b.nurseUser().name);
}

export function orderByPatient(a, b) {
  return sortByfield(a.patientUser().name, b.patientUser().name);
}

export function orderByDate(a, b) {
  return sortByfield(a.meeting_at, b.meeting_at);
}

export function orderByStatus(a, b) {
  return sortByfield(a.confirmedStatus(), b.confirmedStatus());
}
