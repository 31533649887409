import actionTypes from '../constants';

import Message from 'models/Message';

export default {
  receiveItem: actionTypes.RECEIVE_MESSAGE, // Handled by the conversationReducer
  removeItem: actionTypes.REMOVE_MESSAGE,

  Model: Message,

  itemKey: 'conversation', // returns a conversation

  apiEndPoint: '/api/v1/messages',
  bodyParam: 'message'
};
