import React from 'react';
import ResourceFavoriteSection from '../components/ResourceSections/ResourceFavoriteSection/ResourceFavoriteSection';
import ResourceSectionCard from '../components/ResourceSections/ResourceSectionCard/ResourceSectionCard';

class ResourceCategories extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    };
  }

  render() {
    return (
      <div>
        <ResourceFavoriteSection
          favoritesSection={this.props.resourceSections.favoritesSection}
          selectResourceSection={this.props.resourceSections.selectResourceSection}
        />
        <div className="resource-sections-cards-container">
          {this._resourceSectionsList() }
        </div>
      </div>
    );
  }

  /**
   * Get the resource sections list
   *
   * @private
   *
   * @method _resourceSectionsList
   *
   * @return {Array/Elements} An array of resource sections
   */
  _resourceSectionsList() {
    return this.props.resourceSections.resourceSections.filter(section => section.resourceCount > 0).map(resourceSection => {
      return (
        <div key={resourceSection.id}>
          <ResourceSectionCard
            resourceSection={resourceSection}
            selectResourceSection={() => {}}
          />
        </div>
      );
    });
  }
}

export default ResourceCategories;
