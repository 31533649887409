/* global location */
import 'isomorphic-fetch';
import lscache from 'lscache';
import CryptoJS from 'crypto-js';
import { apiMethods } from './apiConstants';

const { fetch } = global;

const cachePrefix = `key-${document.querySelector('body').dataset.userId}-`;
const secretKey = `key-${document.querySelector('body').dataset.cacheKey}-`;

/**
 * The fetch resource uses isomorphic-fetch and has the following promise resolvers.
 * When resolving the promise a success action will be dispatched. Server side business logic error messages will be
 * handled in the `.then` and dispatch the failure action. Network errors will be handled in the `.catch` handler.
 */
export function fetchResource(resource, dispatch, getState) {
  if (resource.onBegin) {
    dispatch(resource.onBegin({ resource }));
  }

  const cache = lscache.get(cachePrefix + resource.url);
  if (cache && cacheableUrl(resource.url)) {
    try {
      const bytes = CryptoJS.AES.decrypt(cache, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      dispatch(
        resource.onSuccess(decryptedData, resource.store, {
          loadedFromCache: true
        })
      );
    } catch (err) {}
  }
  return fetch(resource.url, { credentials: 'same-origin', cache: 'no-store' })
    .then(response => {
      if (!response.ok && response.status === 401) {
        location.reload();
      } else {
        return response.json();
      }
    })
    .then(json => {
      const hasErrors = json.errors;

      if (cacheableUrl(resource.url)) {
        const data = CryptoJS.AES.encrypt(JSON.stringify(json), secretKey).toString();
        lscache.set(cachePrefix + resource.url, data);
      }

      if (hasErrors && resource.onFailure) {
        dispatch(resource.onFailure(json.errors, { resource }));
      }

      if (!hasErrors && resource.onSuccess) {
        dispatch(resource.onSuccess(json, resource.store));
      }

      return json;
    })
    .catch(error => {
      if (resource.onFailure) {
        dispatch(resource.onFailure(error, { resource }));
      }
    });
}

function loadCSRFToken() {
  const meta = document.querySelector('meta[name=csrf-token]');

  if (meta) {
    return meta.getAttribute('content');
  } else {
    return null;
  }
}

function headersForUpdateOrDelete() {
  const token = loadCSRFToken();

  if (token) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': token
    };
  } else {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
  }
}

export function updateResource(data, resource, dispatch, getState) {
  if (resource.onBegin) {
    dispatch(resource.onBegin());
  }

  let params;

  // When you want to be explicit about url and method use resourceUrl & method (recommended)
  if (resource.resourceUrl && resource.method) {
    params = { method: resource.method, url: resource.resourceUrl };
  } else {
    // Historically we made assumptions on put and post.  This is a bit restrictive as it does not allow for patch
    // or setting of explicit url. However it is available and is still used by existing code
    params = data.id
      ? { method: apiMethods.PUT, url: `${resource.url}/${data.id}` }
      : { method: apiMethods.POST, url: resource.url };
  }

  var body = {};
  if (resource.param) {
    body[resource.param] = data;
  } else {
    body = data;
  }

  const fetchParams = {
    credentials: 'same-origin',
    method: params.method,
    headers: headersForUpdateOrDelete(),
    body: JSON.stringify(body)
  };

  return fetch(params.url, fetchParams)
    .then(response => response.json())
    .then(json => {
      const hasErrors = json.errors || json.error;
      if (resource.onSuccess && !hasErrors) {
        dispatch(resource.onSuccess(json, resource.store));
      }

      if (resource.onCompleteCallBack) {
        resource.onCompleteCallBack({
          success: !hasErrors,
          json: json
        });
      }

      if (hasErrors && resource.onFailure) {
        dispatch(resource.onFailure(json.errors));
      }

      // Return response so we can use ".then" promise handlers off action creators
      return json;
    })
    .catch(function(err) {
      if (resource.onFailure) {
        dispatch(resource.onFailure(err));
      }

      if (resource.onCompleteCallBack) {
        resource.onCompleteCallBack({ success: false, error: err });
      }

      // Return response so we can use ".then" promise handlers off action creators
      return err;
    });
}

export function deleteResource(data, resource, dispatch, getState) {
  if (resource.onBegin) {
    dispatch(resource.onBegin());
  }

  const params = {
    method: apiMethods.DELETE,
    url: `${resource.url}${data && data.id ? `/${data.id}` : ''}`
  };

  return fetch(params.url, {
    credentials: 'same-origin',
    method: params.method,
    headers: headersForUpdateOrDelete()
  })
    .then(response => response.json())
    .then(json => {
      const hasErrors = json.errors || json.error;

      if (resource.onSuccess && !hasErrors) {
        dispatch(resource.onSuccess(json, resource.store));
      }

      if (resource.onCompleteCallBack) {
        resource.onCompleteCallBack({
          success: !hasErrors,
          json: json
        });
      }

      if (hasErrors && resource.onFailure) {
        dispatch(resource.onFailure(json.errors));
      }

      // Return response so we can use ".then" promise handlers off action creators
      return json;
    })
    .catch(error => {
      if (resource.onFailure) {
        dispatch(resource.onFailure(error));
      }

      if (resource.onCompleteCallBack) {
        resource.onCompleteCallBack({ success: false, error: error });
      }

      // Return response so we can use ".then" promise handlers off action creators
      return error;
    });
}

// White list which URL's we cache, i.e. don't cache page=2 etc
const cacheableUrls = [
  '/api/v1/users/current_user',
  '/api/v1/meetings',
  '/api/v1/conversations'
];

const cacheableUrl = url => cacheableUrls.includes(url);

/**
 * Deprecated fetchResource, the server side (business logic) errors are returned as part of the success handler here.
 * The new fetch resource will evaluate if the response contains an errors object and if so dispatch the onFailure action.
 * This way the server and network errors can be handled by the same onFailure action. This brings this method inline
 * with how the updateResource method works.
 */
export function deprecatedFetchResource(resource, dispatch, getState) {
  if (resource.onBegin) {
    dispatch(resource.onBegin());
  }

  const cache = lscache.get(cachePrefix + resource.url);
  if (cache && cacheableUrl(resource.url)) {
    try {
      const bytes = CryptoJS.AES.decrypt(cache, secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      dispatch(
        resource.onSuccess(decryptedData, resource.store, {
          loadedFromCache: true
        })
      );
    } catch (err) {}
  }
  return fetch(resource.url, { credentials: 'same-origin', cache: 'no-store' })
    .then(response => {
      if (!response.ok && response.status === 401) {
        location.reload();
      } else {
        return response.json();
      }
    })
    .then(json => {
      if (cacheableUrl(resource.url)) {
        const data = CryptoJS.AES.encrypt(JSON.stringify(json), secretKey).toString();
        lscache.set(cachePrefix + resource.url, data);
      }

      if (resource.onSuccess) {
        dispatch(resource.onSuccess(json, resource.store));
      }
    })
    .catch(error => {
      if (resource.onFailure) {
        dispatch(resource.onFailure(error));
      }
    });
}
