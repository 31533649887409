/* eslint-disable */
//
// Copied from https://github.com/sweetalert/sweetalert-with-react
// v0.1.1 @ 4b08a880e36a7cbc42b6057db4fe94e9c362de60
//
// This has been copied from the sweet alert repo so that we can run it through babel
// without running our entire node modules through it. This is a temporary measure to
// allow the community to load in IE11
//
import React from 'react';
import ReactDOM from 'react-dom';

import transformer, { bindActions } from './sweetalert-transformer';

/*
 * Convert <Element /> to pure DOM node using ReactDOM
 * (remember that ReactDOM.render() is async!)
 */
const getDOMNodeFromJSX = (Element) => {
  const wrapper = document.createElement('div');

  return new Promise((resolve) => {
    ReactDOM.render(Element, wrapper, () => {
      const el = wrapper.firstChild;

      return resolve(el);
    });
  });
};

const swal = (...params) => (
  transformer(params, {
    identifier: React.isValidElement,
    transformer: getDOMNodeFromJSX, 
  })
);

bindActions(swal);

export default swal;
