import React from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import UIText from 'store/UIText';

import Conversations from './Conversations/index';
import NewConversationComponent from '../components/NewConversation/index';
import Messages from './Messages/index';
import EmptyMessage from './EmptyMessage';

export default class ChatComponent extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    filterUsers: PropTypes.func.isRequired,
    chatWithActions: PropTypes.object.isRequired,
    messageActions: PropTypes.object.isRequired,
    conversationActions: PropTypes.object.isRequired,
    filterConversations: PropTypes.func.isRequired
  };

  render() {
    const { store, messageActions, conversationActions } = this.props;

    return (
      <Router>
        <div className="conversation-wrapper">
          <Routes>
            <Route path="/conversations/:id" element={<Conversations
              store={this.props.store}
              filterUsers={this.props.filterUsers}
              filterConversations={this.props.filterConversations}
              loadConversations={this.props.conversationActions.load}
              chatWithActions={this.props.chatWithActions}
            />} />
            <Route path="/conversations" element={<Conversations
              store={this.props.store}
              filterUsers={this.props.filterUsers}
              filterConversations={this.props.filterConversations}
              loadConversations={this.props.conversationActions.load}
              chatWithActions={this.props.chatWithActions}
            />} />
          </Routes>

          <div className="conversation-display">
            <UIText className="chattychat__conversation-list-message">
              chattychat post conversation list message __##!placeholder!##__
            </UIText>
            <Routes>
              <Route
                path="/conversations/new"
                element={<NewConversationComponent
                  {...this.props}
                  save={conversationActions.save}
                  currentUser={store.currentUser}
                  filteredUsers={store.chatWith}
                  notices={this._messageNotifications()}
                />}
              />

              <Route path="/conversations/saving" element={<div>Saving....</div>} />

              <Route
                exact
                path="/conversations/:id"
                element={<Messages
                  store={store}
                  deleteMessage={messageActions.destroy}
                  sendNewMessage={messageActions.save}
                  findConversation={conversationActions.findConversation}
                  markConversationAsRead={conversationActions.markConversationAsRead}
                  notices={this._messageNotifications()}
                />}
              />
              <Route path="/conversations" element={ <EmptyMessage currentUser={store.currentUser} />} />
            </Routes>
          </div>
        </div>
      </Router>
    );
  }

  _messageNotifications = () => {
    const notices = {
      coachDelayMessage: this.props.store.uiText['Coach Delay Message']
    };

    return notices;
  }
}
