import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MeetingActionCreators from 'store/actions/MeetingActions';
import * as SettingsActionCreators from 'store/actions/SettingsActions';
import * as UserActionCreators from 'store/actions/UserActions';
import { isFetching, isLoaded } from 'rest-states';

import NurseMeetings from '../components/NurseMeetings/index.js';

function select(store) {
  return { store };
}

class NurseMeetingsWidget extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null,
      loadedMeetings: null
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  };

  componentDidMount() {
    const { dispatch, store } = this.props;

    const settingsActions = bindActionCreators(SettingsActionCreators, dispatch);
    settingsActions.load();

    const userActions = bindActionCreators(UserActionCreators, dispatch);

    if (!isFetching(store.currentUserState.getRestState) && !isLoaded(store.currentUserState.getRestState)) {
      userActions.loadCurrentUser();
    }
  }

  componentDidUpdate() {
    const { store } = this.props;

    if (store.currentUser !== this.state.currentUser) {
      this.setState({ currentUser: store.currentUser });
    }

    if (this.state.currentUser && !this.state.loadedMeetings) {
      this.setState({ loadedMeetings: true });
      this.loadMeetingsAfterUsersLoaded();
    }
  }

  loadMeetingsAfterUsersLoaded = () => {
    const { dispatch, store } = this.props;

    const meetingActions = bindActionCreators(MeetingActionCreators, dispatch);

    meetingActions.fetchAllNurseMeetings();
    meetingActions.fetchNurses();

    // refresh data via pubnub
    if (process.env.NODE_ENV !== 'development') {
      store.pubnub.client.subscribe({
        channel: store.pubnub.pubnubChannel,
        callback: meetingActions.fetchAllNurseMeetings
      });
    }
  };

  render() {
    const { dispatch, store } = this.props;
    const meetingActions = bindActionCreators(MeetingActionCreators, dispatch);

    if (!store.meet.all_nurse_meetings.loaded || !store.meet.nurses.loaded || !this.state.currentUser) {
      return <div>loading...</div>;
    }
    return <NurseMeetings store={store} meetingActions={meetingActions} />;
  }
}

export default connect(select)(NurseMeetingsWidget);
