import actionTypes from '../constants';

import Medication from 'models/Medication';

export default {
  requestCollection: actionTypes.REQUEST_MEDICATIONS,
  receiveCollection: actionTypes.RECEIVE_MEDICATIONS,
  receiveItem: actionTypes.RECEIVE_MEDICATION,
  removeItem: actionTypes.REMOVE_MEDICATION,

  storeKey: 'medications',
  Model: Medication,

  collectionKey: 'medications',
  itemKey: 'medication',

  apiEndPoint: '/api/v1/users/:user_id/medications',
  bodyParam: 'medication'
};
