import React from 'react';
import PropTypes from 'prop-types';

export default class ImagePreview extends React.Component {
  static propTypes = {
    cancelImage: PropTypes.func.isRequired,
    image: PropTypes.string
  };

  shouldComponentUpdate(prevProps) {
    return prevProps.image !== this.props.image;
  }

  render() {
    if (this.props.image) {
      return (
        <div className="image-upload-container">
          <button onClick={this.props.cancelImage} className="cancel-upload">
            <i className="far fa-times"/>
          </button>
          <img src={this.props.image} className="image-upload-preview" />
        </div>
      );
    } else {
      return null;
    }
  }
}
