import React from 'react';

const MnFavoriteAddIcon = () => {
  return (
    <svg className="mn-favourite-icon" viewBox="0 0 50 27" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.05.881c.302-.916 1.598-.916 1.9 0l2.827 8.578h9.11c.971 0 1.372 1.247.581 1.813l-7.357 5.269 2.818 8.549c.303.919-.745 1.69-1.532 1.126L14 20.918l-7.397 5.298c-.787.563-1.835-.207-1.532-1.126l2.818-8.549L.53 11.272c-.79-.566-.39-1.813.583-1.813h9.109L13.05.881zM45 10.833h-4.167V15a.836.836 0 0 1-.833.833.836.836 0 0 1-.833-.833v-4.167H35a.836.836 0 0 1-.833-.833c0-.458.375-.833.833-.833h4.167V5c0-.458.375-.833.833-.833.458 0 .833.375.833.833v4.167H45c.458 0 .833.375.833.833a.836.836 0 0 1-.833.833z"
      />
    </svg>
  );
};

export default MnFavoriteAddIcon;
