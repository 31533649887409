import React from 'react';
import PropTypes from 'prop-types';

export default class AllNurseMeetings extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    meetings: PropTypes.object,
    MeetingView: PropTypes.func,
    limitSize: PropTypes.bool
  };

  render() {
    const { meetings } = this.props;

    return <div id="widget-meetings">{this.renderMeetingsSummary(meetings)}</div>;
  }

  renderMeetingsSummary(meetings) {
    const { MeetingView } = this.props;
    if (!meetings.loaded) {
      return 'loading calls...';
    } else if (meetings.items === []) {
      return 'There are no upcoming calls';
    } else {
      var renderedMeetings = [];
      const meetingsToRender = this.props.limitSize ? meetings.items.slice(0, 2) : meetings.items;
      for (const meeting of meetingsToRender.values()) {
        renderedMeetings.push(<MeetingView meeting={meeting} key={meeting.id} />);
      }
      return (
        <div>
          <p className="subtitle">All upcoming calls</p>
          {renderedMeetings}
        </div>
      );
    }
  }
}
