import React from 'react';
import PropTypes from 'prop-types';

export default function ContentModulesCurrentSummary({ currentModule }) {
  const header = currentModule.start_week === 0 ? 'How to use Melon:' : 'In this module:';

  return (
    <div className="summary">
      <p className="strong">{currentModule.body}</p>
      <h3>{header}</h3>

      <ul>
        {currentModule.expectations?.map((expect) => {
          return currentModule.expectations_link_to ? (
            <li onClick={currentModule.expectations_link_to.path} key={currentModule.expectations.indexOf(expect)}>
              {currentModule.expectations_link_to.caption}
            </li>
          ) : (
            <li key={currentModule.expectations.indexOf(expect)}>{expect}</li>
          );
        })}
      </ul>
    </div>
  );
}

ContentModulesCurrentSummary.propTypes = {
  currentModule: PropTypes.object
};
