import BaseModel from 'models/BaseModel';
import Like from 'models/Like';
import Reply from 'models/Reply';
import Resource from 'models/Resource';

export default class Post extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      body: '',
      author_id: null,
      links: null,
      community_id: null,
      community_name: null,
      mentions: null,
      created_at: null,
      feedback: null
    };
    super(item, store, json, properties);
  }

  modalType = () => 'Post';

  author = store => store.allUsers.find(this.author_id);

  likes = () => (this.item.like_ids || []).map(id => this._findLike(id));

  _findLike = id => new Like(this.json.likes.find(m => +m.id === +id) || {});

  replies = () => (this.item.reply_ids || []).map(id => this._findReply(id));

  repliesOrderedChronologically = () => {
    return this.replies().sort(function(a, b) {
      return new Date(a.created_at) - new Date(b.created_at);
    });
  };

  _findReply = id => new Reply(this.json.replies.find(m => +m.id === +id) || {}, this.store, this.json);

  resource = () => (this.hasResource() ? new Resource(this.item.resource, this.store) : null);

  hasResource = () => this.item.resource != null;

  hasDeletedResource = () => this.item.resource === null && this.item.body === 'Shared resource';

  hasImage = () => this.links && this.links.image_medium_url
}

function sortByfield(a, b) {
  if (a === null || a === undefined || b === null || b === undefined) {
    return 0;
  } else if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
}

export function orderByCreatedAt(a, b) {
  return sortByfield(b.created_at, a.created_at); // a & b are reversed, as we want recent first
}
