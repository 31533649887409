import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Mixpanel from 'common/Mixpanel';
import swal from 'mn-react/common/sweetalert-with-react';

import UserAvatar from 'mn-react/components/UserAvatar';
import UserNameAndRoleBadge from '../Common/UserNameAndRoleBadge';
import ReactList from '../Common/ReactAction';
import ReplyLikes from './ReplyLikes';
import BodyFormatter from '../Common/BodyFormatter';
import PostOptions from '../Common/PostOptions';
import Editor from '../Editor';
import { isInputEmpty } from 'web/utils/formHelpers';
import styles from './createPostStyles.css';
import Toast from 'common/toast';
import UserLikesView from '../Likes/UserLikesView';

export default class ReplyView extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    reply: PropTypes.object.isRequired,
    focused: PropTypes.bool.isRequired,
    focus: PropTypes.func.isRequired,
    ensureUserHasConfirmed: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showEdit: false,
      body: this.props.reply.body,
      isShowingMore: false,
      isReplying: false,
      isEditing: false,
      reactionChooserOpen: false,
      optionsOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focused !== this.props.focused && !this.props.focused) {
      this.setState({ reactionChooserOpen: false, optionsOpen: false });
    }
  }

  render() {
    const { reply, store, actions } = this.props;
    const author = reply.author(store);

    return (
      <div className="community-post-reply">
        <UserAvatar user={author} size='size-40' />
        <div className="reply-content">
          <div className="author-details">
            <UserNameAndRoleBadge user={author} />
            <PostOptions
              open={this.state.optionsOpen}
              toggle={this.toggleOptionsMenu}
              reply={reply}
              store={store}
              actions={actions.replyActions}
              startEditing={this.startEditing}
              reportPost={this.report}
              blockUser={this.blockUser}
            />
          </div>
          <div className="reply-time mn-subtext">
            {moment(reply.created_at).fromNow()}
          </div>
          <div className="reply-body">
            {this.bodyText()}
            <div className="reply-footer">
              <span className="react" onClick={(event) => { this.toggleReactMenu(event); Mixpanel.track('Reply Reaction Button'); }}>
                React
              </span>
              { this.state.reactionChooserOpen && <ReactList { ...this.props } item={ this.props.reply } close={ this.toggleReactMenu } /> }
              {reply.likes().length > 0 && <ReplyLikes {...this.props} showAllLikes={this.showAllLikes} />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  bodyText = () => {
    const { reply } = this.props;

    const shouldTruncate = (reply.body.split(' ').length > 40) && (this.state.isShowingMore === false);

    let bodyFormatter;
    if (shouldTruncate) {
      bodyFormatter = <BodyFormatter item={reply} truncateAt={40} />;
    } else {
      bodyFormatter = <BodyFormatter item={reply} />;
    }

    return (
      <div className="community-post__reply_body_text">
        {this.state.isEditing ? this.showPostEdit() : bodyFormatter}
        &nbsp;
        {shouldTruncate ? this.showMoreLink() : ''}
      </div>
    );
  };

  showMoreLink = () => (
    <a onClick={() => { this.showMore(); Mixpanel.track('Show Full Reply Button'); }}>
     Read more
    </a>
  );

  showMore = (_) => this.setState({ isShowingMore: true });

  showPostEdit = () => (
    <form className={styles.form} onSubmit={this.submit}>
      <Editor onChange={this.setBody} body={this.state.body} withImageUploader={false} id={`edit-${this.props.reply.id}`} />
      <div className={styles.toolbar}>
        <input type="button" value="Cancel" className="mn-button is-link" onClick={ this.cancelEditing } />
        <input type="submit" name="commit" value="Save changes" className="mn-button is-primary" />
      </div>
    </form>
  );

  setBody = val => this.setState({ body: val });

  submit = e => {
    e.preventDefault();

    if (!isInputEmpty(this.state.body)) {
      const reply = this.props.reply;
      reply.body = this.state.body;
      this.props.actions.replyActions.save(reply);
    }

    this.cancelEditing();
  };

  startEditing = () => {
    this.setState({ isEditing: true, isShowingMore: true });
  };

  cancelEditing = () => {
    this.setState({ isEditing: false });
  }

  report = () => {
    Toast.add(`${this.props.reply.author(this.props.store).username}'s post has been reported.`, { expires: 5000 });
    this.props.actions.postActions.report(this.props.reply);
  }

  blockUser = () => {
    Toast.add(`${this.props.reply.author(this.props.store).username} has been blocked.`, { expires: 5000 });
    this.props.actions.userActions.block(this.props.reply.author_id);
  }

  toggleReactMenu = event => {
    event.stopPropagation();

    if (this.props.ensureUserHasConfirmed(this.props.store.currentUser)) {
      this.props.focus();
      this.setState({ reactionChooserOpen: !this.state.reactionChooserOpen, optionsOpen: false });
    }
  }

  toggleOptionsMenu = event => {
    event.stopPropagation();

    if (this.props.ensureUserHasConfirmed(this.props.store.currentUser)) {
      this.props.focus();
      this.setState({ reactionChooserOpen: false, optionsOpen: !this.state.optionsOpen });
    }
  }

  showAllLikes = () => {
    swal({
      text: null,
      button: null,
      content: (
        <UserLikesView store={this.props.store} likes={this.props.reply.likes()} close={this.closeLikes} />
      )
    });
  }

  closeLikes = () => {
    swal.close();
  }
}
