import { ApiEndpoint } from 'backend/ApiEndpointActions';
import apiConfig from '../apiConfigs/conversations';

const endpoint = new ApiEndpoint(apiConfig);

export function load(params = {}) {
  return function(dispatch, getState) {
    const queryParams = [];
    if (params.forUser) {
      queryParams.push(`with_user_id=${params.forUser.id}`);
    }

    if (params.conversationId && params.conversationId !== 'new') {
      queryParams.push(`current_conversation_id=${params.conversationId}`);
    }
    if (queryParams.length === 0) {
      endpoint.forcedLoad(dispatch, getState);
    } else {
      endpoint.filteredLoad(dispatch, getState, {
        queryStr: queryParams.join('&')
      });
    }
  };
}

// call this when pubnub triggers a change
export function refreshConversations() {
  return function(dispatch, getState) {
    endpoint.forcedLoad(dispatch, getState);
  };
}

export function save(data) {
  return function(dispatch, getState) {
    return endpoint.save(dispatch, getState, data);
  };
}

export function destroy(data) {
  return function(dispatch, getState) {
    endpoint.destroy(dispatch, getState, data);
  };
}

export function findConversation(id) {
  return function(dispatch, getState) {
    return findConversationIn(getState().conversations.items, id);
  };
}

function findConversationIn(list, id) {
  return list.find(c => c.id === parseInt(id));
}

export function markConversationAsRead(conversationId) {
  // fire a mark as read, and then reload item so any changes are seen
  const url = `/api/v1/conversations/${conversationId}/mark_as_read`;
  return function(dispatch, getState) {
    endpoint.callAndReceiveItem(dispatch, getState, {}, url);
  };
}
