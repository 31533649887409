import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isFetching, isLoaded } from 'rest-states';

import * as PostActionCreators from 'store/actions/PostActions';
import * as ReplyActionCreators from 'store/actions/ReplyActions';
import * as LikeActionCreators from 'store/actions/LikeActions';
import * as SettingsActionCreators from 'store/actions/SettingsActions';
import * as UserActionCreators from 'store/actions/UserActions';
import * as CurrentCommunityActionCreators from 'store/actions/CurrentCommunityActions';

import { BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom';
import ViewCommunity from '../components/ViewCommunity';
import ViewSinglePost from '../components/ViewSinglePost';

import Mixpanel from 'common/Mixpanel';

function select(store) {
  return { store };
}

class ViewCommunityPostsWidget extends React.Component {
  constructor() {
    super();

    this.state = {
      currentUser: null
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  };

  componentDidMount() {
    const { dispatch, store } = this.props;
    const settingActions = bindActionCreators(SettingsActionCreators, dispatch);
    const userActions = bindActionCreators(UserActionCreators, dispatch);

    if (!isFetching(store.currentUserState.getRestState) && !isLoaded(store.currentUserState.getRestState)) {
      userActions.loadCurrentUserFromProps(this.props.currentUser);
    }

    settingActions.load();

    Mixpanel.screenView();
  }

  componentDidUpdate() {
    const { store } = this.props;

    if (store.currentUser !== this.state.currentUser) {
      this.setState({ currentUser: store.currentUser });
    }
  }

  render() {
    const { dispatch, store } = this.props;

    if (!this.state.currentUser) {
      return null;
    }

    const postActions = bindActionCreators(PostActionCreators, dispatch);
    const replyActions = bindActionCreators(ReplyActionCreators, dispatch);
    const likeActions = bindActionCreators(LikeActionCreators, dispatch);
    const userActions = bindActionCreators(UserActionCreators, dispatch);
    const currentCommunityActions = bindActionCreators(CurrentCommunityActionCreators, dispatch);

    const actions = {
      postActions: postActions,
      replyActions: replyActions,
      likeActions: likeActions,
      userActions: userActions,
      currentCommunityActions
    };

    return (
      <Router>
        <Routes>
          <Route
            path="/posts/community/:id"
            element={<ViewCommunity
              store={store}
              actions={actions}
              enableRefreshListener={this.props.enableRefreshListener}
              focusNewPostOnStart={this.props.focusNewPostOnStart}
              initialPosts={this.props.posts}
              isEmbedded={this.props.isEmbedded}
              ensureUserHasConfirmed={this.ensureUserHasConfirmed}
            />}
          />
          <Route
            path="/posts/:id"
            element={<ViewSinglePost
              store={store}
              actions={actions}
              postData={this.props.postData}
              ensureUserHasConfirmed={this.ensureUserHasConfirmed}
            />}
          />

          <Route
            path="/posts"
            element={<Navigate to={`/posts/community/${this.state.currentUser.returnDefaultCommunityId().toString()}`} replace={true} />}
          />
        </Routes>
      </Router>
    );
  }

  ensureUserHasConfirmed = (user) => {
    if (!user.has_confirmed && user.username != null) {
      Mixpanel.track('Unconfirmed Email');
      window.location.href = '/access/confirm_email';
      return false;
    }
    if (user.username == null) {
      window.location.href = '/access/pick_username';
      return false;
    }
    return true;
  }
}

export default connect(select)(ViewCommunityPostsWidget);
