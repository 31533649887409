import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ContentModulesCurrent from '../components/ContentModulesCurrent/ContentModulesCurrent';
import ContentModulesList from '../components/ContentModulesList';
import ProgramSummary from './ProgramSummary';
import { MyProgramContext } from '../context/MyProgramContext';
import * as CompletedResourceActionCreators from 'store/actions/CompletedResourceAction';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
function select(store) {
  return { store };
}

function ContentModules(props) {
  const {
    current_module: currentModule,
    weekly_modules_data: weeklyModules,
    total_modules: totalModules,
    total_resources: totalResources,
    completed_resources: completedResourcesNum,
    completed_resources_ids: completedResIds,
    all_completed_resources: allCompletedResources,
    completed_modules: completedMods,
    modules
  } = props.user;

  const {
    dispatch, store, inMobileApp
  } = props;
  const completedResourceActions = bindActionCreators(CompletedResourceActionCreators, dispatch);

  const actions = {
    completedResourceActions: completedResourceActions
  };

  const resources = totalResources === 0 ? 1 : totalResources;
  const [completedModules, setCompletedModules] = useState(completedMods.length);
  const [completedModulesIds, setCompletedModulesIds] = useState(completedMods.map((m) => m.module_id));
  const [completedResources, setCompletedResources] = useState(completedResourcesNum);
  const [completedResourcesIds, setCompletedResourcesIds] = useState(completedResIds);
  const [userProgress, setProgress] = useState(Math.floor((completedResources / resources) * 100));

  const decreaseCompletedResources = () => {
    completedResources <= 0 ? setCompletedResources(0) : setCompletedResources(completedResources - 1);
  };

  const increaseCompletedResources = () => {
    completedResources >= resources ? setCompletedResources(resources) : setCompletedResources(completedResources + 1);
  };

  const saveCompletedResource = (resource, onPrevStep) => {
    if (userProgress !== 100) {
      if (resource && !completedResourcesIds.includes(resource.id) && completedResources < resources) {
        actions.completedResourceActions.save(resource.id);
        setCompletedResourcesIds([...completedResourcesIds, resource.id]);
      }
      onPrevStep ? decreaseCompletedResources() : increaseCompletedResources();
    }
  };

  useEffect(() => {
    setProgress(Math.floor((completedResources / resources) * 100));
  }, [completedResources]);

  mixpanel.init(props.mixpanelProjectToken, { api_transport: 'sendBeacon', debug: !(props.railsENV === 'production' || props.railsENV === 'staging') });
  return (
    <div>
      <MyProgramContext.Provider
        value={{
          userProgress,
          completedResources,
          saveCompletedResource,
          allCompletedResources,
          // last module is a resources summary, we don't want to include it in progress
          completedModules: completedModules > 0 ? completedModules - 1 : completedModules,
          setCompletedModules,
          completedModulesIds,
          setCompletedModulesIds,
          // last module is a resources summary, we don't want to include it in progress
          totalModules: totalModules > 0 ? totalModules - 1 : totalModules
        }}
      >
        {inMobileApp ? (
          <ContentModulesMobile
            currentModule={currentModule}
            userProgress={userProgress}
            weeklyModules={weeklyModules}
            modules={modules}
            actions={actions}
            store={store}
            dispatch={dispatch}
            inMobileApp={inMobileApp}
          />
        ) : (
          <ContentModulesWeb
            currentModule={currentModule}
            userProgress={userProgress}
            weeklyModules={weeklyModules}
            modules={modules}
            actions={actions}
            store={store}
            dispatch={dispatch}
            inMobileApp={inMobileApp}
          />
        )}
      </MyProgramContext.Provider>
    </div>
  );
}

export function ContentModulesWeb({
  currentModule,
  userProgress,
  weeklyModules,
  modules,
  actions,
  store,
  dispatch,
  inMobileApp
}) {
  return (
    <>
      <ProgramSummary inMobileApp={inMobileApp} />
      <div className="content_modules container">
        <div className="row">
          <div className="col-md-4">
            <ContentModulesList
              currentModule={currentModule}
              userProgress={userProgress}
              weeklyModules={weeklyModules}
              inMobileApp={inMobileApp}
              modules={modules}
            />
          </div>
          <div className="col-md-8">
            <ContentModulesCurrent modules={modules} currentModule={currentModule} actions={actions} store={store} dispatch={dispatch} />
          </div>
        </div>
      </div>
    </>
  );
}

export function ContentModulesMobile({
  currentModule,
  userProgress,
  weeklyModules,
  modules,
  actions,
  store,
  dispatch,
  inMobileApp
}) {
  return (
    <Router>
      <Routes>
        <Route path="/redesigned_content_modules/current"
          element={<div className="content_modules container">
            <div className="row">
              <ProgramSummary inMobileApp={inMobileApp} />
              <ContentModulesList
                currentModule={currentModule}
                userProgress={userProgress}
                weeklyModules={weeklyModules}
                inMobileApp={inMobileApp}
                modules={modules}
              />
            </div>
          </div>}
        />

        <Route path="/redesigned_content_modules/:id"
          element={ <ContentModulesCurrent modules={modules} currentModule={currentModule} actions={actions} store={store} dispatch={dispatch} />}
        />

        <Route path="/redesigned_content_modules" element={<Navigate to="redesigned_content_modules/current" replace={true} />} />
      </Routes>
    </Router>
  );
}

ContentModules.props = {
  current_module: PropTypes.object,
  weekly_modules_data: PropTypes.object,
  total_modules: PropTypes.number,
  total_resources: PropTypes.number
};

export default connect(select)(ContentModules);
