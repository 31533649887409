import React from 'react';
import PropTypes from 'prop-types';
import ConversationsList from './ConversationsList';
import NewConversationBtn from '../NewConversation/NewConversationBtn';
import Availability from './Availability';
import Filter from './Filter';
import MnSpinner from 'mn-react/components/MnSpinner';
import withRouter from '../../../../common/withRouter';

class ConversationsComponent extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    loadConversations: PropTypes.func.isRequired,
    chatWithActions: PropTypes.object.isRequired,
    filterConversations: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { loadConversations } = this.props;
    loadConversations({ conversationId: this.conversationId() });
  }

  render() {
    const { store } = this.props;
    const { currentUser } = store;
    if (!currentUser) {
      return (
        <div className="conversation-widget-loading">
          <MnSpinner />
        </div>
      );
    }
    return (
      <div className="conversation-list">
        <Heading currentUser={currentUser} newAllowed={this.newConversationAllowed()} />
        {this.newConversationAllowed() && <Availability currentUser={currentUser} />}
        {store.chatWith.loaded && (
          <Filter {...this.props} filteredUsers={store.chatWith} conversationId={this.conversationId()} />
        )}
        <ConversationsList
          {...this.props}
          user={currentUser}
          conversations={store.conversations}
          conversationId={this.conversationId()}
          filterByUser={store.chatWith.forUser}
        />
      </div>
    );
  }

  newConversationAllowed = () =>
    this.props.store.settings &&
    this.props.store.settings.loaded &&
    this.props.store.settings.chat.allow_new_conversation;

  conversationId = () => this.props.params.id;
}

const Heading = ({ newAllowed }) => (
  <h2>
    My messages
    {newAllowed && <NewConversationBtn />}
  </h2>
);

export default withRouter(ConversationsComponent);
