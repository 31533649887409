import { useContext } from 'react';
import { MyProgramContext } from '../context/MyProgramContext';

/**
 * By default, each badge represents 20% progress within the program (badgeWeight).
 * This excludes the first badge which is earned after completing the first module.
 * However, for "large programs" (eg. DPP) with more than 20 modules, the milestones
 * are shifted down so more badges are earned in the first half and less in the second.
 *
 * What this looks like
 * - For large programs: milestoneTwo = 20%, milestoneThree = 40%, milestoneFour = 60%
 * - For other programs: milestoneTwo = 40%, milestoneThree = 60%, milestoneFour = 80%
 */
export function getMilestones() {
  const context = useContext(MyProgramContext);
  const badgeWeight = Math.trunc((context.totalModules * 20) / 100); // 20% of total modules
  const largeProgram = context.totalModules > 20;
  const milestoneTwo = largeProgram ? badgeWeight : badgeWeight * 2;
  const milestoneThree = largeProgram ? badgeWeight * 2 : badgeWeight * 3;
  const milestoneFour = largeProgram ? badgeWeight * 3 : badgeWeight * 4;
  const milestoneFive = context.totalModules;

  return [milestoneTwo, milestoneThree, milestoneFour, milestoneFive];
}
