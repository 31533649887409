import { reducer as restReducer, initialState as restInitialState } from 'backend/stdRestReducer';
import apiConfig from './RemindersApiConfig';

export const initialState = restInitialState;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return restReducer(state, action, apiConfig);
  }
}

export const orderReminders = items => items.sort(orderByTitle);

const orderByTitle = (a, b) => {
  if ((a.title || '').toUpperCase() > (b.title || '').toUpperCase()) {
    return 1;
  } else if ((a.title || '').toUpperCase() < (b.title || '').toUpperCase()) {
    return -1;
  }
  return 0;
};
