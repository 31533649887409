import React from 'react';
import PropTypes from 'prop-types';

export default class LikeView extends React.Component {
  static propTypes = {
    likeKind: PropTypes.string.isRequired,
    likeCount: PropTypes.number
  };

  render() {
    return (
      <div className="community-post-reaction-container">
        <span className={`community-post__mini-emoticon community-post__mini-emoticon_${this.props.likeKind}`} />
        { this.props.likeCount && (<span className="community-post-reaction-count">{this.props.likeCount}</span>) }
      </div>
    );
  }
}
