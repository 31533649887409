import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CurrentModuleBg from '../../../../../../assets/images/content_modules/current_module_bg.png';
import LinearProgressBar from '../LinearProgressBar';
import { CompletedButton, FinishButton, NextButton, PrevButton } from '../ContentModulesButtons';
import { MyProgramContext } from '../../context/MyProgramContext';

export default function ContentModulesCurrentHeader({ nextStep, prevStep, isFirstStep, isFinalStep, currentModule }) {
  const context = useContext(MyProgramContext);
  const isCompletedModule = context.completedModulesIds.includes(currentModule.id);
  const [displayFinishButton, setDisplayFinishButton] = useState(false);
  const [displayCompletedButton, setDisplayCompletedButton] = useState(false);
  const [displayNextButton, setDisplayNextButton] = useState(true);

  useEffect(() => {
    !isFinalStep ? setDisplayNextButton(true) : setButtons();
  }, [context.completedModulesIds]);

  const setButtons = () => {
    if (isCompletedModule) {
      setDisplayCompletedButton(true);
      setDisplayFinishButton(false);
    } else {
      setDisplayCompletedButton(false);
      setDisplayFinishButton(true);
    }
    setDisplayNextButton(false);
  };

  const displayButton = () => {
    switch (true) {
      case displayFinishButton:
        return <FinishButton nextStep={nextStep} currentModule={currentModule} />;
      case displayCompletedButton:
        return <CompletedButton />;
      case displayNextButton:
        return <NextButton nextStep={nextStep} currentModule={currentModule} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="banner" style={{ backgroundImage: `url(${CurrentModuleBg})` }}>
        {!isFirstStep && <PrevButton prevStep={prevStep} currentModule={currentModule} />}
        {displayButton()}
      </div>
      <LinearProgressBar />
    </>
  );
}

ContentModulesCurrentHeader.propTypes = {
  nextStep: PropTypes.func
};
