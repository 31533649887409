import React from 'react';
import UserAvatar from 'mn-react/components/UserAvatar';

export default class UserLikesView extends React.Component {
  render() {
    const { likes } = this.props;

    return (
      <div className="likes-modal">
        <i className="close-button far fa-times" onClick={this.props.close}></i>
        <ul className="likes-with-user">
          {likes.map(like => {
            const author = like.author(this.props.store);

            return (
              <li className="like-with-user" key={like.id}>
                <span className={`community-post__mini-emoticon community-post__mini-emoticon_${like.kind}`} />
                <UserAvatar user={author} size='size-40' />
                <span className="username">{author.username}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
