import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import GLightbox from 'glightbox';
import Mixpanel from 'common/Mixpanel';
import swal from 'mn-react/common/sweetalert-with-react';

import UserAvatar from 'mn-react/components/UserAvatar';
import UserNameAndRoleBadge from '../Common/UserNameAndRoleBadge';
import PostOptions from '../Common/PostOptions';
import PostResource from './PostResource';
import PostLikes from './PostLikes';
import PostReplies from '../Replies/PostReplies';
import BodyFormatter from '../Common/BodyFormatter';
import Editor from '../Editor';
import styles from './createPostStyles.css';
import { isInputEmpty } from 'web/utils/formHelpers';
import Toast from 'common/toast';
import ReactList from '../Common/ReactAction';
import PostWriteReply from './PostWriteReply';
import UserLikesView from '../Likes/UserLikesView';

export default class PostView extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    focused: PropTypes.bool.isRequired,
    focus: PropTypes.func.isRequired,
    ensureUserHasConfirmed: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showEdit: false,
      body: props.post.body,
      isShowingMore: false,
      isReplying: false,
      isEditing: false,
      reactionChooserOpen: false,
      optionsOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focused !== this.props.focused && !this.props.focused) {
      this.setState({ reactionChooserOpen: false, optionsOpen: false });
    }

    if (this.props.post.hasImage()) {
      GLightbox({ selector: `.expandableImage-${this.props.post.id}`, touchNavigation: false, keyboardNavigation: false, draggable: false, preload: false });
    }
  }

  render() {
    const { post, store } = this.props;
    const author = post.author(store);

    return (
      <div className="mn-card community-post">
        <div className="post-content">
          <UserAvatar user={author} size='size-40' />
          <div className="post-header">
            <div className="author-details">
              <UserNameAndRoleBadge user={author} />
              <PostOptions
                open={this.state.optionsOpen}
                toggle={this.toggleOptionsMenu}
                post={post}
                store={store}
                actions={this.props.actions.postActions}
                startEditing={this.startEditing}
                reportPost={this.report}
                blockUser={this.blockUser}
              />
            </div>
            <div className="post-time mn-subtext">
              {moment(post.created_at).fromNow()}
            </div>
          </div>

          {this.bodyText()}
          {this.postImage()}
          {this.postResource()}

          <PostLikes {...this.props} showAllLikes={this.showAllLikes} />

          <div className="react" onClick={(event) => { this.toggleReactMenu(event); Mixpanel.track('Post Reaction Button'); } }>
            {this.state.reactionChooserOpen && <ReactList {...this.props} item={this.props.post} close={this.toggleReactMenu} /> }
            React
          </div>
        </div>

        <PostReplies {...this.props} focus={this.focusReply} focused={this.props.focused && !(this.state.reactionChooserOpen || this.state.optionsOpen)} />
        <PostWriteReply {...this.props} active={this.state.isReplying} onFocus={this.startCommenting} onCancel={this.cancelComment} />
      </div>
    );
  }

  bodyText = () => {
    const { post } = this.props;
    if (post.hasResource()) {
      return null;
    }
    if (post.hasDeletedResource()) {
      return (
        <div className="community-post__shared-resource">
          <div className="community-post__shared-resource-content">
            <h3>Resource Deleted</h3>
          </div>
        </div>
      );
    }

    const shouldTruncate = (post.body && post.body.split(' ').length > 60) && (this.state.isShowingMore === false);

    let bodyFormatter;
    if (shouldTruncate) {
      bodyFormatter = <BodyFormatter item={post} truncateAt={60} />;
    } else {
      bodyFormatter = <BodyFormatter item={post} />;
    }

    if (this.state.isEditing) {
      return (
        <div className="post-body">
          { this.showPostEdit() }
        </div>
      );
    } else if (post.body === null) {
      return null;
    } else {
      return (
        <div className="post-body">
          { bodyFormatter }
          &nbsp;
          { shouldTruncate ? this.showMoreLink() : '' }
        </div>
      );
    }
  };

  postImage = () => {
    const { post } = this.props;
    if (post.hasResource()) {
      return null;
    }
    if (post.hasImage()) {
      return (
        <a href={post.links.image_large_url} className={ `expandableImage-${post.id}` } data-glightbox="type: image">
          <img className="post-image" src={post.links.image_medium_url}></img>
        </a>
      );
    }

    return null;
  };

  postResource = () => {
    const { post } = this.props;
    if (post.hasResource()) {
      return <PostResource {...this.props} />;
    }
    return null;
  };

  showMoreLink = () => (
    <a onClick={() => { this.showMore(); Mixpanel.track('Show Full Post Button'); }}>
     Read more
    </a>
  );

  showMore = (_) => this.setState({ isShowingMore: true });

  showPostEdit = () => (
    <form className={styles.form} onSubmit={this.submit}>
      <Editor onChange={this.setBody} body={this.state.body} withImageUploader={false} id={`edit-${this.props.post.id}`} focusOnStart={true} />
      <div className={styles.toolbar}>
        <input type="button" value="Cancel" className="mn-button is-link" onClick={() => { this.cancelEditing(); Mixpanel.track('Cancel Edit Post'); } }/>
        <input type="submit" name="commit" value="Save changes" className="mn-button is-primary" />
      </div>
    </form>
  );

  setBody = val => this.setState({ body: val });

  submit = e => {
    e.preventDefault();

    const post = this.props.post;
    const body = isInputEmpty(this.state.body) ? null : this.state.body;

    if (body || (post.links && post.links.image_medium_url)) {
      post.body = body;
      this.props.actions.postActions.save(post);
    } else {
      Toast.add('Unable to save empty post', { expires: 5000 });
    }

    Mixpanel.track('Save Post Changes');
    this.cancelEditing();
  };

  report = () => {
    Toast.add(`${this.props.post.author(this.props.store).username}'s post has been reported.`, { expires: 5000 });
    this.props.actions.postActions.report(this.props.post);
  }

  blockUser = () => {
    Toast.add(`${this.props.post.author(this.props.store).username} has been blocked.`, { expires: 5000 });
    this.props.actions.userActions.block(this.props.post.author_id);
  }

  toggleReactMenu = event => {
    event.stopPropagation();

    if (this.props.ensureUserHasConfirmed(this.props.store.currentUser)) {
      this.props.focus();
      this.setState({ reactionChooserOpen: !this.state.reactionChooserOpen, optionsOpen: false });
    }
  }

  toggleOptionsMenu = event => {
    event.stopPropagation();

    if (this.props.ensureUserHasConfirmed(this.props.store.currentUser)) {
      this.props.focus();
      this.setState({ reactionChooserOpen: false, optionsOpen: !this.state.optionsOpen });
    }
  }

  startCommenting = () => {
    if (this.props.ensureUserHasConfirmed(this.props.store.currentUser)) {
      this.setState({ isReplying: true });
    }
  }

  cancelComment = () => {
    this.setState({ isReplying: false });
  }

  startEditing = () => {
    this.setState({ isShowingMore: true, isEditing: true });
  }

  cancelEditing = () => {
    this.setState({ isEditing: false });
  }

  showAllLikes = () => {
    swal({
      text: null,
      button: null,
      content: (
        <UserLikesView store={this.props.store} likes={this.props.post.likes()} close={this.closeLikes} />
      )
    });
  }

  closeLikes = () => {
    swal.close();
  }

  focusReply = () => {
    this.props.focus();
    this.setState({ reactionChooserOpen: false, optionsOpen: false });
  }
}
