import React from 'react';

const MnResourceDocumentIcon = (props) => {
  const dimensions = props.dimensions || {};
  const colour = props.colour || '#fff';

  return (
    <svg width={ dimensions.width || '24px' } height={ dimensions.height || '30px' } viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.885.885A3.01 3.01 0 0 0 13.755 0H3C1.35 0 0 1.35 0 3v24c0 1.65 1.335 3 2.985 3H21c1.65 0 3-1.35 3-3V10.245c0-.795-.315-1.56-.885-2.115L15.885.885zM16.5 24h-9c-.825 0-1.5-.675-1.5-1.5S6.675 21 7.5 21h9c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5zm0-6h-9c-.825 0-1.5-.675-1.5-1.5S6.675 15 7.5 15h9c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5zm-3-9V2.25l8.25 8.25H15c-.825 0-1.5-.675-1.5-1.5z"
        fill={colour}
      />
    </svg>
  );
};

export default MnResourceDocumentIcon;
