import BaseModel from 'models/BaseModel';

export default class Medication extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      name: '',
      strength: '',
      quantity: '',
      frequency: '',
      reason: '',
      remind_me: false,
      reminder_id: null,
      user_id: item.user_id || store.currentUser.id,
      is_deleted: false,
      created_at: null,
      updated_at: null,

      // reminder fields
      reminder_title: '',
      time_of_day: '',
      frequency_id: ''
    };
    super(item, store, json, properties);
  }

  modalType = () => 'Medication';
}
