import React from 'react';
import Header from './Header';
import Panel from 'mn-react/components/Panel';

import DailyAffirmationImage from '../../../../assets/images/home/bg-daily-affirmation.png';
import RecommendedReadImage from '../../../../assets/images/home/bg-recommended-read.png';

export default class Home extends React.Component {
  render() {
    const CommunityPanel = () => {
      return (
        <Panel
          text={"Check out what's happening with your community"}
          backgroundColor="#e9816e"
          hasDisclosureIndicator
          panelType="navigation"
          displayPage={
            this.props.user.community_id === null ? '/home' : `/posts/community/${this.props.user.community_id}`
          }
        />
      );
    };

    const JournalPanel = () => {
      return (
        <Panel
          text="Track your progress"
          backgroundColor="#f09d5f"
          hasDisclosureIndicator
          panelType="navigation"
          displayPage="/journal"
        />
      );
    };

    const MyProgramPanel = () => {
      return (
        <Panel
          text="Review your program"
          backgroundColor="#4b7eca"
          hasDisclosureIndicator
          panelType="navigation"
          displayPage={this.props.user.my_program_path}
        />
      );
    };

    const DailyAffirmationPanel = () => {
      return (
        <Panel
          text={this.props.user.daily_affirmation}
          panelType="daily_affirmation"
          imageUrl={DailyAffirmationImage}
        />
      );
    };

    const RecommendedReadPanel = () => {
      return (
        <Panel
          text={this.props.user.recommended_read}
          panelType="recommended_read"
          imageUrl={RecommendedReadImage}
          displayPage={this.props.user.recommended_read[0]}
        />
      );
    };

    const displayCommunityPanel = () => {
      if (this.props.user.community_enabled) return <CommunityPanel />;
    };

    const displayMyProgramPanel = () => {
      if (this.props.user.my_program_enabled) {
        return (
          <div className="home-panel-group">
            <JournalPanel />
            <div className="mn-gap" />
            <MyProgramPanel />
          </div>
        );
      } else {
        return <JournalPanel />;
      }
    };

    return (
      <>
        <Header user={this.props.user} />
        <div className="mn-container home-body" data-cy="panels">
          {displayCommunityPanel()}
          {displayMyProgramPanel()}
          <div className="badge" data-cy="panel_name">
            Daily Affirmation
          </div>
          <DailyAffirmationPanel />
          <div className="badge" data-cy="panel_name">
            Recommended Read
          </div>
          <RecommendedReadPanel />
        </div>
      </>
    );
  }
}
