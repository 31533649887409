import React from 'react';
import PropTypes from 'prop-types';

export default class ConversationNotice extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired
  };

  /**
   * This uses dangerouslySetInnerHTML as the message passed contains embedded html derived from the locals on our server
   */
  render() {
    return (
      <div className='conversation-notice'>
        <span dangerouslySetInnerHTML={{ __html: this.props.message }} />
      </div>
    );
  }
}
