/* global $ */
import React from 'react';
import PropTypes from 'prop-types';
// TODO: does not emit a message back up to the store
export default class InviteStatus extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    if (this.props.user.meeting_status !== undefined) {
      this.state = {
        available: this.props.user.meeting_status.available
      };
    }
  }

  render() {
    if (this.props.user.meeting_status === undefined) {
      return <div />;
    } else {
      return (
        <div className="meeting-invite-status">
          <p>
            You are <strong>{this.statusWord()}</strong>{' '}
            <a href="#" onClick={this.changeStatus}>
              {this.buttonText()}
            </a>
          </p>
          <p className="meeting-status-info">{this.availableDesc()}</p>
        </div>
      );
    }
  }

  availableDesc() {
    return this.state.available ? 'Patients may request meetings' : "Patients can't request meetings with you";
  }

  statusWord() {
    return this.state.available ? 'available' : 'unavailable';
  }

  buttonText() {
    return this.state.available ? '(change)' : '(change)';
  }

  changeStatus = () => {
    var newStatus = !this.state.available;
    this.setState({
      available: newStatus
    });
    var url = `/meeting_status_availability/${this.props.user.id}?available=${newStatus}`;
    $.post(url, { _method: 'put' }, function(result) {});
  };
}
