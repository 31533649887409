import React from 'react';
import MnIcon from 'mn-react/components/MnIcon';

class ResourceHeaderToggleFavorites extends React.Component {
  render() {
    const { resourceItem } = this.props;

    if (!resourceItem) {
      return null;
    }

    return this._getFavoriteButton();
  }

  _getFavoriteButton() {
    const { resourceItem } = this.props;
    const icon = resourceItem.favorite ? 'mn-favorite-remove-icon' : 'mn-favorite-add-icon';

    return (
      <button className="mn-button is-primary" onClick={() => this._toggleResourceFavorite(resourceItem)}>
        <MnIcon icon={ icon } />
      </button>
    );
  }

  _toggleResourceFavorite(resourceItem) {
    if (resourceItem.favorite) {
      return this.props.removeResourceFavorite({ id: resourceItem.id, slug: resourceItem.slug });
    }

    return this.props.setResourceFavorite({ id: resourceItem.id, slug: resourceItem.slug });
  }
}

export default ResourceHeaderToggleFavorites;
