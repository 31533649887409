import React from 'react';
import moment from 'moment';

export default function TimeAgoNoIcon({ time }) {
  moment.locale('en', {
    relativeTime: {
      future: 'moments',
      past: '%s',
      s: '1 second',
      m: '1 minute',
      mm: '%d minutes',
      h: '1 hour',
      hh: '%d hours',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years'
    }
  });

  if (time === undefined || time === null) {
    return <span className="message-time" />;
  }
  const ago = moment(time).fromNow();

  return <span className="message-time">{ago} ago</span>;
}
