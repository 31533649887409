import BaseModel from 'models/BaseModel';

export default class User extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      community_id: null,
      type: null,
      role_name: '',
      i18n_role_name: '',
      name: '',
      first_name: '',
      last_name: '',
      profile_image_url: '',
      links: {},
      email: '',
      username: '',
      is_deleted: false,
      location: '',
      bio: null,
      has_onboarded: null,
      program_start_date: null,
      program_id: null,
      goal: null,
      user_theme_image_id: null,
      meet_and_greet_status: null,
      accepted_current_terms: null,
      us_measurement_system: null,
      program_type: null,
      dob: null,
      ethnicity: '',
      time_zone: '',
      time_zone_offset: null,
      medical_group_location: '',
      feature_set: {},
      survey_due: null,
      gender_options: [],
      has_help_enabled: false,
      available_for_chat: false,
      has_confirmed: false,
      confirmed_at: null,
      unconfirmed_email: null
    };
    super(item, store, json, properties);
  }

  isNurse = () => this.type === 'Nurse' || this.role_name === 'Nurse';

  isCoach = () => this.role_name === 'Coach';

  isCommunityManager = () => this.role_name === 'Community Manager';

  isPatient = () => this.type === 'Patient' || this.role_name === 'Patient';

  isEmotionalWellnessMember = () => this.program_type === 'emotional_wellness';

  hasHelpEnabled = () => this.has_help_enabled === true;

  shouldSeeCoachDelayMessage = () => this.hasHelpEnabled() && this.isPatient();

  canToggleChatStatus = () => this.isNurse();

  postableCommunities = communityId =>
    this._communities().filter(c => {
      return communityId === c.id;
    });

  switchableCommunities = () => this._communities();

  communities = () => this._communities();

  communityKeyFor = id => {
    const community = this.communityFor(id);
    return community.id;
  };

  // copes with communities being side loaded or inline
  _communities = () => (this.json && this.json.communities ? this.json.communities : this.item.communities);

  communityNameFor = val => this.communityFor(val).name;

  communityFor = val => {
    const id = this.communityIdFor(val);
    return this._communities().find(c => c.id === id);
  };

  returnDefaultCommunityId = () => this.community_id == null ? this._communities()[0].id : this.community_id;

  // takes a id or anything and ensures the return is a valid community id
  communityIdFor = val =>
    this._communities().find(c => c.id === parseInt(val)) ? parseInt(val) : this.returnDefaultCommunityId();

  orderByName = (a, b) => {
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    }
    return 0;
  };
}
