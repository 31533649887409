import BaseModel from 'models/BaseModel';

export default class Like extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      likeable_type: null,
      likeable_id: null,
      kind: '',
      author_id: null,
      is_deleted: false,
      created_at: null
    };
    super(item, store, json, properties);
  }

  author = store => store.allUsers.find(this.author_id);
}
