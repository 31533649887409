import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RequestNewMeeting from '../components/RequestNewMeeting';

function select(store) {
  return { store };
}

class MeetAndGreetScheduleCallContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  };

  render() {
    const { store } = this.props;
    return <RequestNewMeeting current_user={store.currentUser} />;
  }
}

export default connect(select)(MeetAndGreetScheduleCallContainer);
