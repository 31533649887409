import React from 'react';
import moment from 'moment';
import { constants, resourceTypes } from '../../resourceHelpers';

class ResourceItemBodyTextSection extends React.Component {
  render() {
    if (!this.props.resourceItem.bodyText) {
      return null;
    }

    return (
      <div className="resource-item-body-text-container">
        {this._getEventInformation()}
        <div dangerouslySetInnerHTML={this._createMarkup()} />
      </div>
    );
  }

  /**
   * Get the event information element, will return nothing when resource is not an event
   *
   * @private
   *
   * @method _getEventInformation
   *
   * @return {Element} The event information
   */
  _getEventInformation() {
    const { type, location, eventStart, eventEnd } = this.props.resourceItem;

    if (type !== resourceTypes.EVENT) {
      return null;
    }

    const locationElement = location ? (
      <div className="mn-mbs">
        <span className="mn-bold">Location:&nbsp;</span>
        {location}
      </div>
    ) : null;

    const startTimeElement = eventStart ? (
      <div className="mn-mbs">
        <span className="mn-bold">Starts:&nbsp;</span>
        {moment(eventStart).format(constants.RESOURCE_DATE_TIME_FORMAT)}
      </div>
    ) : null;

    const endTimeElement = eventEnd ? (
      <div className="mn-mbs">
        <span className="mn-bold">Ends:&nbsp;</span>
        {moment(eventEnd).format(constants.RESOURCE_DATE_TIME_FORMAT)}
      </div>
    ) : null;

    return (
      <div className="resource-item-body-text-event-container">
        {locationElement}
        {startTimeElement}
        {endTimeElement}
      </div>
    );
  }

  /**
   * Create markup to be set as inner HTML for body text
   * Note this markup has been sanitized on the model
   *
   * @private
   *
   * @method _createMarkup
   *
   * @return {Object} An object containing the html for the body text section
   */
  _createMarkup() {
    return { __html: this.props.resourceItem.bodyText };
  }
}

export default ResourceItemBodyTextSection;
