import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Toast from 'common/toast';
import Mixpanel from 'common/Mixpanel';

import {
  getResourceSections,
  setResourceSections,
  getResourceList,
  setResourceList,
  getResourceItem,
  setResourceItem,
  setResourceItemAsFavorite,
  removeResourceItemAsFavorite
} from 'store/actions/ResourceActions';

import { isFailed } from 'rest-states';
import MnSpinner from 'mn-react/components/MnSpinner';
import ResourceSections from '../components/ResourceSections/ResourceSections';
import ResourceList from '../components/ResourceList/ResourceList';
import ResourceItem from '../components/ResourceItem/ResourceItem';

/**
 * This class is mainly used as a workaround for an IOS issue.  When navigating back sometimes content won't render
 * on IOS Safari until the user touches the device. This is possibly due to a race condition between IOS trying to render
 * the previous scroll position and the browser trying to scroll to top on route change. In this case IOS would not have
 * rendered the top of the page and only updates when the user touches the device.
 */
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 1);
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

class ResourcesContainer extends React.Component {
  constructor(props) {
    super(props);

    this._dispatch = this.props.dispatch;
    this._history = createBrowserHistory();
    this.state = {
      currentPath: this._history.location.pathname,
      loaded: false
    };

    this.selectResourceSection = this.selectResourceSection.bind(this);
    this.selectResourceItem = this.selectResourceItem.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
    this.setResourceFavorite = this.setResourceFavorite.bind(this);
    this.removeResourceFavorite = this.removeResourceFavorite.bind(this);
  }

  componentDidMount() {
    if (this.props.resourceSections) {
      this._dispatch(setResourceSections(this.props.resourceSections));
    } else if (this.props.resourceSection) {
      this._dispatch(setResourceList(this.props.resourceSection));
    } else if (this.props.resource) {
      this._dispatch(setResourceItem(this.props.resource));
    }

    this._history.listen((data) => {
      const path = data.location.pathname;
      this.setState({ currentPath: path });
      this._updateRailsDropDownCurrentPage(path);
      this._sendMixpanelScreen(path);
    });
    this._sendMixpanelScreen(this.state.currentPath);
    this.setState({ loaded: true });
  }

  _sendMixpanelScreen(path) {
    const pathComponents = path.split('/').filter(Boolean);

    if (pathComponents.length === 2) {
      Mixpanel.setScreenName(`Resources Section - ${pathComponents[1]}`);
    } else if (pathComponents.length === 3) {
      Mixpanel.setScreenName(`Resource View - ${pathComponents[2]}`);
    } else {
      Mixpanel.setScreenName('Resources Overview');
    }
    Mixpanel.screenView();
  }

  componentDidUpdate(prevProps, _prevState) {
    const { favorites } = this.props.store.resources;
    const prevFavorites = prevProps.store.resources.favorites;

    // Determine if state has changed
    const hasUpdatedResourceFavoritesProps = prevFavorites.saveRestState !== favorites.saveRestState;

    // Determine resource favorite state
    const hasFailedToSetResourceAsFavorite = isFailed(favorites.saveRestState) && hasUpdatedResourceFavoritesProps;

    if (hasFailedToSetResourceAsFavorite) {
      Toast.add('Failed to update favorite, please refresh and try again');
    }
  }

  render() {
    if (!this.state.loaded) {
      return (
        <div className="resources-loading">
          <MnSpinner />
        </div>
      );
    } else {
      return (
        <Router>
          <Routes>
            <Route path="/resources/*" element={ <div className="resource-app-container">{this._determineResourceView()}</div>} />
          </Routes>
        </Router>
      );
    }
  }

  /**
   * Used to dispatch an action to set a resource as favorite
   *
   * @method setResourceFavorite
   *
   * @param {Object} options
   *        @param {String} id
   *        @param {Boolean} [isListView]
   *
   * @return {Promise/Action} Dispatches an event to set the given resource as favorite
   */
  setResourceFavorite(options) {
    return this._dispatch(setResourceItemAsFavorite(options)).then(() => {
      Toast.add('Item added to favorites', { expires: 2000 });
    });
  }

  /**
   * Used to dispatch an action to un-favorite resource
   *
   * @method removeResourceFavorite
   *
   * @param {Object} options
   *        @param {String} id
   *        @param {Boolean} [isListView]
   *
   * @return {Promise/Action} Dispatches an event to un-favorite a given resource
   */
  removeResourceFavorite(options) {
    return this._dispatch(removeResourceItemAsFavorite(options)).then(() => {
      Toast.add('Item removed from favorites', { expires: 2000 });
    });
  }

  /**
   * Select a resource section
   *
   * @param {String} resourceSectionSlug
   */
  selectResourceSection(resourceSectionSlug) {
    this._history.push(`/resources/${resourceSectionSlug}`);
  }

  /**
   * Select the resource item
   *
   * @param {String} resourceItemSlug
   */
  selectResourceItem(resourceItemSlug) {
    this._history.push(`${this.state.currentPath}/${resourceItemSlug}`);
  }

  /**
   * Navigate back
   *
   * @method navigateBack
   *
   * @return {routeUpdate} Method to update the history to the previous route
   */
  navigateBack() {
    const location = this.state.currentPath.split('/').filter(Boolean);
    location.pop();

    const newPath = location.join('/');

    // We set the path ourselves instead of using `goBack` to ensure the resources back button will always
    // navigate in context of the react app
    return this._history.push(`/${newPath}`);
  }

  /**
   * Determine the current resource view
   *
   * @private
   *
   * @method _determineResourceView
   *
   * @return {Element} The resource view depending on the current path
   */
  _determineResourceView() {
    // @todo We possible need a fallback when requesting a resource that does not exist
    const location = this.state.currentPath.split('/').filter(Boolean);
    const resourceSectionsView = location.length === 1;
    const resourceItemListView = location.length === 2;
    const resourceItemView = location.length === 3;

    if (resourceSectionsView) {
      return this._getResourceSectionsView();
    }

    if (resourceItemListView) {
      return this._getResourceListView(location[1]);
    }

    if (resourceItemView) {
      return this._getResourceItemView(location[2]);
    }

    // Default to resource sections when no route matches
    return this._getResourceSectionsView();
  }

  /**
   * Get the resource sections view
   *
   * @private
   *
   * @method _getResourceSections
   *
   * @return {Element} The resource sections view
   */
  _getResourceSectionsView() {
    const { sections } = this.props.store.resources;

    return (
      <div>
        <ScrollToTopOnMount />
        <ResourceSections
          resourceSections={sections}
          selectResourceSection={this.selectResourceSection}
          loadResourceSections={this._loadResourceSections}
        />
      </div>
    );
  }

  /**
   * Get the resource list view
   *
   * @private
   *
   * @method _getResourceListView
   *
   * @return {Element} The resource list view
   *
   */
  _getResourceListView(slug) {
    const { resources } = this.props.store;

    return (
      <div>
        <ScrollToTopOnMount />
        <ResourceList
          slug={slug}
          loadResourceList={this._loadResourceList}
          resources={resources}
          navigateBack={this.navigateBack}
          selectResourceItem={this.selectResourceItem}
          setResourceFavorite={this.setResourceFavorite}
          removeResourceFavorite={this.removeResourceFavorite}
        />
      </div>
    );
  }

  /**
   * Get the resource item view
   *
   * @private
   *
   * @method _getResourceItemView
   *
   * @return {Element} The resource item view
   */
  _getResourceItemView(slug) {
    const { resourceItems } = this.props.store.resources;
    return (
      <div>
        <ScrollToTopOnMount />
        <ResourceItem
          navigateBack={this.navigateBack}
          slug={slug}
          resourceItems={resourceItems}
          loadResourceItem={this._loadResourceItem}
          hideNavigationHeader={this.props.hideNavigationHeader}
          setResourceFavorite={this.setResourceFavorite}
          removeResourceFavorite={this.removeResourceFavorite}
        />
      </div>
    );
  }

  _loadResourceList = slug => {
    this._dispatch(getResourceList(slug));
  }

  _loadResourceSections = () => {
    this._dispatch(getResourceSections());
  }

  _loadResourceItem = slug => {
    this._dispatch(getResourceItem(slug));
  }

  /**
   * Used to update the rails navigation current page
   * This is normally done via rails on page load, but since the react app does client side routing we need to manually
   * set the current state since both resources and favorites are listed in the drop down
   *
   * @private
   *
   * @method _updateRailsDropDownCurrentPage
   *
   * @param {String} currentPath
   */
  _updateRailsDropDownCurrentPage(currentPath) {
    const resourceFavoritePath = '/resources/favorites';
    const resourcePath = '/resources';
    let matchedPath = '';

    if (currentPath === resourcePath) {
      matchedPath = resourcePath;
    }

    if (currentPath === resourceFavoritePath) {
      matchedPath = resourceFavoritePath;
    }

    if (!matchedPath) {
      return;
    }

    const navElementsDesktop = document.querySelectorAll('.react-nav-dropdown-locator-desktop li');
    const navElementsMobile = document.querySelectorAll('.react-nav-locator-mobile li');

    // Since we have two instances of the nav (mobile and desktop) we need to update the current state for both
    // This helps keep the nav consistent when resizing the window for example
    const navElementsArray = [navElementsDesktop, navElementsMobile];

    navElementsArray.forEach(navElements => {
      navElements.forEach(navElement => {
        const linkElement = navElement.querySelector('a');

        if (!linkElement) {
          return;
        }

        linkElement.classList.remove('current');

        const linkElementHref = linkElement.getAttribute('href');

        if (linkElementHref === matchedPath) {
          linkElement.classList.add('current');
        }
      });
    });
  }
}

function mapStateToProps(state) {
  return {
    store: state
  };
}

export default connect(mapStateToProps)(ResourcesContainer);
