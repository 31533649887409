import moment from 'moment';

export function sameDate(d1, d2) {
  return moment(d1).format('L') === moment(d2).format('L');
}

export function isToday(d) {
  return sameDate(d, moment());
}

export function isYesterday(d) {
  return sameDate(d, previousDay(moment()));
}

export function previousDay(d) {
  return moment(d).subtract(1, 'days');
}

export function nextDay(d) {
  return moment(d).add(1, 'days');
}

export function niceShortDateTime(d) {
  return moment(d).format('Do MMM YY, h:mm a');
}

export function minsAfterMidnight(d) {
  const midnight = new Date(d).setHours(0, 0, 0, 0);
  return (d - midnight) / 1000 / 60; // 1000ms 60sec
}

export function daysBetween(date1, date2) {
  // Get 1 day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  const date1Ms = date1.getTime();
  const date2Ms = date2.getTime();

  // Calculate the difference in milliseconds
  const differenceMs = date2Ms - date1Ms;

  // Convert back to days and return
  return Math.round(differenceMs / oneDay);
}

export function minutesBetween(time1, time2) {
  return secondsBetween(time1, time2) / 60;
}

export function secondsBetween(time1, time2) {
  // Get 1 second in milliseconds
  const oneSecond = 1000;

  // Convert both dates to milliseconds
  const time1Ms = new Date(time1).getTime();
  const time2Ms = new Date(time2).getTime();

  // Calculate the difference in milliseconds
  const differenceMs = time2Ms - time1Ms;

  // Convert back to days and return
  return Math.round(differenceMs / oneSecond);
}

export function timeNowAmPm() {
  return moment().format('h:mm a');
}
export function timeNow24() {
  return moment().format('HH:mm');
}
