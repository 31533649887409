export default class ChatPolicy {
  constructor(store) {
    this.store = store;
  }

  showFilter = () => {
    if (!this.store.settings.loaded) {
      return false;
    }
    const roles = this.store.settings.chat.user_roles_allowed_to_filter;
    return roles && (roles === 'all' || roles.indexOf(this.currentUser().role_name) > -1);
  };

  currentUser = () => this.store.currentUser;
}
