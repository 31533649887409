import React from 'react';
import PropTypes from 'prop-types';

export default function ContentModulesCurrentResourcesList({ currentModule }) {
  return (
    <div className="summary">
      <h3>{currentModule.file_name.includes('spanish') ? 'Resumen de recursos' : 'Resources recap'}</h3>
      <p>{currentModule.file_name.includes('spanish') ? 'En este módulo cubrimos los siguientes recursos' : 'In this module we covered the following resources:' }</p>
      <ul>
        {currentModule.resources.map((resource) => {
          return (
            <li key={currentModule.resources.indexOf(resource)}>
              <a target="_blank" rel="noopener noreferrer" href={`/resources/${resource.slug}`}>{resource.title}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

ContentModulesCurrentResourcesList.propTypes = {
  currentModule: PropTypes.object
};
