import React from 'react';
import UserAvatar from 'mn-react/components/UserAvatar';
import { HomeCameraIcon } from 'util/FaIcons';

export default function Header(props) {
  return (
    <div className="home-header">
      <div className="header-content">
        <UserAvatar user={props.user} size="size-100" />
        <div className="camera-icon">
          <a href="/users/edit_profile">
            <HomeCameraIcon />
          </a>
        </div>
        <h1>Hi there!</h1>
        <p>{"Let's get better together"}</p>
      </div>
    </div>
  );
}
