import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightIcon } from 'mn-react/util/FaIcons';

export default function Panel(props) {
  const styles = {
    backgroundColor: props.backgroundColor,
    color: '#fff'
  };

  switch (props.panelType) {
    case 'navigation':
      return (
        <a href={`${props.displayPage}`}>
          <div className={'mn-panel'} style={styles}>
            <p>{props.text}</p>
            {maybeDisclosureIndicator(props.hasDisclosureIndicator)}
          </div>
        </a>
      );
    case 'daily_affirmation':
      return (
        <div
          className={'mn-panel mn-daily-affirmation-panel'}
          style={props.imageUrl ? { backgroundImage: 'url(' + props.imageUrl + ')' } : {}}
        >
          <p>{props.text}</p>
          {maybeDisclosureIndicator(props.hasDisclosureIndicator)}
        </div>
      );
    case 'recommended_read':
      return (
        <a href={`${props.displayPage}`}>
          <div
            className={'mn-panel mn-recommended-read-panel'}
            style={props.imageUrl ? { backgroundImage: 'url(' + props.imageUrl + ')' } : {}}
          >
            <p>
              <span className="title">{props.text[1]}</span>
              <br />
              {displayResourcesAvailable(props.text[2])}
            </p>
            {maybeDisclosureIndicator(props.hasDisclosureIndicator)}
          </div>
        </a>
      );
    default:
      return <div className={'mn-panel'} style={styles} />;
  }
}

Panel.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hasDisclosureIndicator: PropTypes.bool,
  panelType: PropTypes.string,
  displayPage: PropTypes.string
};

Panel.defaultProps = {
  backgroundColor: '#ffcb03',
  text: 'Default',
  hasDisclosureIndicator: false,
  panelType: 'navigation',
  displayPage: '/home'
};

function maybeDisclosureIndicator(hasDisclosureIndicator) {
  if (hasDisclosureIndicator) {
    return <ChevronRightIcon />;
  }
}

function displayResourcesAvailable(resourceNum) {
  return resourceNum === 1 ? `${resourceNum} resource available` : `${resourceNum} resources available`;
}
