import React from 'react';
import { connect } from 'react-redux';
import MnSpinner from 'mn-react/components/MnSpinner';
import { iframeAPIReady } from 'common/youtube-utils';
import { trackVideoInteraction } from '../actions';

class YoutubeContainer extends React.Component {
  constructor(props) {
    super(props);
    this._dispatch = this.props.dispatch;
    this._onPlayerStateChange = this._onPlayerStateChange.bind(this);
    this._onPlayerError = this._onPlayerError.bind(this);
    this._attachPlayerApi = this._attachPlayerApi.bind(this);

    const randomId = Math.round(Math.random() * 1000);

    this.state = {
      player: null,
      videoId: this.props.videoId,
      playerId: `${this.props.videoId}-${randomId}`,
      errorLoadingPlayerApi: false
    };
  }

  render() {
    const URL = `https://www.youtube.com/embed/${this.state.videoId}?enablejsapi=1`;
    const loadedPlayer = this.state.player || this.state.errorLoadingPlayerApi;
    const iframeClasses = loadedPlayer ? '' : 'youtube-hide';
    const spinnerClasses = loadedPlayer ? '' : 'youtube-loading-spinner';

    if (!this.state.videoId) {
      return null;
    }

    // NOTE: We have to pre-render the iframe before attaching the youtube API
    // Otherwise you will see a race condition console error "Failed to execute 'postMessage' on 'DOMWindow"
    // This has been raised in popular libraries like react-youtube https://github.com/troybetz/react-youtube/issues/96
    // To get around this we don't generate the iframe via the youtube api, but instead we pre-render the iframe
    // and then attach the api afterwards.  The downside is we have to hide the iframe while the youtube API loads
    // otherwise there is a chance that the user could click play before the events are ready.
    return (
      <div>
        <div className={spinnerClasses}>
          <MnSpinner />
        </div>
        <iframe
          className={iframeClasses}
          id={this.state.playerId}
          src={URL}
          frameBorder="0"
          onLoad={this._attachPlayerApi}
        />
      </div>
    );
  }

  /**
   * Attach the youtube API to the existing iframe
   *
   * @private
   *
   * @method _attachPlayerApi
   *
   * @return {Promise} Resolved with the youtube player API attached to the existing iframe
   */
  _attachPlayerApi() {
    const loadYoutubeApi = iframeAPIReady();

    return loadYoutubeApi
      .then(YT => {
        this._YT = YT;

        const player = new this._YT.Player(this.state.playerId, {
          events: {
            onStateChange: this._onPlayerStateChange,
            onError: this._onPlayerError
          }
        });

        return this.setState({ player });
      })
      .catch(error => {
        this.setState({ errorLoadingPlayerApi: true });
        console.error('Error loading youtube API: ', error);
      });
  }

  /**
   * Manage the player state changes
   *
   * @private
   *
   * @method _onPlayerStateChange
   *
   * @param {Object} event
   */
  _onPlayerStateChange(event) {
    const { currentUser } = this.props.store;

    switch (event.data) {
      case this._YT.PlayerState.PLAYING:
        // We added the currentUser check as we only want to track the resource for authenticate users
        const trackStartedPlaying = this.props.resourceId && this._cleanTime() === 0 && currentUser;

        if (trackStartedPlaying) {
          this._dispatch(trackVideoInteraction({ resourceId: this.props.resourceId, interaction: 'playedVideo' }));
        }
    }
  }

  /**
   * Round the youtube player progress time
   *
   * @private
   *
   * @method _cleanTime
   *
   * @return {Number} The rounded player progress time in seconds
   */
  _cleanTime() {
    return Math.round(this.state.player.getCurrentTime());
  }

  /**
   * Handle player errors
   *
   * @private
   *
   * @method _onPlayerError
   *
   * @param {Object} event
   *        An event containing the error data
   */
  _onPlayerError(event) {
    switch (event.data) {
      case 2:
        console.error('Invalid video id');
        break;
      case 5:
        console.error('Cannot render video due to HTML5 error');
        break;
      case 100:
        console.error('The video requested was not found');
        break;
      case 101 || 150:
        console.error('The owner of the requested video does not allow it to be played in embedded players');
        break;
    }
  }
}

function mapStateToProps(state) {
  return {
    store: state
  };
}

export default connect(mapStateToProps)(YoutubeContainer);
