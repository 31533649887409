import React from 'react';
import PropTypes from 'prop-types';
import { ClockIcon, MapMarkerIcon } from 'util/FaIcons';
import { niceShortDateTime } from 'util/datetime/dateHelpers';

export default class PostResource extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired
  };

  render() {
    const { post } = this.props;
    const resource = post.resource();

    const ViewComponent = resource.modalType() === 'Event' ? EventResource : StandardResource;
    return <ViewComponent {...this.props} resource={resource} />;
  }
}

class EventResource extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object,
    resource: PropTypes.object.isRequired
  };

  render() {
    const { resource } = this.props;
    return (
      <div className="community-post__shared-resource">
        <div className="community-post__shared-resource-byline">New Upcoming Event:</div>
        <div className="community-post__shared-resource-content">
          <h3>{resource.title}</h3>
          {resource.event_start && eventTime('Starts', resource.event_start)}
          {resource.location && eventLocation(resource.location)}
          {resourceLink(resource, 'View details')}
        </div>
      </div>
    );
  }
}

const eventTime = (text, at) => (
  <p className="community-post__shared-resource-time">
    <ClockIcon />
    <strong>
      {' '}
      {text}: {niceShortDateTime(at)}{' '}
    </strong>
  </p>
);

const eventLocation = location => (
  <p className="community-post__shared-resource-location">
    <MapMarkerIcon />
    <strong> Location: {location} </strong>
  </p>
);

class StandardResource extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object,
    resource: PropTypes.object.isRequired
  };

  render() {
    const { resource } = this.props;
    return (
      <a href={resource.links.web}>
        <div className="community-post__shared-resource">
          <div className="community-post__shared-resource-byline">
            New shared resource: {resourceLink(resource, 'Read more')}
          </div>
          <div className="community-post__shared-resource-content">
            <h3>{resource.title}</h3>
            <div className="community-post__shared-resource-image">
              <img src={resource.image_url} />
            </div>
            {resource.url && viewUrl(resource.url)}
            {resource.document_url &&
              !resource.image_url &&
              viewDocument(resource.document_url, resource.document_content_type)}
            {resource.linked_video && linkedVideo(resource.linked_video)}
          </div>
        </div>
      </a>
    );
  }
}

const resourceLink = (resource, text) => null;

const viewUrl = url => (
  <div className="community-post__shared-resource-url">
    <a href={url} />
  </div>
);

const viewDocument = (url, documentContentType) => (
  <div className="community-post__shared-resource-document">
    <embed src={url} width="100%" height="300px" type={documentContentType} />
  </div>
);

const linkedVideo = linkedVideo => {
  if (linkedVideo.embed_url) {
    return (
      <iframe
        className="community-post__shared-resource-video"
        allowFullScreen=""
        frameBorder="0"
        height="350"
        src={linkedVideo.embed_url}
        width="100%"
      />
    );
  } else if (linkedVideo.embed_url) {
    return (
      <p className="community-post__shared-resource-video">
        <img src={linkedVideo.thumb_preview_url} />
      </p>
    );
  }
};
