import { ApiEndpoint } from 'backend/ApiEndpointActions';
import apiConfig from '../apiConfigs/likes';

const endpoint = new ApiEndpoint(apiConfig);

export function save(data) {
  return function(dispatch, getState) {
    endpoint.save(dispatch, getState, data);
  };
}

export function destroy(data) {
  return function(dispatch, getState) {
    endpoint.destroy(dispatch, getState, data);
  };
}
