import React from 'react';
import PropTypes from 'prop-types';
import Messages from './Messages';
import MnSpinner from 'mn-react/components/MnSpinner';
import withRouter from '../../../../common/withRouter';
class MessagesWidget extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    sendNewMessage: PropTypes.func.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    findConversation: PropTypes.func.isRequired,
    notices: PropTypes.object.isRequired
  };

  render() {
    const { store, sendNewMessage, deleteMessage, notices } = this.props;

    this.markAsReadIfNeeded(this.props);

    const conversation = this.conversation();
    if (!conversation) {
      return (
        <div className="conversation-widget-loading">
          <MnSpinner />
        </div>
      );
    }
    return (
      <Messages
        conversation={conversation}
        currentUser={store.currentUser}
        sendNewMessage={sendNewMessage}
        deleteMessage={deleteMessage}
        chatSettings={store.settings.chat}
        notices={notices}
      />
    );
  }

  conversationId = () => parseInt(this.props.params.id);

  conversation = () => this.props.findConversation(this.conversationId());

  markAsReadIfNeeded = props => {
    const { store } = props;

    if (store.conversations.loaded && !store.conversations.fetching) {
      const conversation = props.findConversation(parseInt(props.params.id));

      // update the conversation read_at, which marks all messages as read by this user
      // this matches how slack do it, so if you are chatting with someone, new messages appear as read
      if (conversation && conversation.num_unread_messages > 0) {
        props.markConversationAsRead(conversation.id);
      }
    }
  };
}

export default withRouter(MessagesWidget);
