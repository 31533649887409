import React from 'react';
import PropTypes from 'prop-types';
import UserRoleBadge from '../../../UserRoleBadge';
import Mixpanel from 'common/Mixpanel';

export default class UserNameAndRoleBadge extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;
    const userUrl = `/users/${user.id}`;

    return (
      <span className="user__name-link mn-body-copy mn-semi-bold">
        <a href={userUrl} title={user.username} onClick={() => { Mixpanel.track('Username Link'); }}>
          {user.username}
        </a>
        { this.showRoleBadge() && <UserRoleBadge user={user} /> }
      </span>
    );
  }

  showRoleBadge = () => {
    const { user } = this.props;

    return user.role_name && user.role_name !== 'Patient';
  }
}
