import React from 'react';
import MnIcon from 'mn-react/components/MnIcon';

class ResourceItemDocumentSection extends React.Component {
  render() {
    if (!this.props.resourceItem.documentUrl) {
      return null;
    }

    return (
      <div className="resource-item-document-section-container">
        <div className="resource-item-document-section-content">
          <MnIcon icon='mn-resources-document-icon' />
          <div className="resource-item-document-section-download-link-section" onClick={this._downloadDocument}>
            {this._documentDownloadLink()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * Determine document download link text
   *
   * @private
   *
   * @method _documentDownloadLink
   *
   * @return {Element} The document download link
   */
  _documentDownloadLink() {
    const pdfContentType = 'application/pdf';
    let download = 'Download';

    if (this.props.resourceItem.documentContentType === pdfContentType) {
      download = `${download} pdf`;
    }

    return (
      <a
        href={this.props.resourceItem.documentUrl}
        download={this.props.resourceItem.title}
        className="mn-link mn-link--light"
      >
        {download}
      </a>
    );
  }
}

export default ResourceItemDocumentSection;
