import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

export default class ModalHeading extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  render() {
    return (
      <Modal.Header>
        <button className="close" type="button" onClick={this.props.onClose}>
          <span aria-hidden="true">×</span>
        </button>
        <h3 className="title">{this.props.title}</h3>
      </Modal.Header>
    );
  }
}
