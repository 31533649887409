import React from 'react';
import { Provider } from 'react-redux';

import YoutubeAppContainer from '../containers/YoutubeContainer';

export default props => {
  const store = window.store;

  const reactComponent = (
    <Provider store={store}>
      <YoutubeAppContainer {...props} />
    </Provider>
  );

  return reactComponent;
};
