/* global $ */
import React from 'react';
import PropTypes from 'prop-types';

export default class ConversationAvailability extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      available: this.props.currentUser.available_for_chat
    };
  }

  render() {
    const { currentUser } = this.props;

    if (!currentUser.canToggleChatStatus()) {
      return null;
    }

    return (
      <div className="chat-availiablity">
        <label>Available for new conversations?</label>
        <input type="checkbox" className="mn-toggle-switch" checked={this.state.available} ></input>
        <label className="mn-toggle-switch" onClick={this.changeStatus}></label>
      </div>
    );
  }

  changeStatus = e => {
    const available = !this.state.available;
    this.setState({
      available: available
    });
    var url = `/api/v1/chat_availability?available=${available}`;
    $.post(url, { _method: 'put' }, function(result) {});
  };
}
