import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';

import TimeAgoNoIcon from 'util/datetime/TimeAgoNoIcon';
import { minutesBetween } from 'util/datetime/dateHelpers';
import UserAvatar from 'mn-react/components/UserAvatar';
import { elementsFromText } from 'web/utils/html';

import { TrashIcon } from 'util/FaIcons';

export default class MessageSummary extends React.Component {
  static propTypes = {
    message: PropTypes.object,
    currentUser: PropTypes.object,
    unread: PropTypes.bool,
    deleteMessage: PropTypes.func.isRequired,
    prevMessage: PropTypes.object,
    chatSettings: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showDeleteBtn: false,
      deleted: false
    };
  }

  render() {
    const { message } = this.props;

    if (this.state.deleted) {
      return null;
    }

    return (
      <li className={this.itemClassName()} onMouseLeave={this.hideDeleteBtn} onMouseEnter={this.showDeleteBtn}>
        <div className="conversation-display__avatar">
          {this.showSubheading() && <UserAvatar size='size-40' user={message.owner()} />}
        </div>

        <div className="conversation-display__text">
          {this.showSubheading() && (
            <div>
              <b>{message.owner().name}</b>
              <span className="interpunct">&nbsp; &bull; &nbsp;</span>
              <TimeAgoNoIcon time={message.created_at} />
            </div>
          )}
          <Linkify properties={{ target: '_blank' }}>{elementsFromText(message.body)}</Linkify>
        </div>
        {this.deleteBtn()}
      </li>
    );
  }

  itemClassName = () => {
    let className = 'message';

    className += this.showSubheading() ? ' diff-author' : ' same-author';

    if (this.isUnread()) {
      className += ' unread-message';
    }

    if (this.state.showDeleteBtn) {
      className += ' messages-list__indicate-delete-message';
    }

    return className;
  };

  showDeleteBtn = () => {
    const { currentUser, chatSettings } = this.props;
    if (chatSettings.allow_nurse_to_delete_message && (currentUser.isNurse() || currentUser.isCoach())) {
      this.setState({
        showDeleteBtn: true
      });
    }
  };

  hideDeleteBtn = () => this.setState({ showDeleteBtn: false });

  deleteBtn = () => {
    if (this.state.showDeleteBtn) {
      return (
        <span className="messages-list__delete-message">
          <a href="#" className="mn-button is-primary" onClick={this.deleteMessage}>
            <TrashIcon />
          </a>
        </span>
      );
    }
  };

  deleteMessage = e => {
    e.preventDefault();
    const { message, deleteMessage } = this.props;
    this.setState({
      deleted: true
    });
    deleteMessage(message);
  };

  showSubheading = () => !this.hideSubheading();

  isUnread = () => this.props.unread;

  hideSubheading = () => this.props.prevMessage && this.isSameOwner() && this.withinOneHour();

  isSameOwner = () => this.props.message.owner_id === this.props.prevMessage.owner_id;

  withinOneHour = () => Math.abs(minutesBetween(this.props.message.created_at, this.props.prevMessage.created_at)) < 61;
}
