import React from 'react';
import { Provider } from 'react-redux';

import MeetAndGreetScheduleCallContainer from '../containers/MeetAndGreetScheduleCallContainer';

export default props => {
  const store = window.store;

  const reactComponent = (
    <Provider store={store}>
      <MeetAndGreetScheduleCallContainer />
    </Provider>
  );

  return reactComponent;
};
