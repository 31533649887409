import BaseModel from 'models/BaseModel';

export default class Resource extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      title: '',
      body: '',
      url: null,
      linked_video: null,
      links: null,
      image_url: null,
      medium_image_url: null,
      document_url: null,
      document_content_type: null,
      resource_section_id: null,
      type: null,
      author_id: null,
      location: null,
      event_start: null,
      event_end: null,
      is_deleted: false,
      created_at: null,
      slug: ''
    };
    super(item, store, json, properties);
  }

  modalType = () => this.type; // "Resource"

  author = store => store.allUsers.find(this.author_id);
}
