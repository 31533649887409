import React from 'react';
import PropTypes from 'prop-types';
import { orderByNurse, orderByPatient, orderByDate, orderByStatus } from 'models/MeetingForUser';
import SortableTable from 'util/table/SortableTable';
import UIText from 'store/UIText';

export default class CurrentNurseMeetings extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    meeting_items: PropTypes.array,
    MeetingView: PropTypes.func
  };

  render() {
    const meetingItems = this.props.meeting_items;

    var htmlClasses = 'upcoming-meetings';
    if (meetingItems === undefined) {
      htmlClasses += ' loading';
    }
    return (
      <div id="widget-meetings">
        <div className={htmlClasses}>{this.renderMeetingsSummary(meetingItems)}</div>
      </div>
    );
  }

  renderMeetingsSummary(meetingItems) {
    const { MeetingView } = this.props;

    var columns = [
      // { header: "Nurse", headerStyle: {backgroundColor: "#FFDAB9", width: "100px" }, sortFunc: orderByNurse },
      { header: 'Nurse', sortFunc: orderByNurse },
      { header: <UIText>{'Patient'}</UIText>, sortFunc: orderByPatient },
      { header: 'Time', sortFunc: orderByDate },
      { header: 'Status', sortFunc: orderByStatus },
      { header: 'Actions', sortable: false },
      { header: 'Notes', sortable: false }
    ];

    if (meetingItems === undefined) {
      return 'Loading meetings...';
    } else if (!meetingItems.length) {
      return 'No upcoming meetings';
    } else {
      return (
        <div>
          <SortableTable columns={columns} data={meetingItems} RowRenderer={MeetingView} />
        </div>
      );
    }
  }
}
