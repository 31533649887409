// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OWDbGWeI4lhlUIgR7ccO {\n  display: flex;\n  flex-direction: column;\n}\n\n.Y7CBq4GuhuL4iKE9WK10 {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.wZZ5JLhlMZgw3bxSdcHM .public-DraftEditor-content {\n}", "",{"version":3,"sources":["webpack://./app/javascript/reactjs/components/Community/components/Replies/createPostStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;AACA","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n}\n\n.toolbar {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.editor :global(.public-DraftEditor-content) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "OWDbGWeI4lhlUIgR7ccO",
	"toolbar": "Y7CBq4GuhuL4iKE9WK10",
	"editor": "wZZ5JLhlMZgw3bxSdcHM"
};
export default ___CSS_LOADER_EXPORT___;
