import React from 'react';
import PropTypes from 'prop-types';
import Mixpanel from 'common/Mixpanel';
import Editor from '../Editor';
import { isInputEmpty } from 'web/utils/formHelpers';
import UserAvatar from 'mn-react/components/UserAvatar';

import styles from './createPostStyles.css';

export default class WriteReply extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      body: ''
    };
  }

  render() {
    const { store } = this.props;
    const author = store.currentUser;

    return (
      <div className="new-community-post-reply">
        <div className="community-post__write-reply_avatar">
          <UserAvatar user={author} size='size-40' />
        </div>
        <div className="editor-container">
          <form className={styles.form} onSubmit={this.submit}>
            <Editor
              onChange={this.setBody}
              placeholder="Write a reply..."
              withImageUploader={false}
              id={`reply-${this.props.post.id}`}
              focusOnStart={true}
            />

            <div className={styles.toolbar}>
              <input type="button" value="Cancel" className="mn-button is-link" onClick={() => { this.props.cancel(); Mixpanel.track('Cancel New Reply'); }} />
              <input type="submit" name="commit" value="Post" className="mn-button is-primary" onClick={() => { Mixpanel.track('New Reply Submitted'); }} />
            </div>
          </form>
        </div>
      </div>
    );
  }

  startReply = () => this.props.actions.postActions.startReplyToPost(this.props.post);

  setBody = val => this.setState({ body: val });

  submit = e => {
    e.preventDefault();
    const { body } = this.state;

    if (isInputEmpty(body)) {
      this.props.actions.postActions.finishedEditing();
    } else {
      const reply = {
        body: body,
        community_id: this.props.post.community_id,
        post_id: this.props.post.id
      };

      this.props.actions.replyActions.save(reply);
      this.setState({ body: '' });

      this.props.cancel();
    }
  };
}
