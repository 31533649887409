import BaseModel from 'models/BaseModel';

export default class Reminder extends BaseModel {
  constructor(item, store, json) {
    const properties = {
      id: null,
      user_id: null, // store.currentUser.id,
      title: '',
      time_of_day: '8:00am',
      date: null,
      next_due_at: '',
      frequency_id: 2,
      reminder_type_id: 1,
      enabled: true,
      last_reminded_at: '',
      is_due: false,
      notes: undefined
    };
    super(item, store, json, properties);
  }

  frequencyDescription = () => this.item.frequency_description;
}
