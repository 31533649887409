import React from 'react';
import { Provider } from 'react-redux';

import ShowTermsContainer from '../containers/ShowTermsContainer';

export default props => {
  const store = window.store;
  const reactComponent = (
    <Provider store={store}>
      <ShowTermsContainer />
    </Provider>
  );
  return reactComponent;
};
