import React from 'react';
import ResourceHeaderBackArrow from './ResourceHeaderBackArrow';
import ResourceHeaderToggleFavorites from './ResourceHeaderToggleFavorites';
import { keyboardInteraction } from '../resourceHelpers';
import MnIcon from 'mn-react/components/MnIcon';

class ResourceHeader extends React.Component {
  render() {
    return (
      <div>
        <div className="resource-header-navigation-bar">
          <div className="resource-header-navigation-left">
            {this._headerNavigation()}
            {this._headerTitle()}
          </div>
          {this._favoritesToggle()}
        </div>
        {this._determineResourceHeadingContent()}
      </div>
    );
  }

  /**
   * Toggle resource favorite
   *
   * @private
   *
   * @method _favoritesToggle
   *
   * @returns {Element} The resource favorite toggle
   */
  _favoritesToggle() {
    if (!this.props.resourceItem) {
      return null;
    }

    return (
      <ResourceHeaderToggleFavorites
        resourceItem={this.props.resourceItem}
        removeResourceFavorite={this.props.removeResourceFavorite}
        setResourceFavorite={this.props.setResourceFavorite}
      />
    );
  }

  _headerNavigation() {
    if (this.props.hideNavigationHeader) {
      return null;
    }

    return (
      <div
        tabIndex="0"
        className="resource-header-back-button"
        onClick={this.props.navigateBack}
        onKeyPress={target => keyboardInteraction.handleKeyPress(target, this.props.navigateBack)}
      >
        <ResourceHeaderBackArrow />
      </div>
    );
  }

  /**
   * Determine the resource heading section content
   *
   * @private
   *
   * @method _determineResourceHeadingContent
   *
   * @return {Element} The resource heading content
   */
  _determineResourceHeadingContent() {
    const { resourceList } = this.props;

    if (resourceList) {
      return this._resourceListHeading();
    }

    return null;
  }

  /**
   * Get the header title element
   *
   * @private
   *
   * @method _headerTitle
   *
   * @return {Element} The header title element
   */
  _headerTitle() {
    const { resourceList, resourceItem } = this.props;

    if (resourceList) {
      return <div className="resource-header-title">{this.props.resourceList.name}</div>;
    }

    if (resourceItem) {
      return <div className="resource-header-title">{this.props.resourceItem.title}</div>;
    }
  }

  /**
   * Get the resource list heading element
   *
   * @private
   *
   * @method _resourceListHeading
   *
   * @return {Element} The resource list heading element
   */
  _resourceListHeading() {
    const { resourceList } = this.props;

    const ResourceListHeadingIcon = () => {
      if (resourceList.name === 'Favorites') {
        return (
          <div className="resource-header-resource-list-title-icon">
            <MnIcon icon="mn-star-large-icon" colour="#ffffff" />
          </div>
        );
      }

      return null;
    };

    return (
      <div
        className="resource-header-resource-list-container"
        style={resourceList.imageUrl ? { backgroundImage: 'url(' + resourceList.imageUrl + ')' } : {}}
      >
        <div className="resource-header-resource-list-title-container mn-bold">
          <ResourceListHeadingIcon />
          {resourceList.name}
        </div>
      </div>
    );
  }
}

export default ResourceHeader;
