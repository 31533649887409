import React from 'react';
import PropTypes from 'prop-types';

import Multiselect from 'react-widgets/lib/Multiselect';
import NewMessage from '../Messages/NewMessageForm';
import UserRoleBadge from '../../../UserRoleBadge';
import ConversationNotice from '../utils/ConversationNotice';
import Toast from 'common/toast';
import withRouter from '../../../../common/withRouter';

class NewConversationComponent extends React.Component {
  static propTypes = {
    save: PropTypes.func.isRequired,
    filterUsers: PropTypes.func.isRequired,
    filteredUsers: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    notices: PropTypes.object.isRequired
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.filteredUsers.forUser ? [props.filteredUsers.forUser] : [],
      open: true
    };
  }

  render() {
    return (
      <div className="messages-container">
        <h4>New message</h4>
        <div className="messages-container__messages-list">
          <div className="messages-list-container">
            { this._renderCoachDelayMessage() }
            <div className="messages-list-container-chat-with">
              <Multiselect
                autoFocus={true}
                value={this.state.value}
                data={this.excludeCurrentUser(this.props.currentUser.id)}
                valueField="id"
                textField="name"
                onChange={this.select}
                onSearch={this.search}
                itemComponent={ListItem}
                open={this.state.open}
                onToggle={this.toggle}
                placeholder="To:"
              />
            </div>
          </div>
          <NewMessage
            sendNewMessage={this.createNewConversation}
            currentUser={this.props.currentUser}
            shouldFocusOnMessage={false}
          />
        </div>
      </div>
    );
  }

  _renderCoachDelayMessage = () => {
    if (this.props.currentUser.shouldSeeCoachDelayMessage()) {
      return <ConversationNotice message={ this.props.notices.coachDelayMessage }></ConversationNotice>;
    }
  };

  defaultValue = () => (this.props.filteredUsers.forUser ? [this.props.filteredUsers.forUser] : []);

  excludeCurrentUser = currentUserId => {
    return this.props.filteredUsers.items.filter(function(user) {
      return user.id !== currentUserId;
    });
  };

  createNewConversation = message => {
    if (this.state.value.length < 1) {
      return;
    }
    // gather up the conversation details
    const newConversation = {
      body: message.body,
      user_ids: this.state.value.map(user => user.id)
    };

    this.props.navigate('/conversations/saving');

    this.props.save(newConversation).then((results) => {
      this.props.navigate(`/conversations/${results.conversation.id}`);
    }).catch(() => {
      Toast.add('Failed to create conversation');
      this.props.navigate('/conversations/new');
    });
  };

  toggle = () => this.setState({ open: !this.state.open });

  select = value => this.setState({ value, open: false });

  search = term => {
    this.props.filterUsers(term);
  };
}

const ListItem = ({ item }) => {
  if (item && item.links) {
    return (
      <span className="user-dropdown-item">
        <img src={item.links.profile_image_small_url} className="user-image" />
        {item.name}
        <UserRoleBadge user={item} />
      </span>
    );
  } else {
    return null;
  }
};

export default withRouter(NewConversationComponent);
