import actionTypes from '../constants';

import Conversation from 'models/Conversation';

export default {
  requestCollection: actionTypes.REQUEST_CONVERSATIONS,
  receiveCollection: actionTypes.RECEIVE_CONVERSATIONS,
  clearCollection: actionTypes.CLEAR_CONVERSATIONS,
  receiveItem: actionTypes.RECEIVE_CONVERSATION,
  removeItem: actionTypes.REMOVE_CONVERSATION, // not implemented

  storeKey: 'conversations',
  Model: Conversation,

  collectionKey: 'conversations',
  itemKey: 'conversation',

  apiEndPoint: '/api/v1/conversations',
  bodyParam: 'conversation'
};
