import React from 'react';
import { Provider } from 'react-redux';
import Mixpanel from 'common/Mixpanel';
import mixpanel from 'mixpanel-browser';
import ViewCommunityPostsWidget from './containers/ViewCommunityPostsWidget';

export default props => {
  const store = window.store;
  const appProps = {
    enableRefreshListener: props.enable_refresh_listener,
    focusNewPostOnStart: props.focus_new_post_on_start,
    currentUser: JSON.parse(props.current_user),
    posts: props.posts && JSON.parse(props.posts),
    postData: props.post && JSON.parse(props.post),
    isEmbedded: props.is_embedded,
    mixpanelProjectToken: props.mixpanel_project_token,
    analyticsUUID: props.analytics_uuid,
    railsENV: props.rails_env
  };
  mixpanel.init(props.mixpanel_project_token, { api_transport: 'sendBeacon', debug: !(props.rails_env === 'production' || props.rails_env === 'staging') });

  Mixpanel.setScreenName('Community');

  const reactComponent = (
    <Provider store={store}>
      <ViewCommunityPostsWidget {...appProps} />
    </Provider>
  );
  return reactComponent;
};
