import { ApiEndpoint } from 'backend/ApiEndpointActions';
import apiConfig from '../apiConfigs/users';
import { CurrentUserStateGetInProgressAction } from './CurrentUserStateActions';
import { apiMethods } from '../../backend/apiConstants';

const endpoint = new ApiEndpoint(apiConfig);

export function loadUser(id) {
  return function(dispatch, getState) {
    endpoint.loadItem(dispatch, getState, id, {}, { queryStr: 'scheme=lite' });
  };
}

export function loadCurrentUser() {
  const id = 'current_user';
  return function(dispatch, getState) {
    dispatch(CurrentUserStateGetInProgressAction());
    endpoint.loadItem(dispatch, getState, id, {}, { queryStr: 'scheme=lite' });
  };
}

export function loadCurrentUserFromProps(currentUser) {
  return function(dispatch, getState) {
    dispatch(CurrentUserStateGetInProgressAction());
    endpoint.loadItemFromProps(dispatch, getState, currentUser);
  };
}

export function save(data) {
  return function(dispatch, getState) {
    endpoint.save(dispatch, getState, data);
  };
}

export function updateUsername(userId, username) {
  const data = {
    id: userId,
    username: username
  };
  return function(dispatch, getState) {
    endpoint.save(dispatch, getState, data);
  };
}

export function block(userId) {
  const params = {
    resourceOverrides: {
      resourceUrl: '/api/v1/blocked_authors',
      method: apiMethods.POST,
      param: null
    },
    onCompleteCallBack: () => { location.reload(); }
  };

  return function(dispatch, getState) {
    endpoint.save(dispatch, getState, { user_id: userId }, params);
  };
}
