import React from 'react';
import PropTypes from 'prop-types';
export default class ViewApptForSelector extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    nurses: PropTypes.object,
    filter: PropTypes.object,
    onChange: PropTypes.func,
    countMeetingsForUser: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = { value: 'Hello!' };
  }

  render() {
    const { currentUser, nurses, filter, countMeetingsForUser } = this.props;
    var values = [];
    values.push(
      <option value="My" key="my">
        My Calls ({countMeetingsForUser(currentUser)})
      </option>
    );

    values.push(
      <option value="All" key="all">
        All Calls
      </option>
    );
    for (const n of nurses.byName().values()) {
      if (currentUser.id !== n.id) {
        values.push(
          <option value={n.id} key={n.id}>
            {n.name} ({countMeetingsForUser(n)})
          </option>
        );
      }
    }
    return (
      <span className="pull-left">
        View
        <select value={filter.nurse.id} onChange={this.handleChange}>
          {values}
        </select>
      </span>
    );
  }

  handleChange = event => {
    const newFilter = Object.assign({}, this.props.filter, {
      nurse: { id: event.target.value }
    });
    this.props.onChange(newFilter);
  };
}
