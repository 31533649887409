import React from 'react';
import { keyboardInteraction } from '../../resourceHelpers';
import MnIcon from 'mn-react/components/MnIcon';
import Mixpanel from 'common/Mixpanel';

class ResourceFavoriteSection extends React.Component {
  render() {
    if (!this.props.favoritesSection) {
      return null;
    }

    return (
      <div
        tabIndex="0"
        onClick={
          () => {
            const slug = this.props.favoritesSection.slug;
            Mixpanel.track(`Resource Section Selected - ${slug}`);
            this.props.selectResourceSection(slug);
          }
        }
        onKeyPress={target =>
          keyboardInteraction.handleKeyPress(target, () => {
            const slug = this.props.favoritesSection.slug;
            Mixpanel.track(`Resource Section Selected - ${slug}`);
            this.props.selectResourceSection(slug);
          })
        }
        className="resource-favorite-section-card"
        style={
          this.props.favoritesSection.imageUrl
            ? { backgroundImage: 'url(' + this.props.favoritesSection.imageUrl + ')' }
            : {}
        }
      >
        <div className="resource-favorite-section-name-container">
          <MnIcon icon="mn-star-large-icon" colour="#ffffff" />
          <div className="resource-favorite-section-name mn-bold">{this.props.favoritesSection.name}</div>
        </div>
        <div className="resource-favorite-section-count-container">{this._sectionCountElement()}</div>
      </div>
    );
  }

  /**
   * Resource count element
   *
   * @private
   *
   * @method _sectionCountElement
   *
   * @return {Element} The resource count element
   */
  _sectionCountElement() {
    return (
      <div className="resource-section-card-resource-count mn-subtext">{this.props.favoritesSection.resourceCount}</div>
    );
  }
}

export default ResourceFavoriteSection;
